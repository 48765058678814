import Item from './Item';
import { getAlignKeyword } from '../utils';
import { SEAT_WIDTH, SEAT_HEIGHT, SIDE_MAP } from '../constants';
/**
 *
 * @param {*} threekitApi threekit api object after initial the 3D player
 * @param {*} param2
 */

export default class Seat extends Item {
  constructor(threekitApi, options = {}) {
    super(threekitApi, options);

    this.width = SEAT_WIDTH;
    this.height = SEAT_HEIGHT;
    this._type = 'seat';
    this._key = 'standard';
    this._initAlignmentWithConnector(options.connector);
  }

  _initAlignmentWithConnector = (connector) => {
    this.left = null;
    this.right = null;
    this.top = null;
    this.bottom = null;
    this.leftAlign = null;
    this.rightAlign = null;
    this.topAlign = null;
    this.bottomAlign = null;
    this.island = null;

    if (connector) {
      this._connectTo(connector);
    }
  };

  _connectTo = (connector) => {
    const { target, targetLocalSide, localSide, insert } = connector;

    let curConnectItem;
    let curItemNewConnector;

    // if the target's side already have item C connect, means this is a insert, we will connect his item to the target item's side, and reconnect that C item to this item on the same side as the target side

    if (insert) {
      curConnectItem = target[targetLocalSide];
      if (curConnectItem._type !== 'side') {
        throw new Error(
          'Invalid insert connection, the exist connect item must be side!'
        );
      }
      curItemNewConnector = {
        target: this,
        targetLocalSide,
        alignment: target[getAlignKeyword(targetLocalSide)],
      };
    }

    let thisLocalSide = localSide;

    if (!thisLocalSide) {
      const targetWorldSide = target.getWorldSide(targetLocalSide);
      const thisWorldSide = SIDE_MAP[targetWorldSide];
      thisLocalSide = this.getLocalSide(thisWorldSide);
    }

    if (target._type === 'anytable' || target._type === 'side') {
      const sideWithSide = ['top', 'bottom'].find(
        (s) => this[s] && this[s]._type === 'side'
      );
      if (thisLocalSide === 'left') {
        connector.alignment = SIDE_MAP[sideWithSide];
      } else if (thisLocalSide === 'right') {
        connector.alignment = sideWithSide;
      }
    }

    this._connectHelper({ ...connector, localSide: thisLocalSide });

    if (curConnectItem) {
      curConnectItem._connectTo(curItemNewConnector);
    }

    this._updatePillow();
    target._updatePillow();
  };
}
