import { getRootId } from '../../helpers';
import { status } from '../../../status';

// const EXTRA_LIGHT_COLOR = '#00d3e7';
const LIGHT_COLOR = '#00bbc8';
const MEDIUM_COLOR = '#0098A7';
const DARK_COLOR = '#00474e';

const FLOOR_MEASUREMENT_COLOR = {
  None: MEDIUM_COLOR,
  Espresso: MEDIUM_COLOR,
  Chestnut: LIGHT_COLOR,
  SaddleBrown: LIGHT_COLOR,
  CoastalGrey: DARK_COLOR,
  WhiteOak: MEDIUM_COLOR,
};

const { isMobile } = status;
const FONT_SIZE = isMobile ? 7.4 : 3.4;
const Y_OFFSET = isMobile ? -0.03 : 0;
const XZ_OFFSET = isMobile ? 0.03 : 0;
const DEFAULT_LINE_OFFSET = 0.12 + XZ_OFFSET;

// offset lines when + sign is on
let lineOffset = 0;

const state = {
  floor: 'None',
  enabled: false,
  step: 0,
  boxes: [],
};

export const enableMeasurement = (type) => {
  state.enabled = true;
  // Show measurement of sets
  updateSets();
};

export const disableMeasurement = () => {
  state.enabled = false;
  state.activeRuler = null;
  state.boxes.map((box) =>
    window.threekit.api.scene.set(
      {
        id: box.id,
        plug: 'Measurement',
        property: 'targets',
      },
      []
    )
  );
};

const getMeasurementColor = (floor) =>
  FLOOR_MEASUREMENT_COLOR[floor || state.floor] || MEDIUM_COLOR;

export const updateMeasurementColor = (floorOrColor) => {
  const color = getMeasurementColor(floorOrColor);
  if (FLOOR_MEASUREMENT_COLOR[floorOrColor]) state.floor = floorOrColor;
  const { api } = window.threekit;
  state.boxes.map((box) =>
    api.scene.set(
      {
        id: box.id,
        plug: 'Measurement',
        property: 'color',
      },
      FLOOR_MEASUREMENT_COLOR[floorOrColor] || floorOrColor || color
    )
  );
};

const updateMeasurementLines = () => {
  const { api } = window.threekit;
  state.boxes.map((box) => {
    api.scene.set(
      {
        id: box.id,
        plug: 'Measurement',
        property: 'xOffset',
      },
      {
        x: 0,
        y: Y_OFFSET,
        z: DEFAULT_LINE_OFFSET + lineOffset,
      }
    );
    api.scene.set(
      {
        id: box.id,
        plug: 'Measurement',
        property: 'yOffset',
      },
      {
        x: DEFAULT_LINE_OFFSET + lineOffset,
        y: 0,
        z: DEFAULT_LINE_OFFSET + lineOffset,
      }
    );
    api.scene.set(
      {
        id: box.id,
        plug: 'Measurement',
        property: 'zOffset',
      },
      {
        x: DEFAULT_LINE_OFFSET + lineOffset,
        y: Y_OFFSET,
        z: 0,
      }
    );
  });
};

export const initFloor = (floor) => {
  state.floor = floor;
};

export const updateSets = async (sets) => {
  const { api } = window.threekit;
  const { enabled } = state;

  if (!sets) sets = state.sets;
  else state.sets = sets;

  if (enabled) {
    const currentNumOfBoxes = state.boxes.length;
    if (sets.length > currentNumOfBoxes) {
      const parentId = await getRootId();
      await Promise.all(
        sets.map(async (set, index) => {
          const { island } = set;
          const targets = await Promise.all(
            island.getItems().map(async (item) => {
              const { _id } = item;
              const instanceId = await api.player.getAssetInstance({
                id: _id,
                plug: 'Null',
                property: 'asset',
              });
              const assetId = await api.player.getAssetInstance({
                id: instanceId,
                plug: 'Proxy',
                property: 'asset',
              });
              const nullId = api.scene.findNode({
                from: assetId,
                name: 'Sactional*_Nulls', // Find null
              });
              return nullId;
            })
          );
          const { angleToX } = island;
          let xPositioning;
          let zPositioning;
          if (angleToX < 180) {
            xPositioning = 'top';
            if (angleToX < 90) {
              zPositioning = 'right';
            } else {
              zPositioning = 'left';
            }
          } else {
            // angleToX > 180
            xPositioning = 'bottom';
            if (angleToX < 270) {
              zPositioning = 'left';
            } else {
              zPositioning = 'right';
            }
          }

          if (index < currentNumOfBoxes) {
            // update target nodes
            api.scene.set(
              {
                id: state.boxes[index].id,
                plug: 'Measurement',
                property: 'targets',
              },
              targets
            );
            api.scene.set(
              {
                id: state.boxes[index].id,
                plug: 'Measurement',
                property: 'yEnabled',
              },
              status.view !== 'top'
            );
            api.scene.set(
              {
                id: state.boxes[index].id,
                plug: 'Measurement',
                property: 'xPositioning',
              },
              xPositioning
            );
            api.scene.set(
              {
                id: state.boxes[index].id,
                plug: 'Measurement',
                property: 'zPositioning',
              },
              zPositioning
            );
          } else {
            const color = getMeasurementColor();
            // create new boxes
            const boxPlugs = {
              Measurement: [
                {
                  targets,
                  type: 'BoundingBox',
                  unit: 'in',
                  decimals: 1,
                  xEnabled: true,
                  xOffset: {
                    x: 0,
                    y: Y_OFFSET,
                    z: DEFAULT_LINE_OFFSET + lineOffset,
                  },
                  xPositioning,
                  yEnabled: status.view !== 'top',
                  yOffset: {
                    x: DEFAULT_LINE_OFFSET + lineOffset,
                    y: 0,
                    z: DEFAULT_LINE_OFFSET + lineOffset,
                  },
                  yPositioning: 'left',
                  zEnabled: true,
                  zOffset: {
                    x: DEFAULT_LINE_OFFSET + lineOffset,
                    y: Y_OFFSET,
                    z: 0,
                  },
                  zPositioning,
                  space: 'world',
                  lineThickness: 0.75,
                  fontSize: FONT_SIZE,
                  fontCSSSpecifier:
                    "'museo-sans','Helvetica Neue',Helvetica,Arial,sans-serif",
                  color,
                },
              ],
              Properties: [{ type: 'ModelProperties', visible: true }],
            };
            const nodeId = api.scene.addNode(
              {
                type: 'Measurement',
                name: `Box Dimensions ${index}`,
                plugs: boxPlugs,
              },
              parentId
            );
            state.boxes.push({ id: nodeId });
          }
        })
      );
    } else {
      await Promise.all(
        new Array(currentNumOfBoxes).fill(0).map(async (v, index) => {
          if (index < sets.length) {
            const set = sets[index];
            const { island } = set;
            const targets = await Promise.all(
              island.getItems().map(async (item) => {
                const { _id } = item;
                const instanceId = await api.player.getAssetInstance({
                  id: _id,
                  plug: 'Null',
                  property: 'asset',
                });
                const assetId = await api.player.getAssetInstance({
                  id: instanceId,
                  plug: 'Proxy',
                  property: 'asset',
                });
                const nullId = api.scene.findNode({
                  from: assetId,
                  name: 'Sactional*_Nulls', // Find null
                });
                return nullId;
              })
            );
            const { angleToX } = island;
            let xPositioning;
            let zPositioning;
            if (angleToX < 180) {
              xPositioning = 'top';
              if (angleToX < 90) {
                zPositioning = 'right';
              } else {
                zPositioning = 'left';
              }
            } else {
              xPositioning = 'bottom';
              if (angleToX < 270) {
                zPositioning = 'left';
              } else {
                zPositioning = 'right';
              }
            }
            api.scene.set(
              {
                id: state.boxes[index].id,
                plug: 'Measurement',
                property: 'targets',
              },
              targets
            );
            api.scene.set(
              {
                id: state.boxes[index].id,
                plug: 'Measurement',
                property: 'yEnabled',
              },
              status.view !== 'top'
            );
            api.scene.set(
              {
                id: state.boxes[index].id,
                plug: 'Measurement',
                property: 'xPositioning',
              },
              xPositioning
            );
            api.scene.set(
              {
                id: state.boxes[index].id,
                plug: 'Measurement',
                property: 'zPositioning',
              },
              zPositioning
            );
          } else {
            api.scene.set(
              {
                id: state.boxes[index].id,
                plug: 'Measurement',
                property: 'targets',
              },
              []
            );
          }
        })
      );
    }
  }
};

export const getEnabled = () => state.enabled;

export const setLineOffsets = (offset) => {
  lineOffset = offset || 0;
  if (state.enabled) updateMeasurementLines();
};
