import Island, { ItemMap } from './Island';
import {
  getModelFromMouseEvent,
  renderPlusIcon,
  renderPlusIconAtPosition,
} from '../threekitUtils';
import {
  itemSelectEvent,
  itemAddEvent,
  itemDeleteEvent,
  itemDeleteErrorEvent,
  itemMoveStartEvent,
  itemMoveEndEvent,
  capacityChange,
} from '../events';
import {
  findClosetSegmentPointPair,
  flatNestArray,
  getAlignKeyword,
  getItemOutlineSegments,
  getTranslationOffset,
  findIslandValidTranslation,
  isSameTranslation,
  swapSideKey,
  getPosition,
} from '../utils';
import {
  ATTACH_THRESHOLD,
  SIDE_KEYWORD,
  VERTEX_KEYWORD,
  SELECTION_COLORS,
  DRAG_UNIT_DISTANCE,
  ALIGNMENT_MAP,
  SIDE_TYPE_KEYS,
  DEEP_SIDE_KEYS,
  CONFIGURATOR_VERSION,
  PREVIOUS_SEAT_SIDES,
  NEXT_SEAT_SIDES,
  OPPOSITE_SEAT_SIDES,
} from '../constants';

import {
  baFabricToSceneConfig,
  mapSceneConfigurationToTKConfiguration,
  setFloor as setFloorV1,
} from '../../v1/modules/room';
import { getSavedConfiguration } from '../../v1/modules/persistence/persistence';
import { getCameraId, HERO_CAM, ORTHO_CAM } from '../modules/camera';
import {
  getConnectedSets,
  updatePillowsForSet,
  evaluateSets,
} from '../modules/layout';
import {
  setStealthTech,
  getStealthTechBundlesFromSets,
  setSubwoofers,
} from '../modules/stealthTech';
import {
  updateSets,
  setLineOffsets,
  enableMeasurement,
  disableMeasurement,
} from '../../v1/modules/measurement';
import DoubleSidedSide from './DoubleSidedSide';

import { updateCapacity } from '../modules/capacity';

import { status } from '../../status';

export default class Configurator {
  constructor(threekitApi, options = {}) {
    const { islands, configJson, playerEl, device } = options;
    this._threekitApi = threekitApi;
    this._selectedItemId = null;
    this._islands = new Map();
    this._plusSignId = new Set();
    this._tempItemInstance = null;
    this._playerEl = playerEl;
    this._itemIds = new Set();
    this._isPresetLayout = false;
    this._doubleSidedSideLayout = false;
    status.view = 'top';
    if (device) {
      status.isMobile = device === 'mobile';
    }

    this._customPanHandler = (ev) => {
      let { deltaX, deltaY } = ev;
      const translation = this._threekitApi.camera.getPosition();
      if (
        (translation.x <= this._boundingBox.min.x && deltaX > 0) ||
        (translation.x >= this._boundingBox.max.x && deltaX < 0)
      ) {
        deltaX = 0;
      }
      if (
        (translation.z <= this._boundingBox.min.z && deltaY > 0) ||
        (translation.z >= this._boundingBox.max.z && deltaY < 0)
      ) {
        deltaY = 0;
      }
      this._threekitApi.player.cameraController.pan(deltaX / 20, deltaY / 20);
    };

    this._mobileCustomPan = {
      key: 'customPan',
      label: 'Custom Pan',
      active: true,
      enabled: true,
      handlers: {
        drag: () => {
          // when item selected, the drag will be moving the items instead of pan
          // return false so it will fallback to item drag logic
          if (this._selectedItemId) return false;
          return {
            handle: this._customPanHandler,
          };
        },
        swipe: this._customPanHandler,
      },
    };

    if (islands) {
      islands.forEach((island) => this._islands.set(island._id, island));
    } else if (configJson) {
      this.fromJson(configJson);
    }
  }

  _getAllItems = () =>
    this._getIslands().reduce(
      (res, island) => res.concat(island.getItems()),
      []
    );

  _setSelectItem = (modelId = null) => {
    let selectedItem;
    if (this._selectedItemId) {
      this._threekitApi.selectionSet.remove(this._selectedItemId);
      selectedItem = this.getItemById(this._selectedItemId);
      if (selectedItem) {
        selectedItem._configurator.setConfiguration({ 'Arrow Asset': false });
      }
    }

    if (modelId) {
      this._threekitApi.selectionSet.add(modelId);
      selectedItem = this.getItemById(modelId);
      if (
        selectedItem._type !== 'side' &&
        SIDE_KEYWORD.filter((side) => {
          return !!selectedItem[side];
        }).length < 2
      ) {
        selectedItem._configurator.setConfiguration({ 'Arrow Asset': true });
      }
    }
    this._selectedItemId = modelId;
    itemSelectEvent(this._selectedItemId);

    return selectedItem;
  };

  _getAllConnector = (type, key, options = {}) => {
    console.log('Get all connectors of ', type, key);
    const { instanceRef, excludes, insertAtEnd, autoRotate } = options;
    const islands = this._getIslands();
    const instance = new ItemMap[type][key](this._threekitApi, {
      rotation: instanceRef?.rotation,
      key,
    });
    this._tempItemInstance = instance;

    let allItems = this._getAllItems();
    if (excludes)
      allItems = allItems.filter((item) => !excludes.includes(item));

    const connectors = islands.reduce(
      (res, island) =>
        res.concat(
          island.getConnector(instance, {
            validationItems: allItems,
            insertAtEnd,
            autoRotate,
          })
        ),
      []
    );

    instance.setRotation(instanceRef?.rotation || 0);
    return connectors;
  };

  _addItem = async (instance, connector) => {
    const targetIsland = this._islands.get(connector.target.island);
    // Make the side a DoubleSidedSide when:
    if (
      instance._type === 'side' &&
      instance._key !== 'rollArm' &&
      instance._key !== 'angled' &&
      this._doubleSidedSideLayout &&
      ((connector.target === targetIsland._firstItem &&
        connector.target.getWorldSide(connector.targetLocalSide) === 'left') ||
        (connector.target === targetIsland._lastItem &&
          connector.target.getWorldSide(connector.targetLocalSide) === 'right'))
    ) {
      instance = new DoubleSidedSide(this._threekitApi);
    }

    if (connector.anytable && connector.insert) {
      // Update the connector so when adding the anytable
      // the table's long end attaches to the seat by default,
      // and short end aligns the front of seat if connecting
      // to the short edge
      const { target, targetLocalSide } = connector;
      const otherSeat = target[targetLocalSide];
      const otherConnector = {
        target: instance,
        alignment: 'bottom',
        targetLocalSide: 'bottom',
      };
      targetIsland.addItem(instance, connector);
      otherSeat._connectTo(otherConnector);

      // all following seats re-attach to the anytable
      const otherSeatConnections = otherSeat.getConnections();
      otherSeatConnections.forEach((c) => {
        if (c.target !== instance) {
          targetIsland._connectRecersive(otherSeat, c);
        }
      });

      //
    } else {
      if (connector.insert && instance._type === 'seat') {
        instance.setRotation(connector.target.rotation);
      }
      targetIsland.addItem(instance, connector);
    }

    await instance.init();
    const modelId = instance.getInstanceId();
    this._itemIds.add(modelId);

    this._tempItemInstance = null;

    /**
     * the following code are designed to handle potential connection from other side when add an item
     * however, this create a lot of problem in terms of rotation as rotation may break an exist connection
     * in this case, we will not apply any additional connection besides the default connector
     */

    if (instance._type === 'seat') {
      // Inserting a seat between a side and a seat will
      // also add a side to close the back
      let sideOfBack;
      // Read next & prev side of target's connection side
      const nextSide = NEXT_SEAT_SIDES[connector.targetLocalSide];
      const prevSide = PREVIOUS_SEAT_SIDES[connector.targetLocalSide];
      if (connector.target[nextSide]?._type === 'side') {
        sideOfBack = nextSide;
      } else if (connector.target[prevSide]?._type === 'side') {
        sideOfBack = prevSide;
      }
      const sideItem = connector.target[sideOfBack];
      if (connector.insert) {
        // if a Side is found, also create a Side to this instance on the same side
        // There is a chance target is a deep seat, needs to move it over to new item
        // ( side._disconnect(), side._connectTo() ), create a deep version and
        // attach to target
        if (sideOfBack) {
          let newSideItem;
          let newSideConnector;
          const moveToConnectorTemplate = {
            targetLocalSide: sideOfBack,
            alignment: connector.target[getAlignKeyword(sideOfBack)],
            localSide: 'bottom',
          };
          if (sideOfBack === 'left' || sideOfBack === 'right') {
            sideItem._disconnect();
            sideItem._connectTo({
              ...moveToConnectorTemplate,
              target: instance,
            });
            const newSideKey = swapSideKey(sideItem._key);
            newSideItem = new ItemMap[sideItem._type][newSideKey](
              this._threekitApi,
              {
                rotation: sideItem.rotation,
              }
            );
            newSideConnector = {
              ...moveToConnectorTemplate,
              target: connector.target,
            };
          } else {
            // create a new side the same type to sideItem and attach to instance
            newSideItem = new ItemMap[sideItem._type][sideItem._key](
              this._threekitApi,
              {
                rotation: sideItem.rotation,
              }
            );
            newSideConnector = { ...moveToConnectorTemplate, target: instance };
          }

          targetIsland.addItem(newSideItem, newSideConnector);

          await newSideItem.init();
          const newSideModelId = newSideItem.getInstanceId();
          this._itemIds.add(newSideModelId);
        }
      } else if (
        connector.target._type === 'seat' &&
        (sideOfBack === 'left' || sideOfBack === 'right') &&
        !/deep/i.test(connector.target[nextSide]?._key) &&
        connector.target[OPPOSITE_SEAT_SIDES[connector.targetLocalSide]]
          ?._type === 'seat'
      ) {
        // if the seat is adding to another seat, whose next side has a shorter edge, but
        // attached to a longer Side, swap the longer Side to a shorter deep Side
        const newSideKey = swapSideKey(sideItem._key);
        const newSideItem = new ItemMap[sideItem._type][newSideKey](
          this._threekitApi,
          {
            rotation: sideItem.rotation,
          }
        );
        let newSideConnector = {
          ...moveToConnectorTemplate,
          target: connector.target,
        };
        const moveToConnectorTemplate = {
          targetLocalSide: sideOfBack,
          alignment: connector.target[getAlignKeyword(sideOfBack)],
          localSide: 'bottom',
        };
        sideItem._disconnect();
        sideItem._connectTo({
          ...moveToConnectorTemplate,
          target: instance,
        });
        newSideConnector = {
          ...moveToConnectorTemplate,
          target: connector.target,
        };
        targetIsland.addItem(newSideItem, newSideConnector);

        await newSideItem.init();
        const newSideModelId = newSideItem.getInstanceId();
        this._itemIds.add(newSideModelId);
      }
      // handle the potential connection with other existing items
      const existItemOpenSides = this._getAllItems()
        .map((item) => {
          if (
            item === instance ||
            (item._type !== 'seat' && item._key !== 'doubleSided')
          )
            return;

          const openSides = SIDE_KEYWORD.filter((side) => item[side] === null);
          if (!openSides.length) return;

          if (item.island === targetIsland._id) return;
          return {
            targetItem: item,
            targetOpenSides: openSides.reduce(
              (map, side) =>
                Object.assign(map, {
                  [side]: item._getSideVerticesTranslation(side),
                }),
              {}
            ),
          };
        })
        .filter((item) => !!item);

      const connected = {};
      SIDE_KEYWORD.filter((side) => instance[side] === null).forEach((side) => {
        const [itemp1, itemp2] = instance
          ._getSideVerticesTranslation(side)
          .reverse();
        const sidePoint = {
          x: (itemp1.x + itemp2.x) / 2,
          y: (itemp1.y + itemp2.y) / 2,
          z: (itemp1.z + itemp2.z) / 2,
        };
        existItemOpenSides.forEach(({ targetItem, targetOpenSides }) => {
          if (connected[targetItem._id]) return;
          const connectIsland = this._islands.get(targetItem.island);
          Object.entries(targetOpenSides).forEach(
            ([targetSide, [targetp1, targetp2]]) => {
              const targetPoint = {
                x: (targetp1.x + targetp2.x) / 2,
                y: (targetp1.y + targetp2.y) / 2,
                z: (targetp1.z + targetp2.z) / 2,
              };

              const distance = Math.sqrt(
                (targetPoint.x - sidePoint.x) ** 2 +
                  (targetPoint.y - sidePoint.y) ** 2 +
                  (targetPoint.z - sidePoint.z) ** 2
              );

              const canAttach = distance < 0.0001;

              if (canAttach) {
                // two segment are parallel, then we further check if any vertex translation are match
                let alignment;
                if (isSameTranslation(itemp1, targetp1)) {
                  alignment = 'top';
                } else if (isSameTranslation(itemp2, targetp2)) {
                  alignment = 'bottom';
                }

                if (alignment) {
                  // Connect to targetItem, send all items from targetItem's island to this island
                  targetItem[targetSide] = instance;
                  targetItem[getAlignKeyword(targetSide)] = alignment;

                  instance[side] = targetItem;
                  instance[getAlignKeyword(side)] = ALIGNMENT_MAP[alignment];

                  connected[targetItem._id] = side;
                  connectIsland._items.forEach((item) => {
                    item.island = targetIsland._id;
                    targetIsland._items.push(item);
                  });
                  this._islands.delete(connectIsland._id);
                }
              }
            }
          );
        });
      });

      targetIsland._setItems(targetIsland._items);
    }

    itemAddEvent(modelId);
    this._isPresetLayout = false;

    // Check layout and pillows
    const connectedSets = getConnectedSets(this._getAllItems());
    evaluateSets(connectedSets);
    this.sets = connectedSets;
    connectedSets.map((set) => updatePillowsForSet(set));

    this.updateGeo();
    this._frameBoundingSphere();
    this.checkEligibility(null, true);

    return instance;
  };

  _getIslands = () => [...this._islands].map((pair) => pair[1]);

  _clear = () => {
    this._islands.clear();
    for (const id of this._itemIds) {
      this._threekitApi.scene.deleteNode(id);
    }
    this._itemIds.clear();
    this.clearPlusSign();
    this._setSelectItem();
  };

  _setSactionConfiguration = (sactionalConfiguration) => {
    sactionalConfiguration.forEach((islandJson) => {
      const island = new Island(this._threekitApi);
      island.fromJson(islandJson);
      this._islands.set(island._id, island);
    });
  };

  _setThreekitConfiguration = (threekitConfiguration) =>
    this._configurator.setConfiguration(threekitConfiguration);

  _initItems = async () => {
    await Promise.all(this._getIslands().map((island) => island.init()));

    this._getAllItems().forEach((item) => {
      this._itemIds.add(item.getInstanceId());
      if (!this._doubleSidedSideLayout && item._key === 'doubleSided') {
        this._doubleSidedSideLayout = true;
      }
    });

    // Check layout and pillows
    const connectedSets = getConnectedSets(this._getAllItems());
    evaluateSets(connectedSets);
    this.sets = connectedSets;
    connectedSets.map((set) => updatePillowsForSet(set));

    this._centerSactional();
    this.updateGeo();
    this._frameBoundingSphere();
    this.checkEligibility(null, true);
  };

  _checkModelOverlay = (modelId) => {
    const targetItem = this.getItemById(modelId);

    return this._getAllItems()
      .filter((item) => item !== targetItem)
      .some((item) => targetItem.intersectWith(item));
  };

  _frameBoundingSphere = () => {
    // this._threekitApi.player.cameraController.frameBoundingSphere([
    //   ...this._itemIds,
    // ]);
    // this._threekitApi.camera.frameBoundingSphere([...this._itemIds]);
    // this._threekitApi.player.cameraController.zoom(1);
    // this._threekitApi.camera.zoom(1); // not sure why but this will cause the frame during initial loading behave wrong
    const {
      fieldOfView,
    } = this._threekitApi.player.cameraController.getCameraData();

    const angleOfView = (fieldOfView / 180) * Math.PI;
    const { min, max } = this._boundingBox;
    const x = (min.x + max.x) / 2;
    const z = (min.z + max.z) / 2;

    // offset a bit
    const lengthX = max.x - min.x;
    const lengthZ = max.z - min.z;
    const rView = this._playerEl.clientWidth / this._playerEl.clientHeight;
    const rBoundingBox = lengthX / lengthZ;

    const padding = (status.measurement === 'on' ? 0.1 : 0) + 0.75;

    const newY =
      ((rBoundingBox > rView ? lengthX / rView : lengthZ) + padding) /
      Math.tan(angleOfView);

    if (newY < 0 || status.view !== 'top') {
      this._threekitApi.camera.frameBoundingSphere([...this._itemIds]);
    } else {
      this._threekitApi.camera.setPosition({ x, y: newY, z });
    }
  };

  _getBoundingBox = () =>
    [...this._islands.values()].reduce(
      (res, island) => res.union(island.getBoundingBox()),
      new this._threekitApi.THREE.Box3()
    );

  _centerSactional = () => {
    const islands = [...this._islands.values()];
    const box = this._getBoundingBox();
    const translation = box.getCenter().multiplyScalar(-1);
    islands.forEach((island) => island.applyTranslation(translation));
    return this._threekitApi.player.evaluateSceneGraph();
  };

  setStealthTech = setStealthTech;

  setSubwoofers = setSubwoofers;

  setStorageSeats = (num) => {
    if (typeof num !== 'number') {
      throw Error('Please pass a number');
    }
    status.numOfStorageSeats = num;
  };

  checkEligibility = (itemId, update = false) => {
    if (itemId && update)
      throw Error(
        'Cannot check eligibility and update status at the same time'
      );

    const islands = Array.from(this._islands.values());

    let sets = this.sets || {};

    let nodesToDelete = [];
    // This is for checking eligibility and should not update status
    if (itemId && this.getItemById(itemId)?._type !== 'anytable') {
      // Get a list of ids of the items and the island(s) if item should be deleted
      const { deletedNodes, resultIsland } = this.deleteItem(itemId, false);
      nodesToDelete = Array.from(deletedNodes).map((n) => n._id);
      islands.find((island, i) => {
        if (island._id === resultIsland._id) {
          islands[i] = resultIsland;
          return island;
        } else return false;
      });
    }

    const filter = new Set(nodesToDelete);
    const items = islands.reduce((acc, island) => {
      return acc.concat(island._items.filter((e) => !filter.has(e._id)));
    }, []);
    if (filter.size) {
      sets = getConnectedSets(Array.from(items.values()), [itemId]);
      evaluateSets(sets);
    }

    const result = getStealthTechBundlesFromSets(sets, items, update);
    const { stealthTechEligible: bundles } = result;
    // console.log(`Bundles if to remove ${itemId}`, bundles);

    if (update) {
      const sets = islands.map((island) => {
        return {
          island,
          seats: island.getSeatItems().map((i) => i._id),
          sides: island.getSideItems().map((i) => i._id),
        };
      });
      updateSets(sets);
      const stealthTechState = updateCapacity({ bundles: result });
      stealthTechState && capacityChange(stealthTechState);
      this.getLayoutJson();
    }

    return bundles;
  };

  initThreekit = async () => {
    const self = this;
    const api = this._threekitApi;

    api.enableApi('player');
    api.selectionSet.setStyle({
      outlineColor: SELECTION_COLORS.VALID,
    });

    this._configurator = await api.getConfigurator();
    api.tools.removeTool('pan');

    api.tools.addTool({
      key: 'selection',
      label: 'Selection Tool',
      active: true,
      enabled: true,
      handlers: {
        click: (ev) => {
          if (status.view === 'hero') return;

          const clickedPlusSign = getModelFromMouseEvent(ev, 'PlusIcon_');

          if (clickedPlusSign && !status.adding) {
            status.adding = true;
            try {
              const [_, jsonName] = clickedPlusSign.name.split('_');

              const connectInfo = JSON.parse(jsonName);
              if (connectInfo.target === 'new') {
                // add item at connectInfo position
                self._tempItemInstance.init().then(() => {
                  const instance = self._tempItemInstance;
                  instance.setTranslation(connectInfo.translation);
                  const modelId = instance.getInstanceId();
                  this._itemIds.add(modelId);
                  const newIsland = new Island(this._threekitApi, [instance]);
                  this._islands.set(newIsland._id, newIsland);

                  this._tempItemInstance = null;
                  itemAddEvent(modelId);
                  this._isPresetLayout = false;

                  // Check layout and pillows
                  const connectedSets = getConnectedSets(this._getAllItems());
                  evaluateSets(connectedSets);
                  this.sets = connectedSets;
                  connectedSets.map((set) => updatePillowsForSet(set));

                  this.updateGeo();
                  this._frameBoundingSphere();
                  this.checkEligibility(null, true);
                  status.adding = false;
                });
              } else {
                self
                  ._addItem(self._tempItemInstance, {
                    ...connectInfo,
                    target: self.getItemById(connectInfo.target),
                  })
                  .then(() => {
                    status.adding = false;
                  });
              }
              if (this._playerEl) this._playerEl.style.cursor = 'auto';
            } catch (error) {
              console.error(error);
              status.adding = false;
            }

            return;
          }

          const clickedModel = getModelFromMouseEvent(ev);

          if (clickedModel) {
            this.clearPlusSign();
            const hasOverlay = this._checkModelOverlay(clickedModel.nodeId);
            api.selectionSet.setStyle({
              outlineColor: hasOverlay
                ? SELECTION_COLORS.INVALID
                : SELECTION_COLORS.VALID,
            });
          }
          self._setSelectItem(clickedModel?.nodeId);
        },
        hover: (ev) => {
          if (!self._plusSignId.size || status.view === 'hero') return;

          const modelNamePrefix = 'PlusIcon_';
          const hoverModel = getModelFromMouseEvent(ev, modelNamePrefix);

          if (this._playerEl)
            this._playerEl.style.cursor = hoverModel ? 'pointer' : 'auto';
        },
      },
    });

    api.tools.addTool({
      key: 'drag',
      label: 'Drag Tool',
      active: true,
      enabled: true,
      handlers: {
        drag: (ev) => {
          /**
           * drag feature details:
           * 1. the item can attach to a valid attach position (item position fixed within snap range)
           * 2. the item can snap and move along the other piece's edge while keeping a certain distance (item can only move along one dimension), for example, if the distance is 1m, then the item can 'snap' to the segment that is 1m away from the target item's edge
           * 3. the item can move freely beyond the snap distance (move free on 2 dimension)
           *
           * approaches
           * 1. pre-calculate all attach point, and in additional, pre-calculate the item translation for each attachment. If the real time position is close, then apply attachment.
           * 2/3. pre-calculate all the edges of the existing items by using dfs to retrive the existing items and keep adding outter segment and remove inner segments. For example, start from a piece, it result to 4 segments, and if another item connect to the right, then remove the right segment of the first item, and add the additional 3 segment from the second item. And finally, a proper algorithm to union the segments.
           * 2/3. we then keep check the distance from all vertices of the select items to all the segments and use the distance to decide the snap or free move mode
           */
          const dragModel = getModelFromMouseEvent(ev);
          if (
            !self._selectedItemId ||
            self._selectedItemId !== dragModel?.nodeId
          )
            return false;
          const selectItem = self.getSelectedItem();

          // prevent moving a seat from a stealthTech product
          if (selectItem._type === 'seat') {
            const set = this.sets.find(
              (ele) => ele.mappedSeats[selectItem._id]
            );
            const inPathItems = set.paths.reduce((acc, path) => {
              path.forEach((item) => {
                if (!acc.has(item.id)) {
                  acc.add(item.id);
                }
              });
              return acc;
            }, new Set([]));

            if (inPathItems.has(selectItem._id)) return false;
          }

          // only item with one or less connection can be moved
          const hardConnections = selectItem
            .getConnections()
            .filter((connection) => {
              const { target } = connection;
              const targetHasSide = SIDE_KEYWORD.some(
                (key) => target[key]?._type === 'side'
              );
              return targetHasSide;
            });
          if (
            hardConnections.length > 1 ||
            SIDE_KEYWORD.some((side) => selectItem[side]?._type === 'side')
          )
            return false;

          const selectItemIslandId = selectItem.island;

          const curTarget =
            selectItem[SIDE_KEYWORD.find((side) => !!selectItem[side])];
          const curTargetLocalSide =
            curTarget &&
            SIDE_KEYWORD.find((side) => curTarget[side] === selectItem);
          const curTargetAlignment =
            curTarget && curTarget[getAlignKeyword(curTargetLocalSide)];

          const initConnector = curTarget && {
            target: curTarget,
            targetLocalSide: curTargetLocalSide,
            alignment: curTargetAlignment,
          };

          // disconnect the select item first in order to get correct connection point
          selectItem._disconnect();

          const initTranslation = { ...selectItem.translation };
          const initRotation = selectItem.rotation;

          const initVerticesTrans = VERTEX_KEYWORD.map((vertex) =>
            selectItem.getVertexWorldTranslation(vertex)
          );
          let targetConnector = null;
          let overlay = false;

          // get connector need to be called after disconnect
          const allConnector = self
            ._getAllConnector(selectItem._type, selectItem._key, {
              instanceRef: selectItem,
              excludes: [selectItem],
            })
            .filter((connectors) => connectors[0][0].target !== selectItem);

          const allItems = self
            ._getAllItems()
            .filter((item) => item !== selectItem);

          const flatConnectors = flatNestArray(allConnector).filter(
            (connector) => !connector.insert
          );
          const instance = self._tempItemInstance;

          const connectors = flatConnectors.map((connector) => {
            instance._connectTo(connector);
            const connectTrans = { ...instance.translation };
            const connectRotat = instance.rotation;
            instance._disconnect();
            instance.setRotation(initRotation);
            return {
              ...connector,
              translation: connectTrans,
              rotation: connectRotat,
            };
          });

          const segments = [...self._islands]
            .map(([islandId, island]) => {
              return getItemOutlineSegments(
                island._items.filter((item) => item !== selectItem)
              );
            })
            .reduce((res, segments) => res.concat(segments));

          itemMoveStartEvent(self._selectedItemId);

          const virtualPlane = new api.THREE.Plane(
            new api.THREE.Vector3(0, -1, 0)
          );
          const intersection = new api.THREE.Vector3();

          // Mouse can be off the center of an item, move the item based on
          // mouse position
          const initIntersection = new api.THREE.Vector3();
          ev.eventRay.ray.intersectPlane(virtualPlane, initIntersection);
          const initOffset = initIntersection.clone().sub(initTranslation);

          const { srcElement } = ev.originalEvent;
          return {
            handle: (ev) => {
              if (ev.originalEvent.srcElement !== srcElement) return;
              ev.eventRay.ray.intersectPlane(virtualPlane, intersection);
              intersection.sub(initOffset);

              // verify overlay
              self._tempItemInstance.setTranslation(intersection);
              selectItem.setRotation(initRotation);

              if (
                allItems.some((item) =>
                  item.intersectWith(self._tempItemInstance)
                )
              ) {
                api.selectionSet.setStyle({
                  outlineColor: SELECTION_COLORS.INVALID,
                });
                selectItem.setTranslation(intersection);
                overlay = true;
                return;
              }

              overlay = false;

              api.selectionSet.setStyle({
                outlineColor: SELECTION_COLORS.VALID,
              });

              let attachment;
              let closestDis = ATTACH_THRESHOLD;
              connectors.forEach(({ translation }, idx) => {
                const dis = Math.sqrt(
                  (translation.x - intersection.x) ** 2 +
                    (translation.z - intersection.z) ** 2
                );

                if (dis <= closestDis) {
                  closestDis = dis;
                  attachment = connectors[idx];
                }
              });

              if (attachment) {
                targetConnector = attachment;
                selectItem.setTranslation(attachment.translation);
                selectItem.setRotation(attachment.rotation);
                return;
              }

              // free move or snap move mode
              const moveTrans = {
                x: intersection.x - initTranslation.x,
                y: 0,
                z: intersection.z - initTranslation.z,
              };
              const verticesTrans = initVerticesTrans.map((vertexTrans) => {
                const copy = { ...vertexTrans };
                copy.x += moveTrans.x;
                copy.z += moveTrans.z;

                return copy;
              });

              const paris = findClosetSegmentPointPair(
                segments,
                verticesTrans,
                api.THREE.Line3,
                api.THREE.Vector3
              );
              const offset = getTranslationOffset(paris);
              intersection.x += offset.x;
              intersection.z += offset.z;

              selectItem.setTranslation(intersection);
              targetConnector = null;
            },
            onEnd: () => {
              self._isPresetLayout = false;
              self._tempItemInstance = null;

              const selectedItemIsland = self._islands.get(selectItemIslandId);
              selectItem.setRotation(initRotation);

              if (
                overlay ||
                (selectItem._type === 'side' && !targetConnector)
              ) {
                if (initConnector) {
                  selectItem._connectTo(initConnector);
                } else {
                  selectItem.setTranslation(initTranslation);
                  selectItem.island = selectItemIslandId;
                }

                api.selectionSet.setStyle({
                  outlineColor: SELECTION_COLORS.VALID,
                });
              } else if (targetConnector) {
                const targetIsland = self._islands.get(
                  targetConnector.target.island
                );

                // update the drag item island
                targetIsland.pushItem(
                  selectedItemIsland.popItem(selectItem),
                  targetConnector
                );
              } else {
                const newIsland = new Island(api, [
                  selectedItemIsland.popItem(selectItem),
                ]);
                self._islands.set(newIsland._id, newIsland);
              }

              // if the selectItem is the only item in its island, delete the island
              if (selectedItemIsland._items.length === 0) {
                self._islands.delete(selectItemIslandId);
              }

              itemMoveEndEvent(
                self._selectedItemId,
                targetConnector && targetConnector.target.getInstanceId()
              );

              // Check layout and pillows
              const connectedSets = getConnectedSets(this._getAllItems());
              evaluateSets(connectedSets);
              this.sets = connectedSets;
              connectedSets.map((set) => updatePillowsForSet(set));

              this.updateGeo();
              this._frameBoundingSphere();
              this.checkEligibility(null, true);
            },
          };
        },
      },
    });

    await this._initItems();
    await this.setCameraView('top');

    window.addEventListener('convertSide', (e) => {
      const { prevSideId, newSideId } = e.detail;

      this._itemIds.delete(prevSideId);
      this._itemIds.add(newSideId);
    });
  };

  deleteItem = (optionalItemId, update = true) => {
    const item = this.getItemById(optionalItemId || this._selectedItemId);
    if (!item) return;

    const itemId = item.getInstanceId();

    if (item._type === 'seat') {
      const allSeats = this._getAllItems().filter(
        (ite) => ite._type === 'seat'
      );
      if (allSeats.length === 1) {
        itemDeleteErrorEvent(
          itemId,
          'The configuration must have at least one seat'
        );
        return;
      }
    }

    const itemIsland = this._islands.get(item.island);

    const {
      isolateSets,
      snapTranslation,
      deletedNodes,
      resultIsland,
    } = itemIsland.deleteItem(item, update);

    if (!update) {
      return { deletedNodes, resultIsland };
    }

    this._itemIds.delete(itemId);

    if (!itemIsland._items.length) {
      this._islands.delete(itemIsland._id);
    }
    if (itemId === this._selectedItemId) {
      this._setSelectItem();
    }

    if (isolateSets.length > 1) {
      // this means the remaining items will split into more than one isolated islands
      // the translation of the remaining items will not be changed
      for (let idx = 0; idx < isolateSets.length; ++idx) {
        if (isolateSets[idx].has(itemIsland._firstItem)) continue;

        const items = [...isolateSets[idx]];
        const newIsland = new Island(this._threekitApi, items);
        this._islands.set(newIsland._id, newIsland);
        items.forEach((removeItem) => {
          const itemIdx = itemIsland._items.indexOf(removeItem);
          itemIsland._items.splice(itemIdx, 1);
        });
      }
    } else if (this._islands.has(itemIsland._id) && this._islands.size > 1) {
      // in this case, the items in the island where delete happened will re-arrange to maintain the connection
      // logic to handle the potential overlay with other islands after rearrangement.

      // the checkedIslands save all the islands so far that does not have any intersection
      // for each new island going to check, we will compare it with the islands in checkedIslands
      // if no overlay found, simply push it to the checkedIslands
      // otherwise we need to calculate the translation to apply to make sure it does not overlay with any checked island
      // Unlike the drag/drop overlay check, which using the exact shape of the item, the translation calculate will be calculated with boundingbox approximation
      const checkedIslands = [itemIsland];

      for (const targetIsland of this._islands.values()) {
        if (targetIsland === itemIsland) continue;

        const hasIntersect = checkedIslands.some((checkedIsland) =>
          targetIsland.intersectWithIsland(checkedIsland)
        );

        if (!hasIntersect) {
          checkedIslands.push(targetIsland);
          continue;
        }

        const validTranslation = findIslandValidTranslation(
          targetIsland,
          checkedIslands,
          DRAG_UNIT_DISTANCE / 2
        );

        // try to move the intersected target island match with the rearange movement
        const shiftTranslation = {};
        let primaryDirection =
          Math.abs(snapTranslation.x) > Math.abs(snapTranslation.z) ? 'x' : 'z';
        let primaryTranslation =
          validTranslation[primaryDirection][
            snapTranslation[primaryDirection] > 0 ? 'positive' : 'negitive'
          ];

        // however, if the move along primary direction is too large and not make sense, for example
        // the overlay may due to the delete of a wedge case that result the rotation of the rest of the connect piece
        // another example is delete the middle piece from a U shape sectional and moving the island along the re-arrange translation will cause intersect with the other arm
        // we will need to move the item in the vertical direction instead, the factor 1.2 may need to verify with more test case
        if (
          Math.abs(primaryTranslation) >
          Math.abs(snapTranslation[primaryDirection]) + DRAG_UNIT_DISTANCE
        ) {
          primaryDirection = primaryDirection === 'x' ? 'z' : 'x';
          const primaryTransData = validTranslation[primaryDirection];
          primaryTranslation =
            Math.abs(primaryTransData.positive) >
            Math.abs(primaryTransData.negitive)
              ? primaryTransData.negitive
              : primaryTransData.positive;
        }
        shiftTranslation[primaryDirection] = primaryTranslation;
        targetIsland.applyTranslation(shiftTranslation);
      }
    }

    this._isPresetLayout = false;
    itemDeleteEvent(itemId);

    // Check layout and pillows
    const connectedSets = getConnectedSets(this._getAllItems());
    evaluateSets(connectedSets);
    this.sets = connectedSets;
    connectedSets.map((set) => updatePillowsForSet(set));

    this.updateGeo();
    this._frameBoundingSphere();
    this.checkEligibility(null, true);
  };

  getSelectedItem = () =>
    this._selectedItemId && this.getItemById(this._selectedItemId);

  getItemById = (instanceId) => {
    let item;
    let islandIdx = 0;
    const islands = this._getIslands();
    while (!item && islandIdx < islands.length) {
      item = islands[islandIdx++].getItemByInstanceId(instanceId);
    }

    return item || null;
  };

  setCameraView = async (view) => {
    const api = this._threekitApi;
    if (view !== undefined) {
      let camId;
      if (/perspective|hero/i.test(view)) {
        if (status.view === 'hero') return;
        api.tools.removeTool('customPan');
        camId = await getCameraId(HERO_CAM, api);
        status.view = 'hero';
        this._selectedItemId && this._setSelectItem();
        this.clearPlusSign();
      } else if (/orthographic|top/i.test(view)) {
        if (status.view === 'top') return;
        let cameraName = ORTHO_CAM;
        if (status.isMobile) {
          cameraName += ' Mobile';
          api.tools.addTool(this._mobileCustomPan);
        }
        camId = await getCameraId(cameraName, api);
        status.view = 'top';
      }
      api.player.cameraController.setActiveCamera(camId);
      await this._centerSactional();
      this.updateGeo();
      this._frameBoundingSphere();
      updateSets();
    }
  };

  setConfiguration = (configuration) =>
    this._configurator.setConfiguration(configuration);

  getConfiguration = () => this._configurator.getConfiguration();

  toObject = () => {
    return {
      threekitConfiguration: this._configurator.getConfiguration(),
      sactionalConfiguration: [...this._islands].map(([islandId, island]) =>
        island.toJson()
      ),
      configurationVersion: CONFIGURATOR_VERSION,
    };
  };

  toJson = () => {
    console.log('Convert state to json');
    // implement the logic to convert the configuration into json
    return JSON.stringify(this.toObject());
  };

  fromJson = async (json) => {
    if (!json) {
      return;
    }
    if (!this._configurator) {
      throw new Error('Please call and await initThreekit first');
    }
    const { sactionalConfiguration, threekitConfiguration } = JSON.parse(json);
    this._setSactionConfiguration(sactionalConfiguration);
    this._setThreekitConfiguration(threekitConfiguration);
    return this._initItems();
  };

  getLayoutJson = () => {
    const items = this._getAllItems().map((item) => {
      const itemConfigiuration = item._configurator.getConfiguration();
      const obj = {
        type: item._type,
        key: item._keyAlians || item._key,
        translation: item.translation,
        rotation: { x: 0, y: -item.rotation, z: 0 },
        configuration: {
          PillowPosition: itemConfigiuration.PillowPosition,
          BackPillow: itemConfigiuration.BackPillow,
          PillowBack: itemConfigiuration.PillowBack,
        },
      };
      if (item._type === 'side') {
        obj.style = item.style;
      }
      return obj;
    });
    const Layout = JSON.stringify({ items });
    this.setConfiguration({ Layout });
    return Layout;
  };

  renderPlusSign = async (type, key) => {
    if (status.view === 'hero') return;
    if (this._plusSignId.size) this.clearPlusSign();
    if (this._selectedItemId) this._setSelectItem();

    setLineOffsets(0.15);

    const allConnectors = this._getAllConnector(type, key, {
      insertAtEnd: true,
      autoRotate: true,
    });

    const plusSignIds = await renderPlusIcon(this._threekitApi, allConnectors);
    for (const id of plusSignIds) {
      this._plusSignId.add(id);
    }

    if (type === 'anytable' && this._islands.size === 1) {
      const { THREE } = this._threekitApi;
      const size = {
        width: this._tempItemInstance.width,
        depth: this._tempItemInstance.height,
      };

      const allItems = this._getAllItems();
      const island = this._getIslands()[0];
      // try get the bounding box of island
      const boundingBox = island.getBoundingBox();
      const { min, max } = boundingBox;

      const rIsland = (max.x - min.x) / (max.z - min.z);
      const rView = this._playerEl.clientWidth / this._playerEl.clientHeight;

      // if there is more space on left/right
      const hasSideSpace = rView > rIsland;

      let position;
      allConnectors.find((itemConnectors) => {
        return itemConnectors.find((sideConnectors) => {
          const {
            target,
            targetLocalSide,
            insert,
            translation,
          } = sideConnectors[0];
          if (
            !insert &&
            target._type === 'seat' &&
            target[OPPOSITE_SEAT_SIDES[targetLocalSide]]?._type !== 'seat' &&
            SIDE_KEYWORD.reduce((count, s) => {
              if (target[s]) count++;
              return count;
            }, 0) > 1
          ) {
            const v1 = new THREE.Vector3().add(target.translation);
            const v2 = new THREE.Vector3().add(translation);

            const direction = new THREE.Vector3().subVectors(v2, v1);
            direction.normalize();
            direction.multiplyScalar(this._tempItemInstance.height / 2 + 0.05);
            position = direction.add(v2);
          }
          return position;
        });
      });

      // check mid bottom
      if (!position) {
        const translation = {
          x: (min.x + max.x) / 2,
          y: 0,
          z: max.z - size.depth / 2,
        };

        this._tempItemInstance.setTranslation(translation);
        if (
          !allItems.some((item) => item.intersectWith(this._tempItemInstance))
        ) {
          position = translation;
        }
      }

      if (!position) {
        // check corners if there is space
        ['bottomLeft', 'bottomRight', 'topRight', 'topLeft'].find((corner) => {
          const translation = getPosition(boundingBox, size, corner);
          if (translation) {
            this._tempItemInstance.setTranslation(translation);
            if (
              !allItems.some((item) =>
                item.intersectWith(this._tempItemInstance)
              )
            ) {
              position = translation;
              return translation;
            }
          }
        });
        // if not push the + outside the bounding box
        const translation = {
          x: hasSideSpace ? min.x - size.width / 2 - 0.05 : (min.x + max.x) / 2,
          y: 0,
          z: hasSideSpace ? (min.z + max.z) / 2 : max.z + size.depth / 2 + 0.05,
        };
        position = translation;
      }
      // add an anytable as coffee table
      if (position) {
        const id = await renderPlusIconAtPosition(this._threekitApi, position);
        this._plusSignId.add(id[0]);
      }
    }

    this._frameBoundingSphere();
  };

  toggleMeasurement = (state) => {
    status.measurement = state;
    state === 'on' ? enableMeasurement() : disableMeasurement();

    this._frameBoundingSphere();
  };

  clearPlusSign = () => {
    for (const id of this._plusSignId) {
      this._threekitApi.scene.deleteNode(id);
    }
    this._plusSignId.clear();
    this._tempItemInstance = null;
    const allSeats = this._getAllItems().filter(
      (item) => item._type === 'seat'
    );
    allSeats.forEach((item) => {
      item.connectors = {};
    });
    setLineOffsets(0);
    this._frameBoundingSphere();
  };

  setFloor = (floorName) => setFloorV1(floorName);

  setFabric = (fabricCode) => {
    if (!this._configurator) return;
    const sceneFabric = baFabricToSceneConfig({
      soft: fabricCode,
      hard: fabricCode,
      trim: fabricCode,
    });
    const tkConfig = mapSceneConfigurationToTKConfiguration(sceneFabric);
    this._configurator.setConfiguration({
      ...tkConfig,
      Fabric: tkConfig.Soft,
    });
  };

  setWood = (woodName) => {
    const customId = woodName.toLowerCase().replace(/ /g, '_');
    return this._configurator.setConfiguration({
      Wood: { customId },
    });
  };

  //
  setProduct = async (productId, options = {}) => {
    console.log('Set product', productId, options);
    this._clear();
    this._isPresetLayout = true;
    const res = await getSavedConfiguration(productId);
    const { ArmType, BackType } = options;

    const { configurationVersion } = res.variant;
    if (configurationVersion < 3) {
      const { sactionalConfiguration, threekitConfiguration } = res.variant;
      this._setSactionConfiguration(sactionalConfiguration);
      await this._setThreekitConfiguration(threekitConfiguration);
      await this._initItems();
      await this._threekitApi.player.evaluateSceneGraph();

      if (ArmType || BackType) {
        const result = JSON.stringify(
          this.convertSidesTypes({ ArmType, BackType })
        );

        this._clear();
        await this.fromJson(result);
      }
    } else {
      const result = JSON.stringify(
        this.convertSidesTypes({ ArmType, BackType }, res.variant)
      );

      await this.fromJson(result);
    }
    await this._threekitApi.player.evaluateSceneGraph();

    const arms = new Set([]);
    const backs = new Set([]);
    this._getAllItems().forEach((item) => {
      if (item._type === 'side') {
        let key = item._keyAlians || item._key;
        if (item.style === 'arm' && !arms.has(key)) {
          arms.add(key);
        } else {
          // convert deep keys to standard ones
          if (key === 'deep') {
            key = 'standard';
          } else if (key === 'deepAngled') {
            key = 'angled';
          }
          if (!backs.has(key)) {
            backs.add(key);
          }
        }
      }
    });
    status.ArmType =
      SIDE_TYPE_KEYS[ArmType] ||
      (arms.size > 1
        ? 'mix'
        : arms.size === 0
        ? 'standard'
        : Array.from(arms.keys())[0]);
    status.BackType =
      SIDE_TYPE_KEYS[BackType] ||
      (backs.size > 1
        ? 'mix'
        : backs.size === 0
        ? 'standard'
        : Array.from(backs.keys())[0]);
  };

  /**
   *
   * @param {string} type Standard | Angled
   */
  setBackType = async (type) => {
    const notChanged = status.BackType === SIDE_TYPE_KEYS[type];
    console.log('Set back type', type, '. Not changed:', notChanged);
    if (notChanged) return;
    const backKey = SIDE_TYPE_KEYS[type];
    if (!backKey || /rollarm/i.test(type))
      throw new Error('Incorrect back type');

    const result = JSON.stringify(this.convertSidesTypes({ BackType: type }));

    this._clear();
    await this.fromJson(result);
    await this._threekitApi.player.evaluateSceneGraph();
    status.BackType = type;
  };

  /**
   *
   * @param {string} type Standard | Rollarm | Angled
   */
  setArmType = async (type) => {
    const notChanged = status.ArmType === SIDE_TYPE_KEYS[type];
    console.log('Set arm type', type, '. Not changed:', notChanged);
    if (notChanged) return;
    const armKey = SIDE_TYPE_KEYS[type];
    if (!armKey) throw new Error('Incorrect arm type');

    const result = JSON.stringify(this.convertSidesTypes({ ArmType: type }));

    this._clear();
    await this.fromJson(result);
    await this._threekitApi.player.evaluateSceneGraph();
    status.ArmType = type;
  };

  convertSidesTypes = ({ ArmType, BackType }, configuration) => {
    const armKey = SIDE_TYPE_KEYS[ArmType];
    const backKey = SIDE_TYPE_KEYS[BackType];

    const { threekitConfiguration, sactionalConfiguration } =
      configuration || this.toObject();

    const layout = JSON.parse(threekitConfiguration.Layout);

    const replaceArm = (item) => {
      if (item._key === 'doubleSided' && item.top && item.bottom) return;
      if (item._type === 'side' && item.style === 'arm') {
        item._key = armKey;
        delete item._keyAlians;
      }
    };
    const replaceBack = (item) => {
      if (item._key === 'doubleSided' && item.top && item.bottom) return;
      if (item._type === 'side' && item.style === 'back') {
        // the item may be a deep side, convert to corresponding
        // deep version if true
        const newKey = /deep/i.test(item._key)
          ? DEEP_SIDE_KEYS[backKey]
          : backKey;
        if (newKey) {
          item._key = newKey;
          delete item._keyAlians;
        } else {
          console.warn('Cannot convert deep side', BackType);
        }
      }
    };

    const convertedSactionalConfig = sactionalConfiguration.map(
      (sactionalJson) => {
        const sactional = JSON.parse(sactionalJson);
        sactional._items.forEach((item) => {
          if (armKey) {
            replaceArm(item);
          }
          if (backKey) {
            replaceBack(item);
          }
        });
        return JSON.stringify(sactional);
      }
    );
    layout.items.forEach((item) => {
      if (armKey) {
        replaceArm(item);
      }
      if (backKey) {
        replaceBack(item);
      }
    });

    return {
      threekitConfiguration,
      sactionalConfiguration: convertedSactionalConfig,
    };
  };

  incrementItemRotation = async () => {
    if (!this._selectedItemId) return;
    const item = this.getItemById(this._selectedItemId);

    await this._islands.get(item.island).incrementItemRotation(item);

    const hasOverlay = this._checkModelOverlay(item.getInstanceId());
    this._threekitApi.selectionSet.setStyle({
      outlineColor: hasOverlay
        ? SELECTION_COLORS.INVALID
        : SELECTION_COLORS.VALID,
    });
  };

  isPresetLayout = () => this._isPresetLayout;

  updateGeo = () => {
    // Update bounding box info
    this._boundingBox = this._getBoundingBox();
    const { min, max } = this._boundingBox;
    const xAxis = new this._threekitApi.THREE.Vector3(1, 0, 0);
    const offset = new this._threekitApi.THREE.Vector3().add({
      x: -(min.x + max.x) / 2,
      y: -(min.y + max.y) / 2,
      z: -(min.z + max.z) / 2,
    });

    this._islands.forEach((island, key) => {
      const { position } = island.updatePosition();
      if (this._islands.size === 1) {
        island.angleToX = 0;
        return;
      }
      const v3 = new this._threekitApi.THREE.Vector3().add(position);
      v3.add(offset);

      const angleTo = (v3.angleTo(xAxis) * 180) / Math.PI;
      const angle = v3.z > 0 ? 360 - angleTo : angleTo;

      island.angleToX = angle;
    });

    const arms = new Set([]);
    const backs = new Set([]);
    this._getAllItems().forEach((item) => {});
    const allItems = this._getAllItems();
    const itemsByType = allItems.reduce((acc, item) => {
      const type = item._type;
      if (!acc[type]) {
        acc[type] = [];
      }

      if (type === 'side') {
        let key = item._keyAlians || item._key;
        if (item.style === 'arm' && !arms.has(key)) {
          arms.add(key);
        } else {
          // convert deep keys to standard ones
          if (key === 'deep') {
            key = 'standard';
          } else if (key === 'deepAngled') {
            key = 'angled';
          }
          if (!backs.has(key)) {
            backs.add(key);
          }
        }
      }
      acc[type].push(item);
      return acc;
    }, {});

    status.ArmType =
      arms.size > 1
        ? 'mix'
        : arms.size === 0
        ? 'standard'
        : Array.from(arms.keys())[0];
    status.BackType =
      backs.size > 1
        ? 'mix'
        : backs.size === 0
        ? 'standard'
        : Array.from(backs.keys())[0];

    Object.entries(itemsByType).forEach(([type, array]) => {
      if (status.counts[type] === undefined) {
        status.counts[type] = 0;
        array.sort((a, b) => {
          if (Math.abs(a.translation.x - b.translation.x) < 0.001) {
            return a.translation.z - b.translation.z;
          } else {
            return a.translation.x - b.translation.x;
          }
        });
      }
      array.forEach((item, index) => {
        if (item._index < 0 || item._index === undefined) {
          item._index = status.counts[type]++;
        }
      });
    });
    this.itemsByType = itemsByType;
  };

  getItemByTypeIndex = (type, index) => {
    if (!this.itemsByType[type] || !this.itemsByType[type][index]) {
      throw new Error('Cannot find item by type:', type, 'or by index:', index);
    }
    return this.itemsByType[type][index];
  };

  /**
   * With an item selected by user, add it to given target's side
   * @param {object} target
   * @param {string} side
   */
  addToItemBySide = (target, side) => {
    try {
      if (target && side) {
        if (target.connectors[side]) {
          const localSide = target.getLocalSide(side);
          const connector = target.connectors[localSide][0];
          return this._addItem(this._tempItemInstance, connector);
        } else console.warn('Failed adding item - No connector available');
      }
    } catch (error) {
      throw new Error('Cannot add new item to given target or to its side');
    }
  };

  selectItem = (instance) => {
    if (instance && instance._id) {
      this.clearPlusSign();
      const hasOverlay = this._checkModelOverlay(instance._id);
      this._threekitApi.selectionSet.setStyle({
        outlineColor: hasOverlay
          ? SELECTION_COLORS.INVALID
          : SELECTION_COLORS.VALID,
      });
    }
    return this._setSelectItem(instance?._id);
  };
}
