/* global __THREEKIT_AUTH_TOKEN__,  __THREEKIT_HOST__, __THREEKIT_ORG__ */

import { getFloor } from '../room';
import { getItems } from '../items';
import { getAttachments, decomposeForTransformPlug } from '../placement';
import { getWorldTransform } from '../../helpers';

export async function saveConfiguration() {
  const configuration = getConfigFromState();
  const { id } = await postConfiguration(configuration);
  return id;
}

const postConfiguration = (configuration) => {
  const url = `${__THREEKIT_HOST__}/api/configurations?bearer_token=${__THREEKIT_AUTH_TOKEN__}`;

  const body = new FormData();
  body.append('orgId', __THREEKIT_ORG__);
  body.append('productId', window.threekit.api.assetId);
  // TO DO: what should productVersion be?
  body.append('productVersion', 'test_version');
  body.append('variant', JSON.stringify(configuration));

  return fetch(url, {
    method: 'post',
    body,
  })
    .then((res) => res.json())
    .catch((e) => console.error(e));
};

export const getSavedConfiguration = (configId) => {
  const url = `${__THREEKIT_HOST__}/api/configurations/${configId}?bearer_token=${__THREEKIT_AUTH_TOKEN__}`;

  return fetch(url, {
    method: 'get',
  })
    .then((res) => res.json())
    .catch((e) => console.error(e));
};

const getConfigFromState = () => {
  const floor = getFloor();
  const items = [];
  const attachments = Object.values(getAttachments());

  getItems().forEach((value, itemId) => {
    const item = { ...value };
    // It is necessary to store items' world transforms rather than local
    // transforms, since accessories are reparented to the items they are
    // attached to
    const worldTransform = getWorldTransform(itemId);
    const { translation, rotation } = decomposeForTransformPlug(worldTransform);
    item.position = {
      translation,
      rotation,
    };
    items.push(item);
  });

  return { floor, items, attachments };
};
