import { exportAR } from '../platform';

export async function getAR(options = {}) {
  const { id, url } = options;
  if (id && url) {
    const result = await exportAR(options, 'v1');
    return result;
  } else {
    const configuration = window.threekit.playerConfigurator.getConfiguration();
    const result = await exportAR({ configuration }, 'v1');
    return result;
  }
}
