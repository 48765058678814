/* global __THREEKIT_AUTH_TOKEN__ */

import { initPlayer } from './initPlayer';
import { getInitialConfiguration } from './persistence';
import publicApi from './api';
import { addCapacityListener } from './items';

const getAsset = (productCategory) => {
  switch (productCategory) {
    case 'sac':
      return {
        assetId: '5199135e-e3d2-4c97-9203-5422f84dada3',
        stageId: '83b2944e-a0d0-4707-8cfe-6708f713f996',
      };
    case 'squattoman':
    case 'throwPillow':
      return {
        assetId: '73c831bf-d3e1-4ebc-aaf9-ea4c790505a9',
      };
    case 'sactionalCovers':
      return {
        assetId: '6d4d2200-ee9e-4454-81c0-53495d7872d2',
      };
    // Sactional
    case 'outdoorSactional':
      return {
        assetId: '773c2524-057e-4776-b28e-86828d4df852',
      };
    case 'indoorSactional':
    default:
      return {
        assetId: 'e818aa0c-867f-4842-829c-05f6a4e91cb7',
      };
  }
};

export async function init(params) {
  console.log('Threekit: configurator init start');
  const { el, onCapacityChange, productCategory, analyticsCustomId } = params;
  // TODO: Clear or reuse any previous configurator

  const initialConfiguration = await getInitialConfiguration(params);
  const { assetId, stageId } = getAsset(productCategory);

  const playerApi = await initPlayer(
    el,
    __THREEKIT_AUTH_TOKEN__,
    assetId,
    stageId,
    initialConfiguration,
    { analyticsCustomId }
  );

  // window.threekit.api = playerApi;
  // window.threekit.configurator = publicApi;

  if (onCapacityChange) addCapacityListener(onCapacityChange, true);

  console.log('Threekit: configurator init complete');
  return playerApi;
}
