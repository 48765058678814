import {
  SEAT_HEIGHT,
  SEAT_WIDTH,
  SIDE_MAP,
  PLUS_SIGN_DISTANCE,
} from '../constants';
import { getAlignKeyword, getNextValidLocalSide } from '../utils';
import Item from './Item';

export default class WedgeSeat extends Item {
  constructor(threekitApi, options = {}) {
    super(threekitApi, options);

    this._type = 'seat';
    this._key = 'wedge';
    this.width = SEAT_WIDTH;
    this.height = SEAT_HEIGHT; // this is not the actually height, it is the length of the side of the wedge seat
    this._seatHeight = 0.68; // precalculated actual height
    this._seatShortWidth = 0.32;
    this._seatLongWidth = 0.88;

    // sepecial case for wedgeseat, it define the rotaion different between different side
    this._leftRotation = -22.5;
    this._rightRotation = 22.5;

    this._initAlignmentWithConnector(options.connector);
  }

  // wedge seat does not have bottom side
  _initAlignmentWithConnector = (connector) => {
    this.left = null;
    this.right = null;
    this.top = null;
    this.leftAlign = null;
    this.rightAlign = null;
    this.topAlign = null;
    this.island = null;

    if (connector) {
      this._connectTo(connector);
    }
  };

  _getVertexLocalTranslation = (localPosition, adjustVal = 0) => {
    let xLocalOffset;
    let zLocalOffset;

    const shortWidth = this._seatShortWidth - adjustVal;
    const longWidth = this._seatLongWidth - adjustVal;
    const height = this._seatHeight - adjustVal;

    switch (localPosition) {
      case 'topLeft':
        xLocalOffset = -longWidth / 2;
        zLocalOffset = -height / 2;
        break;
      case 'topRight':
        xLocalOffset = longWidth / 2;
        zLocalOffset = -height / 2;
        break;
      case 'bottomLeft':
        xLocalOffset = -shortWidth / 2;
        zLocalOffset = height / 2;
        break;
      case 'bottomRight':
        xLocalOffset = shortWidth / 2;
        zLocalOffset = height / 2;
        break;
      default:
    }

    return { x: xLocalOffset, y: 0, z: zLocalOffset };
  };

  _connectTo = (connector) => {
    const { target, targetLocalSide, localSide, insert } = connector;

    let curConnectItem;
    let curItemNewConnector;

    // if the target's side already have item C connect, means this is a insert, we will connect his item to the target item's side, and reconnect that C item to this item on the same side as the target side

    if (insert) {
      curConnectItem = target[targetLocalSide];
      if (curConnectItem._type !== 'side') {
        throw new Error(
          'Invalid insert connection, the exist connect item must be side!'
        );
      }
      curItemNewConnector = {
        target: this,
        targetLocalSide: target.getWorldSide(targetLocalSide),
        alignment: target[getAlignKeyword(targetLocalSide)],
      };
    }

    let thisLocalSide = localSide;

    if (!thisLocalSide) {
      const targetWorldSide = target.getWorldSide(targetLocalSide);
      const thisWorldSide = SIDE_MAP[targetWorldSide];
      thisLocalSide = this.getLocalSide(thisWorldSide);
    }

    if (!this.hasOwnProperty(thisLocalSide)) {
      thisLocalSide = getNextValidLocalSide(this, thisLocalSide);
    }

    this._connectHelper({ ...connector, localSide: thisLocalSide });

    if (curConnectItem) {
      curConnectItem._connectTo(curItemNewConnector);
    }

    this._updatePillow();
  };

  getPlusSignTranslation = (localSide, offset = 0) => {
    if (!this.width || !this.height)
      throw new Error(
        'You are calling an absolute method, please overwirte it or define the width and height in the extend class'
      );

    let dx = 0;
    let dz = 0;

    const distance = PLUS_SIGN_DISTANCE + offset;

    switch (localSide) {
      case 'left':
        dx =
          -(this._seatShortWidth + this._seatLongWidth) / 4 -
          distance * Math.cos((this._rightRotation / 180) * Math.PI);
        dz = distance * Math.sin((this._rightRotation / 180) * Math.PI);
        break;
      case 'right':
        dx =
          (this._seatShortWidth + this._seatLongWidth) / 4 +
          distance * Math.cos((this._rightRotation / 180) * Math.PI);
        dz = distance * Math.sin((this._rightRotation / 180) * Math.PI);
        break;
      case 'top':
        dz = -this.height / 2 - distance;
        break;
      default:
        throw new Error(`Unknown side ${localSide}`);
    }

    return this.getWorldTranslation({ x: dx, y: 0, z: dz });
  };

  validateConnection = (item) => {
    return !(item._type === 'anytable' || /angled/i.test(item._key));
  };
}
