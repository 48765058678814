// import { fabricMap } from './fabrics';
import { setConfigOnModel } from '../../helpers';

function applyConfiguration(item) {
  // const { id } = item;
  // const { fabric } = item.configuration;
  // if (fabric) {
  //   const { soft, trim } = fabric;
  //   const threekitConfig = {};
  //   if (fabric.hasOwnProperty('soft'))
  //     threekitConfig.Soft = { assetId: fabricMap[soft] };
  //   if (fabric.hasOwnProperty('trim')) {
  //     if (trim) threekitConfig.Trim = { assetId: fabricMap[trim] };
  //     else if (fabric.hasOwnProperty('soft'))
  //       threekitConfig.Trim = { assetId: fabricMap[soft] };
  //   }
  //   return setConfigOnModel(id, threekitConfig);
  // }
}

export const throwPillow = {
  '18x18': { assetName: 'Throw Pillow 18x18', applyConfiguration },
  '24x16': { assetName: 'Throw Pillow 24x16', applyConfiguration },
  '24x24': { assetName: 'Throw Pillow 24x24', applyConfiguration },
};
