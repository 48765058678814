import { CAMERA_ROTATIONS, tools } from './rotateTool';

export function rotateCamera(view) {
  if (view !== undefined) {
    window.threekit.playerConfigurator.setConfiguration({
      Rotation: view,
    });
    return;
  }
  const currentRotation = window.threekit.playerConfigurator.getConfiguration()
    .Rotation;

  const index = CAMERA_ROTATIONS.indexOf(currentRotation);
  const newIndex = (index + 1) % CAMERA_ROTATIONS.length;
  const newRotationValue = CAMERA_ROTATIONS[newIndex];
  window.threekit.playerConfigurator.setConfiguration({
    Rotation: newRotationValue,
  });
}

export function setCameraTool(options = {}) {
  const { key, enabled } = options;
  if (!key || !tools[key]) throw new Error('Cannot find tool for ', key);

  tools[key].enabled = !!enabled;
}

export function setCameraZoomFactor(zoomFactor = 1) {
  const playerRect = window.threekit.threekitEl.getBoundingClientRect();
  window.threekit.api.enableApi('player').translator.zoom(
    zoomFactor / 2, // the platform default zoom factor is 0.5, apply /2 to convert to a default factor of 1
    playerRect.width / 2 + playerRect.left,
    playerRect.height / 2 + playerRect.top
  );
}
