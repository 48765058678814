import { setConfigOnModel } from '../../helpers';
// import { fabricMap } from './fabrics';

async function applyConfiguration(item) {
  // const { id } = item;
  // const { fabric } = item.configuration;
  // if (fabric) {
  //   const threekitConfig = { Fabric: { assetId: fabricMap[fabric] } };
  //   return setConfigOnModel(id, threekitConfig);
  // }
}

export const squattoman = {
  squattoman: {
    assetName: 'Squattoman',
    applyConfiguration,
    seatingCapacity: 1,
  },
};
