import { setConfigOnModel } from '../../helpers';
// import { fabricMap } from './fabrics';

async function applyConfiguration(item) {
  // const { id } = item;
  // const { fabric } = item.configuration;
  // if (fabric) {
  //   const threekitConfig = { Fabric: { assetId: fabricMap[fabric] } };
  //   return setConfigOnModel(id, threekitConfig);
  // }
}

export const sacItems = {
  gamerSac: { assetName: 'Gamersac', applyConfiguration, seatingCapacity: 1 },
  citySac: { assetName: 'Citysac', applyConfiguration, seatingCapacity: 1 },
  pillowSac: {
    assetName: 'PillowSac',
    applyConfiguration,
    seatingCapacity: 2,
  },
  movieSac: { assetName: 'MovieSac', applyConfiguration, seatingCapacity: 2 },
  superSac: { assetName: 'Supersac', applyConfiguration, seatingCapacity: 3 },
  bigOne: { assetName: 'BigOne', applyConfiguration, seatingCapacity: 4 },
};
