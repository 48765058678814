import {
  ALL_SEAT_SIDES,
  OPPOSITE_SEAT_SIDES,
  PREVIOUS_SEAT_SIDES,
  NEXT_SEAT_SIDES,
} from '../../constants';
import { getBundleFromSet, ERROR_CODES } from '../stealthTech';
import { status } from '../../../status';

const sortSeatsAttachments = (seat, ignoreItemSet = new Set([])) => {
  const connections = ALL_SEAT_SIDES.filter((side) => {
    return !!seat[side];
  }).map((localSide) => ({
    localSide,
    target: seat[localSide],
    targetLocalSide: ALL_SEAT_SIDES.find(
      (side) => seat[localSide][side] === seat
    ),
  }));
  const result = {
    seats: [],
    sides: [],
    anytables: [],
  };

  connections.forEach((connection) => {
    const key = `${connection.target._type}s`;
    if (
      result[key] !== undefined &&
      !ignoreItemSet.has(connection.target._id)
    ) {
      result[key].push(connection);
    }
  });

  return result;
};
/**
 * Given a seat item, check the side by its sideConnector if this side is on the
 * left/right hand of a layout (or the back of a 1 seat 3 sides)
 * @param {object} seatItem
 * @param {object} sideConnector
 * @returns
 */
const getArmPosition = (seatItem, sideConnector) => {
  let side;
  if (sideConnector && sideConnector.localSide) {
    const nextItem = seatItem[NEXT_SEAT_SIDES[sideConnector.localSide]];
    const prevItem = seatItem[PREVIOUS_SEAT_SIDES[sideConnector.localSide]];
    if (nextItem && !prevItem) {
      // next side connects to something while previous
      // side does not
      if (nextItem._type === 'side') {
        // it's a side
        side = 'right';
      } else {
        // it's a seat (ottoman)
        side = 'left';
      }
    } else if (prevItem && !nextItem) {
      // previous side connects to something while next
      // side does not
      if (prevItem._type === 'side') {
        // it's a side
        side = 'left';
      } else {
        // it's a seat (ottoman)
        side = 'right';
      }
    } else if (prevItem && nextItem) {
      // both sides connects to something
      if (nextItem._type !== prevItem._type) {
        if (nextItem._type === 'side') {
          // it's a side
          side = 'right';
        } else {
          // it's a seat (ottoman)
          side = 'left';
        }
      } else if (nextItem._type === 'side') {
        // back on single seat with ottoman
        side = 'back';
      }
    }
  }
  return side;
};

/**
 * Get the connection status of all seats
 * @param {*} items
 * @param {*} ignoreItems
 * @returns
 */
const getConnectedSets = (items, ignoreItems = []) => {
  const sets = [];
  const ignoreItemSet = new Set(ignoreItems);

  // split items into seats and sides
  const { seats, sides, connections } = items.reduce(
    (acc, item) => {
      const id = item._id;
      if (!ignoreItemSet.has(id)) {
        if (item._type === 'seat') acc.seats.push(item);
        if (item._type === 'side') acc.sides.push(item);
        acc.connections[id] = item
          .getConnections()
          .reduce((result, connection) => {
            const { target, alignment, targetLocalSide } = connection;
            if (!ignoreItemSet.has(target._id))
              result[connection.localSide] = {
                target,
                targetLocalSide,
              };
            return result;
          }, {});
      }
      return acc;
    },
    { seats: [], sides: [], connections: {} }
  );
  const deemedConnections = {};
  if (seats.length < 1) {
    return sets;
  }

  // find next seat
  const traverseSeats = (seatItem, set) => {
    const seatId = seatItem._id;
    if (ignoreItemSet.has(seatId)) return;
    if (!set.seats.has(seatId)) set.seats.set(seatId, seatItem);
    if (!set.state.hasStdSeat && seatItem._key === 'standard') {
      set.state.hasStdSeat = true;
    }

    const connectedSeats = [];
    const connectedSides = [];
    deemedConnections[seatId] = {};
    ALL_SEAT_SIDES.forEach((side) => {
      const connection = connections[seatId][side];
      if (connection) {
        const tempConnection = { ...connection };
        while (
          tempConnection.target._type === 'anytable' ||
          (tempConnection.target._type === 'side' &&
            tempConnection.target._key === 'doubleSided')
        ) {
          const map = set[`${tempConnection.target._type}s`];
          if (!map.has(tempConnection.target._id)) {
            map.set(tempConnection.target._id, tempConnection.target);
          }
          const nextTarget =
            tempConnection.target[
              OPPOSITE_SEAT_SIDES[tempConnection.targetLocalSide]
            ];
          if (!nextTarget || !connections[nextTarget._id]) break;
          else {
            tempConnection.targetLocalSide = ALL_SEAT_SIDES.find(
              (s) => nextTarget[s]?._id === tempConnection.target._id
            );
            tempConnection.target = nextTarget;
          }
        }
        deemedConnections[seatId][side] = tempConnection;
        switch (tempConnection.target._type) {
          case 'seat':
            connectedSeats.push({ ...tempConnection, localSide: side });
            break;
          case 'side':
            connectedSides.push({ ...tempConnection, localSide: side });
            break;
          default:
            break;
        }
      }
    });

    const seat = {
      id: seatId,
      item: seatItem,
    };

    // A pair is a situation where side of a Seat connects to a Side
    // and the opposite is a Seat, which implies this Seat could be
    // end/corner of a layout. Each pair stores the connectors of
    // the seat item that connect to the other seat/side
    const pairs = [];

    // Check surrounding seats and add to seat's side
    if (connectedSeats.length) {
      const isNotCenterSeat =
        connectedSides && connectedSeats.length - connectedSides.length < 2;
      connectedSeats.forEach((seatAttachment) => {
        const { localSide, target, targetLocalSide } = seatAttachment;

        seat[localSide] = seatAttachment;

        if (connectedSides && isNotCenterSeat) {
          const findSide = connectedSides.find(
            (attachment) =>
              attachment.localSide === OPPOSITE_SEAT_SIDES[localSide]
          );
          if (findSide) {
            pairs.push({
              seatConnector: seatAttachment,
              sideConnector: findSide,
            });
          }
        }

        if (!set.seats.has(target._id)) {
          traverseSeats(target, set);
        }
      });
    }
    // Add surrounding side's connectors to seat's side
    if (connectedSides) {
      connectedSides.forEach((sideAttachment) => {
        const { localSide, target, targetLocalSide } = sideAttachment;
        seat[localSide] = sideAttachment;
        if (!set.sides.has(target._id)) {
          set.sides.set(target._id, target);
          if (!set.state.hasRollArm && target._key === 'rollArm') {
            set.state.hasRollArm = true;
          }
        }
      });
    }

    if (pairs.length > 0) {
      seat.pairs = pairs;
    }

    set.mappedSeats[seatId] = seat;
  };

  seats.forEach((seat) => {
    const handled = sets.some((set) => set.seats.has(seat._id));
    if (handled) return;
    const set = {
      seats: new Map([]),
      sides: new Map([]),
      anytables: new Map([]),
      mappedSeats: {},
      state: {},
      paths: [],
      errors: [], // list of error codes why this set is not eligible for stealthTech
    };
    traverseSeats(seat, set);
    sets.push(set);
  });

  sets.sort((a, b) => {
    if (b.seats.size === a.seats.size) {
      return b.sides.size - a.sides.size;
    } else {
      return b.seats.size - a.seats.size;
    }
  });

  console.log('sets', sets);

  return sets;
};

const updatePillowsForSet = (set) => {
  const { sides, seats, mappedSeats, paths, stealthTech } = set;
  Array.from(sides.values()).forEach((side) => {
    side.style = 'back';
    if (/angled/.test(side._key)) {
      side._configurator.setConfiguration({
        PillowPosition: 'none',
      });
      side.pillows = [];
    }
  });
  const path = stealthTech ? stealthTech.bundle?.path : paths[0];

  if (path) {
    if (path.length === 1) {
      const item = path[0];
      const seat = item.item;
      const side = item.cap.target;
      const nextItem = seat[NEXT_SEAT_SIDES[item.cap.localSide]];
      const prevItem = seat[PREVIOUS_SEAT_SIDES[item.cap.localSide]];
      if (item.cap.armSide === 'back') {
        side.style = 'back';
        if (nextItem) {
          nextItem.style = 'arm';
          if (/angled/.test(nextItem._key)) {
            nextItem._configurator.setConfiguration({
              PillowPosition: 'right',
            });
          }
        }
        if (prevItem) {
          prevItem.style = 'arm';
          if (/angled/.test(prevItem._key)) {
            prevItem._configurator.setConfiguration({
              PillowPosition: 'left',
            });
          }
        }
      }
    } else {
      const firstItem = path[0];
      const lastItem = path[path.length - 1];
      const { layout } = lastItem;

      [firstItem, lastItem].forEach((item) => {
        const seat = item.item;
        const side = item.cap.target;
        side.style = 'arm';
        if (/angled/.test(side._key)) {
          side._configurator.setConfiguration({
            PillowPosition: OPPOSITE_SEAT_SIDES[item.cap.armSide],
          });
        }

        seat._updatePillow({ noDeepPillow: layout === 'M' });
      });

      const cornerItems = path.filter((node) => node.type === 'corner');
      // M shape check corner piece's cap
      if (layout === 'M') {
        if (cornerItems.length !== 3) {
          console.warn('M layout should have 3 corner pieces');
        } else {
          const firstCorner = cornerItems[0];
          const firstCornerItem = firstCorner.item;
          const firstItemLookupSide = NEXT_SEAT_SIDES[firstCorner.oppositeSide];
          if (firstCornerItem[firstItemLookupSide]?._type === 'side') {
            const side = firstCornerItem[firstItemLookupSide];
            // side.style = 'arm';
            if (/angled/.test(side._key)) {
              const armSide = getArmPosition(
                firstCornerItem,
                mappedSeats[firstCornerItem._id][firstItemLookupSide]
              );
              side._configurator.setConfiguration({
                PillowPosition: OPPOSITE_SEAT_SIDES[armSide],
              });
              if (OPPOSITE_SEAT_SIDES[armSide])
                side.pillows.push({ type: 'sidePillow', key: 'angled' });
            }
            firstCornerItem._updatePillow({ noDeepPillow: true });
          }
          const lastCorner = cornerItems[cornerItems.length - 1];
          const lastCornerItem = lastCorner.item;
          const lastItemLookupSide = lastCorner.oppositeSide;
          if (lastCornerItem[lastItemLookupSide]?._type === 'side') {
            const side = lastCornerItem[lastItemLookupSide];
            // side.style = 'arm';
            if (/angled/.test(side._key)) {
              const armSide = getArmPosition(
                lastCornerItem,
                mappedSeats[lastCornerItem._id][lastItemLookupSide]
              );
              side._configurator.setConfiguration({
                PillowPosition: OPPOSITE_SEAT_SIDES[armSide],
              });
              if (OPPOSITE_SEAT_SIDES[armSide])
                side.pillows.push({ type: 'sidePillow', key: 'angled' });
            }
            lastCornerItem._updatePillow({ noDeepPillow: true });
          }
        }
      } else {
        cornerItems.forEach((item) =>
          item.item._updatePillow({ isCorner: true })
        );
      }
    }
  } else {
    // Only set arm type when there is a path(valid layout)
  }
};

const COMMON_LAYOUT_TYPES = ['I', 'L', 'U', 'M'];

const LAYOUT_FUNCS = [
  // M, U, L, I
  (set) => {
    const { seats, sides: allSides, mappedSeats, state, errors, paths } = set;

    // Get seats that are end/corner(s)
    const endSeats = Object.values(mappedSeats).filter((s) => !!s.pairs);

    /**
     * Check seat and see if it connects to another seat
     * @param {*} headNode node in path as the head
     * @param {*} connector the connector the tail starts from
     * @param {*} numOfCorners
     */
    function getTailSeats(headNode, connector, numOfCorners = 0) {
      const { type } = headNode;
      const { target: item, targetLocalSide: seatSide } = connector;
      const { _id: seatId } = item;
      // Item could be a doubleSided side which has a seat on the other side,
      // this case it can be treated connected
      const paths = [];
      if (mappedSeats[seatId]) {
        // look down the line
        const oppositeSide = OPPOSITE_SEAT_SIDES[seatSide];
        const oppositeConnector = mappedSeats[seatId][oppositeSide];

        if (oppositeConnector) {
          if (oppositeConnector.target._type === 'anytable') {
            let nextSide =
              OPPOSITE_SEAT_SIDES[oppositeConnector.targetLocalSide];
            let nextItem = oppositeConnector.target[nextSide];
            let curItem = oppositeConnector.target;
            while (nextItem && nextItem._type !== 'seat') {
              const side = ALL_SEAT_SIDES.find(
                (s) => nextItem[s]?._id === curItem._id
              );
              nextSide = OPPOSITE_SEAT_SIDES[side];
              curItem = nextItem;
              nextItem = curItem[nextSide];
            }
          } else if (
            mappedSeats[seatId].pairs &&
            oppositeConnector.target._type === 'side'
          ) {
            if (
              oppositeConnector.target._key === 'doubleSided' &&
              oppositeConnector.target[
                OPPOSITE_SEAT_SIDES[oppositeConnector.targetLocalSide]
              ]?._type === 'seat'
            ) {
              const nextSeat =
                oppositeConnector.target[
                  OPPOSITE_SEAT_SIDES[oppositeConnector.targetLocalSide]
                ];
              const tails = getTailSeats(
                headNode,
                {
                  localSide:
                    OPPOSITE_SEAT_SIDES[oppositeConnector.targetLocalSide],
                  target: nextSeat,
                  targetLocalSide: ALL_SEAT_SIDES.find(
                    (s) => nextSeat[s]?._id === oppositeConnector.target._id
                  ),
                },
                numOfCorners
              );
              const node = {
                type: 'middle',
                id: seatId,
                item: mappedSeats[seatId].item,
              };
              if (tails.length) {
                tails.forEach((tail) => {
                  paths.push([node].concat(tail));
                });
              }
            } else {
              // This seat itself is an end/corner
              paths.push([
                {
                  type: 'end',
                  id: seatId,
                  item: mappedSeats[seatId].item,
                  cap: oppositeConnector,
                  layout: COMMON_LAYOUT_TYPES[numOfCorners],
                },
              ]);
            }
          }
          // The item connected is another seat
          else if (mappedSeats[oppositeConnector.target._id]) {
            if (item._key === 'wedge') {
              const previousConnector = mappedSeats[seatId][seatSide];
              const previousItem = previousConnector.target;

              let nodeType;
              if (previousItem._key === item._key) {
                if (
                  previousConnector.localSide === OPPOSITE_SEAT_SIDES[seatSide]
                ) {
                  if (type === 'corner') {
                    // Two/more wedge seats treat as one corner
                    nodeType = 'corner-ext';
                  } else nodeType = 'corner';
                } else {
                  nodeType = 'middle';
                }
              } else {
                nodeType = 'corner';
              }

              const isCorner = nodeType === 'corner';

              const node = {
                type: nodeType,
                id: seatId,
                item: mappedSeats[seatId].item,
              };

              const tails = getTailSeats(
                node,
                oppositeConnector,
                numOfCorners + (isCorner ? 1 : 0)
              );
              if (tails.length) {
                tails.forEach((tail) => {
                  paths.push([node].concat(tail));
                });
              }
            } else {
              const node = {
                type: 'middle',
                id: seatId,
                item,
              };
              const tails = getTailSeats(node, oppositeConnector, numOfCorners);
              if (tails.length) {
                tails.forEach((tail) => {
                  paths.push([node].concat(tail));
                });
              }
            }
          }
        }

        // 1 step counter clockwisely
        const previousSide = PREVIOUS_SEAT_SIDES[oppositeSide];
        const previousConnector = mappedSeats[seatId][previousSide];
        if (previousConnector && mappedSeats[previousConnector.target._id]) {
          const node = {
            type: 'corner',
            id: seatId,
            clockwise: false,
            item,
            oppositeSide,
          };
          const tails = getTailSeats(node, previousConnector, numOfCorners + 1);
          if (tails.length) {
            tails.forEach((tail) => {
              paths.push([node].concat(tail));
            });
          }
        }

        // 1 step clockwisely
        const nextSide = NEXT_SEAT_SIDES[oppositeSide];
        const nextConnector = mappedSeats[seatId][nextSide];
        if (nextConnector && mappedSeats[nextConnector.target._id]) {
          const node = {
            type: 'corner',
            id: seatId,
            clockwise: true,
            item,
            oppositeSide,
          };
          const tails = getTailSeats(node, nextConnector, numOfCorners + 1);
          if (tails.length) {
            tails.forEach((tail) => {
              paths.push([node].concat(tail));
            });
          }
        }
      } else {
        // this may be an anytable
        let nextSide = OPPOSITE_SEAT_SIDES[seatSide];
        let nextItem = connector.target[nextSide];
        let curItem = connector.target;
        while (nextItem && nextItem._type !== 'seat') {
          const side = ALL_SEAT_SIDES.find(
            (s) => nextItem[s]?._id === curItem._id
          );
          nextSide = OPPOSITE_SEAT_SIDES[side];
          curItem = nextItem;
          nextItem = curItem[nextSide];
        }
        if (nextItem?._type === 'seat') {
          const c = {
            ...connector,
            target: nextItem,
            targetLocalSide: ALL_SEAT_SIDES.find(
              (s) => nextItem[s]?._id === curItem._id
            ),
          };
          const tails = getTailSeats(headNode, c, numOfCorners);
          if (tails.length) {
            tails.forEach((tail) => {
              paths.push(tail);
            });
          }
        }
      }

      return paths;
    }

    // clockwisely to get possible layouts
    endSeats.forEach((endSeat) => {
      const { id, pairs } = endSeat;
      pairs.forEach((pair) => {
        const { seatConnector, sideConnector } = pair;
        const { item } = mappedSeats[id];

        const armSide = getArmPosition(item, sideConnector);
        const node = {
          type: 'end',
          id,
          item,
          cap: { ...mappedSeats[id][sideConnector.localSide], armSide },
        };

        const tails = getTailSeats(node, seatConnector, 0);
        if (tails.length) {
          tails.forEach((tail) => {
            const path = [{ ...node }].concat(tail);
            const checkPathNodeIds = path.map((p) => p.id).reverse();
            // check existing paths and see if current path
            // is a reversed one already exists
            if (
              !paths.some((p) => {
                if (p.length === path.length) {
                  const ids = p.map((e) => e.id);
                  for (let i = 0; i < p.length; i++) {
                    if (ids[i] !== checkPathNodeIds[i]) return false;
                  }
                  return true;
                }
                return false;
              })
            ) {
              // This is a unique path
              paths.push(path);
            }
          });
        } else if (endSeats.length === 1) {
          paths.push([node]);
        }
      });
    });

    // Also generate a path when there is only one seat
    if (seats.size === 1) {
      const seat = seats[0];
      let sideIndex = ALL_SEAT_SIDES.findIndex((side) => seat[side]);
      if (sideIndex !== -1) {
        const node = {
          id: seat._id,
          item: seat,
          type: 'end',
          cap: { ...mappedSeats[seat._id][ALL_SEAT_SIDES[sideIndex]] },
        };
        for (; sideIndex < ALL_SEAT_SIDES.length; sideIndex++) {
          const side = ALL_SEAT_SIDES[sideIndex];
          const nextSide = NEXT_SEAT_SIDES[side];
          const prevSide = PREVIOUS_SEAT_SIDES[side];
          if (seat[prevSide] && seat[side] && seat[nextSide]) {
            node.cap = mappedSeats[seat._id][side];
            break;
          }
        }
        paths.push([node]);
      }
    }

    // Sort the paths by the length of nodes
    const sortedPaths = paths.sort((a, b) => b.length - a.length);
    paths.forEach((path) => {
      // sort nodes so the rightmost seat that has an arm stays the first element
      // in the array
      const { layout } = path[path.length - 1];
      const corners = path.filter((n) => n.type === 'corner');
      const firstCorner = corners[0];
      if (
        (firstCorner && !firstCorner.clockwise) ||
        (!firstCorner && path[0].cap.armSide !== 'right')
      ) {
        corners.forEach((node) => {
          node.clockwise = !node.clockwise;
        });
        path[0].layout = layout;
        path.reverse();
        delete path[0].layout;
      }
    });

    const setPathArms = (path) => {
      if (!path) return;
      const firstItem = path[0];
      const lastItem = path[path.length - 1];
      if (!firstItem.cap.armSide && !lastItem.cap.armSide) {
        firstItem.cap.armSide = 'right';
        lastItem.cap.armSide = 'left';
      } else if (firstItem.cap.armSide && !lastItem.cap.armSide) {
        lastItem.cap.armSide = OPPOSITE_SEAT_SIDES[firstItem.cap.armSide];
      } else if (!firstItem.cap.armSide && lastItem.cap.armSide) {
        firstItem.cap.armSide = OPPOSITE_SEAT_SIDES[lastItem.cap.armSide];
      }
    };

    // Island has at least a valid layout, use it to determine arm side
    const bundle = getBundleFromSet(set);
    if (bundle) {
      bundle.speakers.forEach((item) => {
        item.type = allSides.get(item.id)._key;
      });
      bundle.satellites.forEach((item) => {
        item.type = allSides.get(item.id)._key;
      });
      set.stealthTech = {
        bundle,
      };
      setPathArms(bundle.path);
    } else setPathArms(sortedPaths[0]);

    set.paths = sortedPaths;
    return set;
  },
  // I: 1 main seat,  x ottoman
  (set) => {
    const { seats, mappedSeats, state, paths } = set;
    const errors = [];

    // Get seats that only connects 1 seat
    const endSeats = Object.values(mappedSeats).filter((s) => !!s.pairs);

    if (seats.size === 1) {
      const seatItem = Array.from(seats.values())[0];
      const { sides: connectedSides } = sortSeatsAttachments(seatItem);

      const isWedge = seatItem._key === 'wedge';

      const seat = {};
      const oppositeSides = [];
      if (connectedSides && !isWedge) {
        connectedSides.forEach((seatAttachment) => {
          const { localSide } = seatAttachment;
          if (seat[localSide]) {
            return; // side handled
          }
          seat[localSide] = seatAttachment;
          const otherSide = OPPOSITE_SEAT_SIDES[localSide];
          const findSide = connectedSides.find(
            (attachment) => attachment.localSide === otherSide
          );
          if (findSide) {
            oppositeSides.push({
              [otherSide]: findSide,
              [localSide]: seatAttachment,
            });
            seat[otherSide] = findSide;
          }
        });
      }
      if (oppositeSides.length === 1) {
        const speakers = Object.entries(oppositeSides[0]).map(
          ([side, connector]) => {
            delete seat[side];
            return { id: connector.target._id, type: connector.target._key };
          }
        );

        const back = ALL_SEAT_SIDES.find((side) => seat[side]);
        if (back) {
          paths.push([
            {
              id: seatItem._id,
              item: seatItem,
              type: 'end',
              cap: { ...seat[back], armSide: 'back' },
            },
          ]);
        }
        if (
          speakers[0].type === speakers[1].type &&
          speakers[0].type !== 'rollArm'
        ) {
          set.stealthTech = {
            bundle: {
              layout: 'I',
              subwoofer: seatItem._id,
              speakers,
              satellites: [],
              path: paths[0],
            },
          };
        } else if (state.hasRollArm) {
          errors.push(ERROR_CODES.ARMS_DONT_MATCH);
        }
      } else {
        if (state.hasRollArm) errors.push(ERROR_CODES.NO_END_STANDARD_SIDES);
        if (isWedge) errors.push(ERROR_CODES.WEDGE_SEAT_INELIGIBLE);
        if (errors.length)
          return {
            errors,
          };
        else return;
      }
    } else if (endSeats.length === 1) {
      const item = endSeats[0];
      const { id, pairs } = item;
      if (pairs.length === 1) {
        const backSide = ALL_SEAT_SIDES.find(
          (s) => s === pairs[0].sideConnector.localSide
        );

        const leftSide = NEXT_SEAT_SIDES[backSide];
        const rightSide = PREVIOUS_SEAT_SIDES[backSide];

        if (item[leftSide] && item[rightSide]) {
          const speakers = [leftSide, rightSide]
            .map((side) => {
              const { target } = item[side];
              if (target._type === 'side' && target._key !== 'rollArm')
                return { id: target._id, type: target._key };
            })
            .filter((e) => !!e);
          if (speakers.length === 2)
            set.stealthTech = {
              bundle: {
                layout: 'I',
                subwoofer: id,
                speakers,
                satellites: [],
                path: [
                  {
                    cap: { ...pairs[0].sideConnector, armside: 'back' },
                    id: item._id,
                    item,
                    type: 'end',
                  },
                ],
              },
            };
        }
      }
    }
    if (!set.stealthTech && state.hasRollArm) {
      errors.push(ERROR_CODES.NO_END_STANDARD_SIDES);
    }
    set.errors = errors;
    return set;
  },
];

const evaluateSets = (sets) => {
  const ottomans = {};
  sets.map((set) => {
    const endSeats = Object.values(set.mappedSeats).filter((s) => !!s.pairs);
    const result =
      endSeats.length < 2 ? LAYOUT_FUNCS[1](set) : LAYOUT_FUNCS[0](set);
    console.log('Evaluated', result);
    const { mappedSeats } = set;
    Object.entries(mappedSeats).forEach(([id, obj]) => {
      if (
        !ALL_SEAT_SIDES.some(
          (side) => obj[side] && obj[side].target._type === 'side'
        )
      ) {
        ottomans[id] = obj.item;
      }
    });
  });

  status.ottomans = ottomans;
};

export {
  getConnectedSets,
  updatePillowsForSet,
  sortSeatsAttachments,
  evaluateSets,
};
