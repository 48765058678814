import { SEAT_HEIGHT } from '../constants';

import Side from './Side';

export default class DeepSide extends Side {
  constructor(threekitApi, options = {}) {
    super(threekitApi, options);
    this.width = SEAT_HEIGHT;
    this._key = 'deep';

    this._initAlignmentWithConnector(options.connector);
  }
}
