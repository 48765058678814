import Item from './Item';
import { SIDE_WIDTH, SIDE_HEIGHT } from '../constants';

export default class Side extends Item {
  constructor(threekitApi, options = {}) {
    super(threekitApi, options);
    this.width = SIDE_WIDTH;
    this.height = SIDE_HEIGHT;
    this._type = 'side';
    this._key = 'standard';
    this.style = null;

    this._initAlignmentWithConnector(options.connector);
  }

  _initAlignmentWithConnector = (connector) => {
    this.bottom = null;
    this.bottomAlign = null;
    this.island = null;

    if (connector) {
      this._connectTo(connector);
    }
  };

  _connectTo = (connector) => {
    const { target } = connector;
    if (
      target._type !== 'seat' &&
      target._type !== 'wedgeSeat' &&
      target._type !== 'anytable'
    )
      throw new Error('Side can only connect to Seat or WedgeSeat piece');

    this._connectHelper({ ...connector, localSide: 'bottom' });
    target._updatePillow();
  };
}
