import { sacItems } from './sac';
import { squattoman } from './squattoman';
import { throwPillow } from './throwPillows';
import { sactionalItems } from './sactional';
import {
  getRootId,
  traverseHierarchy,
  isShadowPlane,
  getWorldPosition,
} from '../../helpers';
import {
  sortSeatsAttachments,
  OPPOSITE_SEAT_SIDES,
} from '../placement/attachments';
import {
  registerConnectorsForItem,
  itemWillBeRemoved,
  initItemPlacement,
  getConnectorData,
  getConnectorsForItem,
} from '../placement';
import { deselectItems, clearSelection, selectItem } from '../selection';
import { updateCapacity } from '../capacity';
import { frameScene } from '../camera';
import { addColliders, removeColliders } from '../collision';
import { baFabricToSceneConfig, setSceneConfiguration } from '../room';
import { Island } from '../stealthTech/Island';

const state = new Map(); // itemId --> itemData

const itemTemplates = {
  sac: sacItems,
  ...sactionalItems,
  squattoman,
  throwPillow,
};

export async function internalAddItem(item) {
  if (!item) throw new Error('No item provided');

  const { type, key, configuration, position, id } = item;
  const itemType = itemTemplates[type];
  if (!itemType) throw new Error(`Unknown sactional item of type ${type}`);
  const itemData = itemType[key];
  if (!itemData)
    throw new Error(`Unsupported item key '${key}' for type ${type}`);

  // const { assetId } = itemData;
  // if (!assetId) throw new Error('No asset id found for this item');

  const parentId = await getRootId();

  const plugs = {
    Null: [
      {
        type: 'Model',
        asset: {
          query: { metadata: { itemType: type, itemKey: key } },
        },
      },
    ],
  };

  // If predefined position, set it on the initial node data. Otherwise, the
  // item will be auto positioned. Autopositioning depends on model parts
  // (particularly the connectors) be loaded. This means the node gets
  // initialized at the origin momentarily before getting autopositioned. To
  // avoid this visual jump, we keep the item hidden until after it is
  // autopositioned.

  if (position) {
    plugs.Transform = [{ type: 'Transform', ...position }];
  } else plugs.Properties = [{ type: 'ModelProperties', visible: false }];

  const nodeId = window.threekit.api.scene.addNode(
    {
      id,
      type: 'Model',
      name: `${type}_${key}`,
      plugs,
    },
    parentId
  );

  const itemId = nodeId; // using a new variable here for clarity of intent

  // Really item should only be added to state once it is fully set up, so there
  // will never be a partial/invalid set of state for the item. Otherwise
  // anything accessing the item during this setup can cause misbehave, such as
  // selection, but state needs to be set before calling the applyConfigurationFn.
  state.set(itemId, { id: itemId, type, key, configuration });

  if (configuration) {
    // const applyConfigurationFn = itemType[key].applyConfiguration;

    if (configuration && configuration.fabric) {
      const sceneFabric = baFabricToSceneConfig(configuration.fabric);
      await setSceneConfiguration(sceneFabric);
    }

    // if (applyConfigurationFn) await applyConfigurationFn(state.get(itemId));
  }

  // TODO: Handle toggling of shadow plane for newly added sacs or other items
  // This is part of a general issue where any newly added items should inherit
  // any desired global previous we have applied to items.

  await window.threekit.api.player.evaluateSceneGraph();

  // ModelProperties in plugs have to be true so evaluateSceneGraph() can get
  // the instance, but we don't want it to show before it gets to the right
  // position
  // window.threekit.api.scene.set(
  //   { id: itemId, plug: 'Properties', property: 'visible' },
  //   false
  // );

  await registerConnectorsForItem(itemId);
  // Need to add colliders before autoplacement as colliders are used to prevent
  // attachements that would result in collisions
  addColliders([itemId]);

  // Now that we have connectors loaded, we can autoplace the item, and then
  // make it visible since it is now in the right location
  if (!position) {
    await initItemPlacement(itemId);
    // window.api.player.evaluateSceneGraph();
    // window.api.player.cameraController.lookAtBoundingSphere([itemId]);
  }

  window.threekit.api.scene.set(
    { id: itemId, plug: 'Properties', property: 'visible' },
    true
  );

  return itemId;
}

export async function addItem(item) {
  const itemId = await internalAddItem(item);
  await clearSelection();
  await selectItem(itemId);
  // frameScene(Array.from(state.keys())); // with autoplacement of new items in view, let's try without framing
  await updateCapacity([item]);
  return itemId;
}

export async function configureItems(items = [], config) {
  // Previous approach of applying per-item configurations
  // return Promise.all(
  //   items.map(async (itemId) => {
  //     const { type, key } = state.get(itemId);
  //     const applyConfigurationFn = itemTemplates[type][key].applyConfiguration;
  //     if (applyConfigurationFn) {
  //       updateItemState(itemId, config);
  //       return applyConfigurationFn(state.get(itemId));
  //     }
  //   })
  // );

  // Global Scene configuration approach

  items.forEach(async (itemId) => {
    updateItemState(itemId, config);
  });

  if (config && config.fabric) {
    const sceneFabric = baFabricToSceneConfig(config.fabric);
    await setSceneConfiguration(sceneFabric);
  }
}

// Does not need to be async, but for consistency we are using Promise-based return values
// for our api
export async function removeItems(itemIds = []) {
  removeColliders(itemIds);
  await deselectItems(itemIds); // remove from selection state
  const items = await Promise.all(
    itemIds.map(async (itemId) => {
      const item = state.get(itemId);

      // Clear out attachment-related state.
      // Note: if we switched to using redux, this would be handled automatically
      // via the attachment's reducer.
      await itemWillBeRemoved(itemId);
      return item;
    })
  );

  items.map(async (item) => {
    const { id } = item;
    state.delete(id); // remove from items state

    window.threekit.api.scene.deleteNode(id); // remove from scenegraph

    return item;
  });

  await updateCapacity(items, false);
}

export function getItems() {
  return new Map(state); // yes, this is only a shallow copy, but this should be fine
}

export function getItemIds() {
  return Array.from(getItems().keys());
}

export function getItem(itemId) {
  return state.get(itemId);
}

const updateItemState = (itemId, config) => {
  const item = state.get(itemId);
  if (!item.configuration) item.configuration = config;
  else {
    Object.entries(config).forEach(([key, value]) => {
      if (typeof value === 'object')
        item.configuration[key] = { ...item.configuration[key], ...value };
      else item.configuration[key] = value;
    });
  }
};

// Get the ids of all non-shadow plane PolyMeshes for the given set of items
export const getMeshesForItems = async (itemIds, options = {}) => {
  const { excludeBackPillows, excludeAccessories } = options;

  const filteredNodeIds = [];

  const filter = ({ id, name, type }) => {
    // Back Pillows are not included in the height calculation
    if (excludeBackPillows && name === 'Back Pillow') return false;
    if (type === 'PolyMesh' && !isShadowPlane(name)) filteredNodeIds.push(id);
    return true;
  };

  await Promise.all(
    itemIds
      .filter((id) => {
        const { type } = getItem(id);
        if (excludeAccessories && isAccessory(id)) return false;
        if (
          type === 'sac' ||
          type === 'squattoman' ||
          (excludeBackPillows && type === 'seat')
        )
          return true;
        filteredNodeIds.push(id);
        return false;
      })
      .map((id) => traverseHierarchy(id, filter, true))
  );

  return filteredNodeIds;
};

export const isAccessory = (itemId) =>
  ['coaster', 'drinkHolder', 'rollArmDrinkHolder', 'table'].includes(
    getItem(itemId).type
  );

// Sactional accessories that are strewn about the floor or in a separate
// holding area should not be included in the calculation of the framing of the
// scene for the thumbnail
export const getNonAccessories = async (itemIds) => {
  return itemIds.filter((id) => !isAccessory(id));
};

export const getSeatingCapacity = (items) =>
  items.reduce((acc, { type, key }) => {
    const { seatingCapacity } = itemTemplates[type][key];
    if (seatingCapacity) acc += seatingCapacity;
    return acc;
  }, 0);

export const getConnectedSets = (itemIds, ignoreItems = []) => {
  const islands = [];
  const ignoreItemSet = new Set(ignoreItems);

  const { seats, sides } = itemIds.reduce(
    (acc, id) => {
      if (!ignoreItemSet.has(id)) {
        const item = getItem(id);
        if (item.type === 'seat') acc.seats.push(item);
        if (item.type === 'side') acc.sides.push(item);
      }
      return acc;
    },
    { seats: [], sides: [] }
  );
  if (seats.length < 1) {
    return islands;
  }

  const traverseSeats = (seatId, island) => {
    if (ignoreItemSet.has(seatId)) return;
    if (!island.hasSeat(seatId)) island.addSeat(seatId);
    const {
      seats: connectedSeats,
      sides: connectedSides,
    } = sortSeatsAttachments(seatId);
    const worldTransform = getWorldPosition(seatId);
    const seat = { id: seatId, worldTransform };
    // A pair is a situation where side of a seat connects to a side
    // and the opposite is a seat, which implies this seat could be
    // end/corner of a layout, each entry stores the connectors of
    // the seat item that connect to the other seat/side
    const pairs = [];

    const filteredSeats = connectedSeats
      ? connectedSeats.filter(
          (attachment) => !ignoreItemSet.has(attachment.otherConnector.owner)
        )
      : [];
    const filteredSides = connectedSides
      ? connectedSides.filter(
          (attachment) => !ignoreItemSet.has(attachment.otherConnector.owner)
        )
      : [];
    // Check surrounding seats and add to seat's side
    if (filteredSeats.length) {
      if (filteredSeats.length === 4) seat.surroundBySeats = true;
      const isNotCenterSeat =
        filteredSides && filteredSeats.length - filteredSides.length < 2;
      filteredSeats.forEach((seatAttachment) => {
        const { seatSide } = seatAttachment.thisConnector;

        seat[seatSide] = seatAttachment.otherConnector;

        if (filteredSides && isNotCenterSeat) {
          const otherSide = OPPOSITE_SEAT_SIDES[seatSide];
          const findSide = filteredSides.find(
            (attachment) => attachment.thisConnector.seatSide === otherSide
          );
          if (
            findSide &&
            getItem(findSide.otherConnector.owner).key === 'standard'
          ) {
            pairs.push({
              seat: seatAttachment.thisConnector,
              side: findSide.thisConnector,
            });
          }
        }
        if (!island.hasSeat(seatAttachment.otherConnector.owner)) {
          traverseSeats(seatAttachment.otherConnector.owner, island);
        }
      });
    }
    // Add surrounding side's connectors to seat's side
    if (filteredSides) {
      filteredSides.forEach((sideAttachment) => {
        seat[sideAttachment.thisConnector.seatSide] =
          sideAttachment.otherConnector;
        if (!island.hasSide(sideAttachment.otherConnector.owner)) {
          island.addSide(sideAttachment.otherConnector.owner);
        }
      });
    }
    if (pairs.length > 0) {
      seat.pairs = pairs;
    }

    island.addSeatMap(seatId, seat);
  };

  seats.forEach((seat) => {
    const handled = islands.some((island) => island.hasSeat(seat.id));
    if (handled) return;
    const island = new Island();
    traverseSeats(seat.id, island);
    islands.push(island);
  });

  islands.sort((a, b) => {
    if (b.seats.size === a.seats.size) {
      return b.sides.size - a.sides.size;
    } else {
      return b.seats.size - a.seats.size;
    }
  });

  return islands;
};
