import {
  SIDE_KEYWORD,
  SIDE_MAP,
  FLOAT_POINT_ADJUST,
  DRAG_UNIT_DISTANCE,
} from './constants';

export const getAlignKeyword = (side) => `${side}Align`;

export const getLocalKeyword = (
  worldKeyword,
  rotationIdx,
  keywords,
  errorCallback
) => {
  const worldIdx = keywords.indexOf(worldKeyword);
  if (worldIdx === -1)
    throw new Error(
      errorCallback
        ? errorCallback(worldKeyword)
        : `Unknown keyword ${worldKeyword}`
    );

  const localIdx = (worldIdx + keywords.length - rotationIdx) % keywords.length;

  return keywords[localIdx];
};

export const getWorldKeyword = (
  localKeyword,
  rotationIdx,
  keywords,
  errorCallback
) => {
  const localIdx = keywords.indexOf(localKeyword);

  if (localIdx === -1)
    throw new Error(
      errorCallback
        ? errorCallback(localKeyword)
        : `Unknown keyword ${localKeyword}`
    );

  const worldIdx = (localIdx + rotationIdx) % keywords.length;
  return keywords[worldIdx];
};

export const swapSideKey = (key) => {
  const isDeepSide = key.includes('deep');
  const isOutdoorSide = key.includes('outdoor');
  const isAngled = /angled/i.test(key);

  let newKey = isDeepSide
    ? isAngled
      ? 'angled'
      : 'standard'
    : isAngled
    ? 'deepAngled'
    : 'deep';
  if (isOutdoorSide) newKey += '_outdoor';
  return newKey;
};

const sortPoint = (p1, p2) => p1[0] - p2[0] || p1[1] - p2[1];
const sortSegment = (seg1, seg2) =>
  seg1[0][0] - seg2[0][0] || seg1[0][1] - seg2[0][1];

/**
 * Determind if two segments are intersect, overlay or not intersect
 * intersect means the two segments has one and only one common point that is NOT the start/end point of the segment
 * overlay means the two segments has more than one common point, this means the two segment belongs to the same line with overlay
 * no intersection means either there is no common point that is between the start/end point (exclusive) of two segment
 * segment = [[x1,y1], [x2, y2]]
 * @param {*} segment1
 * @param {*} segment2
 * @returns true if intersect, null means overlay and false means not intersect
 *
 * https://stackoverflow.com/questions/9043805/test-if-two-lines-intersect-javascript-function
 */
export const segmentIntersect = (segment1, segment2) => {
  // sort the input so that the start point is always at bottom left side of the second point
  // and the first point of first segment is always at bottom left side of the first point of second segment
  const [[[x1, y1], [x2, y2]], [[x3, y3], [x4, y4]]] = [segment1, segment2]
    .map((segment) => segment.sort(sortPoint))
    .sort(sortSegment);

  let gamma;
  let lambda;
  const threshold = 0.0001;
  const det = (x2 - x1) * (y4 - y3) - (x4 - x3) * (y2 - y1);
  if (det === 0) {
    // for our user case, additional check need when the two segment are parallel
    // It will consider intersect if the two segments has overlap more than a single point
    // for example, [0,0] -> [1,0] and [1,0] -> [2,0] are consider not intersct
    // [0,0] -> [1,0] and [0.9,0] -> [2,0] are consider overlap

    // first check if two segment belongs to same line by check if cross product between vector <p1, p2> and <p1,p3> is zero
    const crossProd = Math.abs((x2 - x1) * (y3 - y1) - (x3 - x1) * (y2 - y1));

    if (crossProd > threshold) return false;

    // if the two segments belongs to the same line, then return null if two segment are overlay (finity length overlay),
    return x2 - x3 > threshold || (x2 === x3 && y2 - y3 > threshold)
      ? null
      : false;
  } else {
    lambda = ((y4 - y3) * (x4 - x1) + (x3 - x4) * (y4 - y1)) / det;
    gamma = ((y1 - y2) * (x4 - x1) + (x2 - x1) * (y4 - y1)) / det;
    return lambda > 0 && lambda < 1 && gamma > 0 && gamma < 1;
  }
};

/**
 * Determind of twp polygon intersect
 * polygonVectices = [vertex1, vertex2, ..., vertexn]
 * vertex = [x,y]
 *
 * The reason we can not simply use boxingbox of the polygon is the polygon in this case is either not rectangular or not axis aligned, and the logic require precise calculation
 * The logic is to check every edge of the first polygon intersect with any edge of second polygon, if any intersection found, means two polygon are intersect
 * when no edges are intersected, then if more than 2 edges are overlay, also means it is intersect
 * for example, [[0,0], [0,1], [2,1], [2,0]] and [[1,0], [1,1], [3,1], [3,0]], this example does not have any two edges intersect, but it does has two pair of edges overlay
 * @param {*} polygon1Vectices
 * @param {*} polygon2Vectices
 *
 * @return boolean
 */

export const polygonIntersect = (polygon1Vectices, polygon2Vectices) => {
  let overlayCount = 0;
  for (let i = 0; i < polygon1Vectices.length; ++i) {
    const segment1 = [
      polygon1Vectices[i],
      polygon1Vectices[(i + 1) % polygon1Vectices.length],
    ];

    for (let j = 0; j < polygon2Vectices.length; ++j) {
      const segment2 = [
        polygon2Vectices[j],
        polygon2Vectices[(j + 1) % polygon2Vectices.length],
      ];
      const status = segmentIntersect(segment1, segment2);
      if (status) return true;

      if (status === null) overlayCount += 1;
      if (overlayCount === 2) return true;
    }
  }
  return false;
};

// used to get the value property key from object or class and ignore function
export const getValueProperties = (input) => {
  const validFunc = (key, val) => {
    if (typeof val === 'function') return false;
    switch (key) {
      case '_threekitApi':
      case '_id':
        return false;
      default:
        return true;
    }
  };
  return Object.keys(input).filter((key) => validFunc(key, input[key]));
};

export const applyRotation = (translation, rotation) => {
  const { x, y, z } = translation;
  const radius = (rotation / 180) * Math.PI;
  return {
    x: x * Math.cos(radius) - z * Math.sin(radius),
    y,
    z: x * Math.sin(radius) + z * Math.cos(radius),
  };
};

export const isSameTranslation = (trans1, trans2) =>
  Object.keys(trans1).every(
    (key) => Math.abs(trans1[key] - trans2[key]) < FLOAT_POINT_ADJUST
  );

export const flatNestArray = (arr) =>
  arr.reduce((res, item) => {
    if (Array.isArray(item)) return res.concat(flatNestArray(item));
    res.push(item);
    return res;
  }, []);

export const depthFirstTraverse = (root, visited, callback) => {
  if (!visited) visited = new Set();
  if (visited.has(root)) return;

  visited.add(root);
  if (typeof callback === 'function') callback(root);
  SIDE_KEYWORD.forEach((side) => {
    if (root[side]) depthFirstTraverse(root[side], visited, callback);
  });

  return visited;
};

export const getItemOutlineSegments = (items) => {
  const segments = [];
  const remainItems = new Set([...items]);

  const dfsRetrive = (item) => {
    if (!remainItems.has(item)) return;
    remainItems.delete(item);

    SIDE_KEYWORD.forEach((side) => {
      if (item[side]) {
        dfsRetrive(item[side]);
      } else {
        segments.push(item._getSideVerticesTranslation(side));
      }
    });
  };

  while (remainItems.size) {
    const iter = remainItems.values();
    const item = iter.next().value;

    dfsRetrive(item);
  }

  return mergeSegments(segments);
};

// this will merge the segments if they are connected and belongs to the same line
// for example, given two segments [(0,0), (0,1)], [(0,1), (0,2)], it will merge it into [(0,0), (0,2)]
// first sort the segment so that the first point is always less tha second point in x-z plane
// then build a map with the key of the start point, and the value is an array of all segment start from that point
// loop through the segments, for each segment, using the map to find all the segments that start from the current segment end, and merge if it belongs to the same line
// keep doing it until the length of segment did changes between two loop
export const mergeSegments = (segments) => {
  const getkeyFromPoint = (p) => p.join('#');
  const getPointFromKey = (key) => key.split('#').map(Number);

  const fixedDecimal = String(FLOAT_POINT_ADJUST).length - 3;
  const parsedSegment = segments.map(([p1, p2]) =>
    [
      [p1.x, p1.z].map((val) => Number(val.toFixed(fixedDecimal))),
      [p2.x, p2.z].map((val) => Number(val.toFixed(fixedDecimal))),
    ]
      .sort(sortPoint)
      .map(getkeyFromPoint)
      .join('_')
  );

  const uniqueSegments = [...new Set(parsedSegment)];
  const sortSegments = uniqueSegments
    .map((segmentKey) => segmentKey.split('_').map(getPointFromKey))
    .sort(sortSegment)
    .map(([p1, p2]) => {
      const p1key = getkeyFromPoint(p1);
      const p2key = getkeyFromPoint(p2);
      return {
        p1key,
        p2key,
        p1,
        p2,
        segmentKey: `${p1key}_${p2key}`,
      };
    });

  // construct the map
  const segmentsMap = new Map();
  for (const { p1key, p2key } of sortSegments) {
    let set = segmentsMap.get(p1key);
    if (!set) {
      set = new Set();
      segmentsMap.set(p1key, set);
    }
    set.add(p2key);
  }
  let prevLen;

  // break the loop if the segments array length didn't change, means no merge happened in the prev loop
  while (prevLen !== sortSegments.length) {
    let idx = 0;
    prevLen = sortSegments.length; // update the prevLen, if no merge happened, the sortSegments length should be same

    while (idx < sortSegments.length) {
      const { p1key, p2key, p1, p2 } = sortSegments[idx];

      const candidateSet = segmentsMap.get(p2key);

      if (!candidateSet) {
        // no other segment start from the end of this segment, merge is not possible
        ++idx;
        continue;
      }

      const mergableKey = [...candidateSet].find((endkey) => {
        const p3 = getPointFromKey(endkey);

        const crossProd =
          (p2[0] - p1[0]) * (p3[1] - p1[1]) - (p3[0] - p1[0]) * (p2[1] - p1[1]);

        // if corssProd is zero, means the two segment are parallel
        // the two segment also has one common point, so it is able to merge
        return crossProd === 0;
      });

      if (!mergableKey) {
        ++idx;
        continue;
      }

      // remove the target segment from the set and map
      candidateSet.delete(mergableKey);
      if (candidateSet.size === 0) segmentsMap.delete(p2key);

      // remove the target segment from sortSegments array, the sorted input will guarentee the targetIdx is larger than current segment idx

      const targetIdx = sortSegments
        .map(({ segmentKey }) => segmentKey)
        .indexOf(`${p2key}_${mergableKey}`);

      if (idx >= targetIdx)
        throw new Error('Something wrong, this condition should not be valid');

      // update the source segment data in the segment map
      const sourceSet = segmentsMap.get(p1key);
      sourceSet.delete(p2key);
      sourceSet.add(mergableKey);

      // update the source segment data in the segments array
      sortSegments[idx].p2 = sortSegments[targetIdx].p2;
      sortSegments[idx].p2key = mergableKey;
      sortSegments[idx].segmentKey = `${p1key}_${mergableKey}`;

      sortSegments.splice(targetIdx, 1);

      // do not increase idx if a merge happend
    }
  }

  return sortSegments.map(({ p1, p2 }) => [
    { x: p1[0], y: 0, z: p1[1] },
    { x: p2[0], y: 0, z: p2[1] },
  ]);
};

// used for find the closet distance from vertices of moving items to the segments from rest existing items
// the result will be used to calculate the translation so that the moving item will maintain a certain distance to the existing items
export const findClosetSegmentPointPair = (
  segments,
  points,
  Line3,
  Vector3
) => {
  // TO DO: write unit test
  const line = new Line3();
  const closestPoint = new Vector3();
  const closestSegmentPoint = new Vector3();
  const sourcePoint = new Vector3();

  let minDisSq = Infinity;
  const res = [];

  for (const segment of segments) {
    line.set(segment[0], segment[1]);
    for (const point of points) {
      sourcePoint.set(point.x, point.y, point.z);

      line.closestPointToPoint(sourcePoint, false, closestPoint);
      const closestDisSq = sourcePoint.distanceToSquared(closestPoint);

      line.closestPointToPoint(sourcePoint, true, closestSegmentPoint);
      const closestSegmentDisSq = sourcePoint.distanceToSquared(
        closestSegmentPoint
      );

      if (
        Math.abs(minDisSq - closestSegmentDisSq) > FLOAT_POINT_ADJUST &&
        closestSegmentDisSq < minDisSq
      ) {
        res.length = 0;
        minDisSq = closestSegmentDisSq;
      }

      if (Math.abs(minDisSq - closestSegmentDisSq) <= FLOAT_POINT_ADJUST) {
        res.push({
          segment,
          point: sourcePoint.clone(),
          closetPoint: closestPoint.clone(),
          closestSegmentPoint: closestSegmentPoint.clone(),
          closestDis: Math.sqrt(closestDisSq),
          closestSegmentDis: Math.sqrt(closestSegmentDisSq),
        });
      }
    }
  }

  return res;
};

export const getTranslationOffset = (segmentPointPairs) => {
  // TO DO: Write unit test

  const getOffset = (segmentPointPair) => {
    const { closetPoint, point, closestDis } = segmentPointPair;
    const snapDistance =
      closestDis <= DRAG_UNIT_DISTANCE / 2
        ? DRAG_UNIT_DISTANCE / 2
        : DRAG_UNIT_DISTANCE;
    return point
      .clone()
      .sub(closetPoint)
      .multiplyScalar(snapDistance / closestDis)
      .add(closetPoint)
      .sub(point);
  };
  // there are 3 different case
  // 1. segmentPointPairs has length 1, this means only one vertex of the moving items will project on the segment with min distance
  // 2. segmentPointPairs has length 2 AND closestSegmentPoint are different
  // this means two different vertices of the moving items are porject on the same segment
  // 3. segmentPointPairs has length 2 AND closestSegmentPoint are same
  // this means the same vertices are project to different segment
  // the case 1 and 2 should be handled with the same approach

  const offset = { x: 0, y: 0, z: 0 };
  if (
    segmentPointPairs.length === 1 ||
    !isSameTranslation(
      segmentPointPairs[0].closestSegmentPoint,
      segmentPointPairs[1].closestSegmentPoint
    )
  ) {
    if (segmentPointPairs[0].closestDis >= DRAG_UNIT_DISTANCE) return offset;
    return getOffset(segmentPointPairs[0]);
  } else {
    if (
      segmentPointPairs[0].closestDis >= DRAG_UNIT_DISTANCE ||
      segmentPointPairs[1].closestDis >= DRAG_UNIT_DISTANCE
    )
      return offset;

    const activate =
      segmentPointPairs[0].closestDis > segmentPointPairs[1].closestDis
        ? segmentPointPairs[0]
        : segmentPointPairs[1];

    return getOffset(activate);
  }
};

export const getNextValidLocalSide = (item, curSide) => {
  const curIdx = SIDE_KEYWORD.indexOf(curSide);
  let sideShiftIdx = 1;

  while (
    !item.hasOwnProperty(
      SIDE_KEYWORD[
        (curIdx + SIDE_KEYWORD.length - sideShiftIdx) % SIDE_KEYWORD.length
      ]
    )
  ) {
    ++sideShiftIdx;
  }

  return SIDE_KEYWORD[
    (curIdx + SIDE_KEYWORD.length - sideShiftIdx) % SIDE_KEYWORD.length
  ];
};

// given a targetIsland and an array of referenceIslands
// find the min translation along x and z dirction to make sure the targetIsland does not intersect with any of the reference island

// 1. it will loop through each item in targetIsland and find the overlay translation with all items in referenceIslands
// 2. merge all the overlay interval so that no overlay interval exist
// 3. find the min translation that falls out to the overlay interval
export const findIslandValidTranslation = (
  targetIsland,
  referenceIslands,
  optionalGap = 0
) => {
  const xInvalidRange = [];
  const zInvalidRange = [];

  targetIsland.getItems().map((targetItem) => {
    referenceIslands.forEach((referenceIsland) => {
      referenceIsland.getItems().forEach((referenceItem) => {
        const { xRange, zRange } = findItemintersectRange(
          targetItem,
          referenceItem,
          optionalGap
        );

        if (xRange) xInvalidRange.push(xRange);
        if (zRange) zInvalidRange.push(zRange);
      });
    });
  });

  const xMergedRange = mergeIntervals(xInvalidRange);
  const zMergedRange = mergeIntervals(zInvalidRange);

  const findMinTranslation = (ranges) =>
    ranges.length
      ? ranges.reduce(
          (res, range) => {
            range.forEach((boundary) => {
              if (boundary < 0) {
                res.negitive = Math.max(res.negitive, boundary);
              } else {
                res.positive = Math.min(res.positive, boundary);
              }
            });
            return res;
          },
          { negitive: -Infinity, positive: Infinity }
        )
      : 0;

  return {
    x: findMinTranslation(xMergedRange),
    z: findMinTranslation(zMergedRange),
  };
};

// return the intervals along x and z direction, where if apply the translation to targetItem falls into the range, two item's bounding box will overlay
// Note that the value of x and z is restract to that axis only and should not be consider together.
// If apply translation on both x and z with both value falls into the range, the two items may not intersect and vise verse if apply translation that both falls outside of the range, it does not gaurante
const findItemintersectRange = (targetItem, referenceItem, optionalGap = 0) => {
  const targetBox = targetItem.getBoundingBox();
  const referenceBox = referenceItem.getBoundingBox();

  let xRange = null;
  let zRange = null;

  if (
    Math.max(targetBox.max.x, referenceBox.max.x) -
      Math.min(targetBox.min.x, referenceBox.min.x) <
    targetBox.max.x + referenceBox.max.x - targetBox.min.x - referenceBox.min.x
  ) {
    // the two bounding box are overlay when project to x axis, this means there are potential range when moving target item along z will cause two box overlay

    zRange = [
      referenceBox.min.z - optionalGap - targetBox.max.z,
      referenceBox.max.z + optionalGap - targetBox.min.z,
    ];
  }

  if (
    Math.max(targetBox.max.z, referenceBox.max.z) -
      Math.min(targetBox.min.z, referenceBox.min.z) <
    targetBox.max.z + referenceBox.max.z - targetBox.min.z - referenceBox.min.z
  ) {
    xRange = [
      referenceBox.min.x - optionalGap - targetBox.max.x,
      referenceBox.max.x + optionalGap - targetBox.min.x,
    ];
  }

  return { xRange, zRange };
};

const mergeIntervals = (intervals) => {
  intervals.sort((a, b) => a[0] - b[0] || a[1] - b[1]);

  const merged = [];

  for (const interval of intervals) {
    if (!merged.length || interval[0] > merged[merged.length - 1][1]) {
      merged.push(interval);
    } else {
      merged[merged.length - 1][1] = Math.max(
        merged[merged.length - 1][1],
        interval[1]
      );
    }
  }

  return merged;
};
// format rotation in range [0, 360)
export const mapRotationInRange = (rotation = 0) => {
  if (rotation < 0) rotation += Math.ceil(-rotation / 360) * 360;
  return rotation % 360;
};

export const getPosition = (boundingBox, size, corner) => {
  const { min, max } = boundingBox;
  const { width, depth } = size;
  let result = null;
  switch (corner) {
    case 'bottomLeft':
      result = {
        x: min.x + width / 2,
        y: 0,
        z: max.z - depth / 2,
      };
      break;
    case 'bottomRight':
      result = {
        x: max.x - width / 2,
        y: 0,
        z: max.z - depth / 2,
      };
      break;
    case 'topRight':
      result = {
        x: max.x - width / 2,
        y: 0,
        z: min.z + depth / 2,
      };
      break;
    case 'topLeft':
      result = {
        x: min.x + width / 2,
        y: 0,
        z: min.z + depth / 2,
      };
      break;
    default:
      break;
  }
  return result;
};
