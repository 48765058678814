const DRAG_PIXEL_SENSITIVITY = 100;

export const CAMERA_ROTATIONS = ['0', '1', '2', '3', '4', '5', '6', '7'];

export const setRotations = (array) => {
  CAMERA_ROTATIONS.splice(0, CAMERA_ROTATIONS.length);
  CAMERA_ROTATIONS.push(...array);
};

export const rotateTool = {
  active: true,
  enabled: false,
  key: 'rotate',
  handlers: {
    drag: (ev) => {
      const startPos = ev.clientX;
      const config = window.threekit.playerConfigurator.getConfiguration();
      const view = config.Rotation || '0';
      const startIndex = CAMERA_ROTATIONS.indexOf(view);
      let deltaIdx = 0;
      return {
        handle: (ev) => {
          const newDeltaIdx = Math.floor(
            (ev.clientX - startPos) / DRAG_PIXEL_SENSITIVITY
          );
          if (newDeltaIdx !== deltaIdx) {
            deltaIdx = newDeltaIdx;
            let newIndex = (startIndex + newDeltaIdx) % CAMERA_ROTATIONS.length;
            if (newIndex <= -1) newIndex += CAMERA_ROTATIONS.length;
            window.threekit.playerConfigurator.setConfiguration({
              Rotation: CAMERA_ROTATIONS[newIndex],
            });
          }
        },
      };
    },
  },
};

export const tools = {
  rotate: rotateTool,
};
