import { SIDE_HEIGHT } from './constants';
/* global __THREEKIT_AUTH_TOKEN__,  __THREEKIT_HOST__, __THREEKIT_ORG__ */

export const getModelFromMouseEvent = (ev, optionalPrefix) => {
  const nodes = ev.hitNodes.reverse();
  if (optionalPrefix) {
    // the plus sign is close to the floor and may below the shadow plan
    let targetNode;

    for (let idx = 0; idx < ev.hitNodes.length && !targetNode; ++idx) {
      const hitNode = nodes[idx];
      targetNode = hitNode.hierarchy.find((node) =>
        node.name.startsWith(optionalPrefix)
      );
    }

    return targetNode;
  }

  return (
    nodes[nodes.length - 1] &&
    nodes[nodes.length - 1].hierarchy.find((node) =>
      node.name.startsWith('ConfigModel_')
    )
  );
};

export const getRootId = (() => {
  let rootId;

  return async (api) => {
    if (rootId) return rootId;

    let rootSceneId = api.instanceId;

    let instanceNode = api.scene.get({ id: rootSceneId });

    while (instanceNode.type !== 'Scene') {
      rootSceneId = await api.player.getAssetInstance({
        id: rootSceneId,
        plug: 'Proxy',
        property: instanceNode.type === 'Item' ? 'asset' : 'webgl',
      });

      instanceNode = api.scene.get({ id: rootSceneId });
    }
    rootId = rootSceneId;

    return rootId;
  };
})();

export const renderPlusIcon = async (api, connectors) => {
  const rootSceneId = await getRootId(api);
  const parentId = api.scene.findNode({ from: rootSceneId, type: 'Objects' });

  return connectors
    .map((itemConnectors) =>
      itemConnectors.map((sideConnectors) => {
        const { target, targetLocalSide, insert, anytable } = sideConnectors[0]; // we only use the first side connector to render the plus icon
        const insertAnytable = insert && anytable;
        const translation = target.getPlusSignTranslation(
          targetLocalSide,
          insert ? (anytable ? -1.17 : 1) * SIDE_HEIGHT : 0
        );
        if (insertAnytable) translation.y = 0.903;

        const jsonName = JSON.stringify({
          ...sideConnectors[0],
          target: target.getInstanceId(),
        });

        const nodeName = `PlusIcon_${jsonName}`;

        sideConnectors[0].translation = translation;

        return api.scene.addNode(
          {
            name: nodeName,
            type: 'Model',
            plugs: {
              Null: [
                {
                  type: 'Model',
                  asset: {
                    assetId: '7c84a5dc-8f12-4cdf-b0b8-c90ac116e258',
                  },
                },
              ],
              Transform: [{ type: 'Transform', translation }],
            },
          },
          parentId
        );
      })
    )
    .reduce((res, itemPlusIcons) => res.concat(itemPlusIcons), []);
};

export const renderPlusIconAtPosition = async (
  api,
  translation = { x: 0, y: 0, z: 0 }
) => {
  const rootSceneId = await getRootId(api);
  const parentId = api.scene.findNode({ from: rootSceneId, type: 'Objects' });

  const nodeName = `PlusIcon_${JSON.stringify({
    target: 'new',
    translation,
  })}`;

  return [
    api.scene.addNode(
      {
        name: nodeName,
        type: 'Model',
        plugs: {
          Null: [
            {
              type: 'Model',
              asset: {
                assetId: '7c84a5dc-8f12-4cdf-b0b8-c90ac116e258',
              },
            },
          ],
          Transform: [{ type: 'Transform', translation }],
        },
      },
      parentId
    ),
  ];
};

export const postConfiguration = (configurationJson) => {
  const url = `${__THREEKIT_HOST__}/api/configurations?bearer_token=${__THREEKIT_AUTH_TOKEN__}`;

  const body = new FormData();
  body.append('orgId', __THREEKIT_ORG__);
  body.append('productId', window.threekit.api.assetId);
  // TO DO: what should productVersion be?
  body.append('productVersion', 'test_version');
  body.append('variant', configurationJson);

  return fetch(url, {
    method: 'post',
    body,
  })
    .then((res) => res.json())
    .catch((e) => console.error(e));
};
