import { Collisions } from 'detect-collisions';
import { getWorldTransform } from '../../helpers';
import { decomposeForTransformPlug, isUnattachedAccessory } from '../placement';
import { getPolygonForItem } from './shapes';
import { toggleHighlightError } from '../selection';

// Create the collision system
const system = new Collisions();

// Create a Result object for collecting information about the collisions
const result = system.createResult();

function createColliderForItem(itemId) {
  let collider;
  const poly = getPolygonForItem(itemId);
  if (poly) collider = system.createPolygon(0, 0, poly);
  else {
    // collider = system.createCircle(0, 0, 0.3683);
    return null;
  }
  system.update();
  return collider;
}

const colliders = {};

export function addColliders(itemIds) {
  itemIds.forEach((itemId) => {
    const collider = createColliderForItem(itemId);
    if (collider) colliders[itemId] = collider;
  });
  syncColliderTransforms(itemIds);
  //   system.update();
}

export function removeColliders(itemIds) {
  itemIds.forEach((itemId) => {
    const collider = colliders[itemId];
    if (collider) system.remove(collider);
  });
  system.update();
}

function setColliderTransforms(itemId, nodeTransform) {
  const { Vector3, Quaternion, Euler } = window.threekit.api.THREE;
  const pos = new Vector3();
  const quat = new Quaternion();
  const scl = new Vector3();

  const collider = colliders[itemId];
  if (!collider) return;

  nodeTransform.decompose(pos, quat, scl);
  const eul = new Euler();

  // NB: It was found that the order needs to have 'Y' first or last to get
  // the intended Y rotation. Otherwise for example with our usual 'ZYX', you
  // can have a rotation of {x:180, y:0, z:180}. This is effectively the same
  // as {x:0, y:180, z:0}, which is what we want.
  // Note: this solution is not strictly based on theory, but it works. We
  // should keep this in mind in case strange collision edge cases arise.
  eul.setFromQuaternion(quat, 'YZX');
  const rotVecDegrees = eul.toVector3();

  collider.x = pos.x;
  collider.y = pos.z;
  // console.log('calc collider angle, rot = ', rotation, translation, scale);
  // NB: collision system uses opposite rotation orientation from us
  if (collider.hasOwnProperty('angle')) collider.angle = -rotVecDegrees.y;
}

export function syncColliderTransforms(itemIds, update = true) {
  itemIds.forEach((itemId) =>
    setColliderTransforms(itemId, getWorldTransform(itemId))
  );

  if (update) system.update();
  // testCollisions(itemIds[0]);

  // This is slightly hacky, but easy and works for now. It assumes that
  // whatever items have moved and thus have collider updates are also the same
  // items that are currently selected.
  const colliding = itemIds.some(isColliding);
  const hasUnattachedAccessories = itemIds.some(isUnattachedAccessory);
  toggleHighlightError(colliding || hasUnattachedAccessories);
}

export function isColliding(itemId) {
  const collider = colliders[itemId];

  if (collider) {
    const potentials = collider.potentials();

    for (const body of potentials) {
      if (collider.collides(body)) {
        return true;
      }
    }
  }

  return false;
}

export function wouldCollide(itemId, nodeTransform) {
  setColliderTransforms(itemId, nodeTransform);
  system.update();
  const collisionDetected = isColliding(itemId);
  syncColliderTransforms([itemId]);
  return collisionDetected;
}

function testCollisions(itemId) {
  const collider = colliders[itemId];
  const potentials = collider.potentials();

  for (const body of potentials) {
    if (collider.collides(body)) {
      console.log('Collision detected!');
    }
  }
}
