/* global __THREEKIT_AUTH_TOKEN__,  __THREEKIT_HOST__, __THREEKIT_ORG__ */
import QRCode from 'qrcode';

export const getSavedConfiguration = (configId) => {
  const url = `${__THREEKIT_HOST__}/api/configurations/${configId}?bearer_token=${__THREEKIT_AUTH_TOKEN__}`;

  return fetch(url, {
    method: 'get',
  })
    .then((res) => res.json())
    .catch((e) => console.error(e));
};

export const postConfiguration = (
  configuration,
  productVersion,
  assetId = window.threekit.api.assetId
) => {
  const url = `${__THREEKIT_HOST__}/api/configurations?bearer_token=${__THREEKIT_AUTH_TOKEN__}`;

  const body = new FormData();
  body.append('orgId', __THREEKIT_ORG__);
  body.append('productId', assetId);
  body.append('productVersion', productVersion);
  body.append('variant', JSON.stringify(configuration));

  return fetch(url, {
    method: 'post',
    body,
  })
    .then((res) => res.json())
    .catch((e) => console.error(e));
};

// const AR_ASSET_ID = 'f40eee5b-0003-4e1f-a95d-4b560856b640';

export const exportAR = async ({ configuration, id, url }, productVersion) => {
  const { assetId } = window.threekit.api;
  
  if (typeof configuration === 'object') {
    Object.keys(configuration).forEach((key) => {
      if (/stealthtech/i.test(key)) {
        configuration[key] = 'off';
      } else {
        switch (key) {
          case 'Floor':
            configuration[key] = {};
            break;
          case 'Dimension':
            configuration[key] = 'off';
            break;
          case 'Rotation':
            configuration[key] = '0';
            break;
          default:
            break;
        }
      }
    });
  }

  const qrUrl = new URL(url || window.location.href);
  qrUrl.searchParams.append('enableAR', true);
  if (!(id && url)) {
    const configRes = await postConfiguration(
      configuration,
      productVersion,
      assetId
    );

    const { shortId } = configRes;

    qrUrl.searchParams.append('tkcsid', shortId);
  } else qrUrl.search.append('tkcsid', id);

  const results = { url: qrUrl.toString() };

  results.dataURL = await QRCode.toDataURL(results.url);

  ['glb', 'usdz'].forEach((format) => {
    const exportURL = `${__THREEKIT_HOST__}/api/asset-jobs/${assetId}/export/${format}?bearer_token=${__THREEKIT_AUTH_TOKEN__}&cache=true`;
    results[format] = fetch(exportURL, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        orgId: __THREEKIT_ORG__,
        assetId,
        sync: true,
        useCatalog: false,
        configuration,
        settings: { arExport: true },
      }),
    })
      .then((r) => r.json())
      .then((res) => {
        const fileObj = res.job?.runs[0]?.results?.files[0];
        if (fileObj) {
          const { id, name } = fileObj;
          return {
            name,
            url: `${__THREEKIT_HOST__}/api/files/${id}/content`,
          };
        }
      });
  });

  return results;
};
