import { SEAT_HEIGHT } from '../constants';

import Side from './Side';

export default class AngledSide extends Side {
  constructor(threekitApi, options = {}) {
    super(threekitApi, options);
    this._key = 'angled';

    this._initAlignmentWithConnector(options.connector);
  }

  _connectTo = (connector) => {
    const { target } = connector;
    if (target._type !== 'seat' && target._type !== 'anytable')
      throw new Error('Angled Side can only connect to Seat');

    this._connectHelper({ ...connector, localSide: 'bottom' });
    target._updatePillow();
  };

  validateConnection = (item) => {
    return item._key !== 'wedge';
  };
}
