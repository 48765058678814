import '@babel/polyfill'; // needed to transpile async/await in babel-transpiled webpack output
import Rollbar from 'rollbar';
import { init as init3D } from './v1/index';
import { init as initVRay } from './v2/vray';
import { init as init3DV2 } from './v3/index';
import { status } from './status';

// include and initialize the rollbar library with your access token
const rollbar = new Rollbar({
  accessToken: '1f9a9138b8454c6aafbe393005663bde',
  captureUncaught: true,
  captureUnhandledRejections: true,
  autoInstrument: { log: false }, // if true, it catches errors which then have rollbar as the top-level source map trace in the console. This effects BA and TK code alike
});

async function init(params) {
  console.log('threekit init:', params);
  const { el, initialConfiguration, initialJson } = params;
  const state = {
    initialed3D: false,
    initialed2D: false,
    api2D: null,
    api3D: null,
  };
  const { parentEl, el2D, el3D, setMode: setElementMode } = initPlayerEl(el);

  const setPlayerMode = async (mode) => {
    if (mode === '2D') {
      setElementMode('2D');
      if (!state.initialed2D) {
        state.api2D = await initVRay({
          ...params,
          el: el2D,
        });
        state.api2D.mode = '2D';
        state.initialed2D = true;
      }
      Object.assign(window.threekit, state.api2D);
      status.api2D = state.api2D;
      return state.api2D;
    } else if (mode === '3D') {
      setElementMode('3D');
      if (!state.initialed3D) {
        state.api3D = await init3DV2({
          ...params,
          el: el3D,
        });
        state.initialed3D = true;
        state.api3D.mode = '3D';
      }
      Object.assign(window.threekit, state.api3D);
      status.api3D = state.api3D;
      return state.api3D;
    }
  };

  window.threekit.setPlayerMode = setPlayerMode;
  const { productCategory, mode } = params;

  if (/^vray$/i.test(mode)) {
    return setPlayerMode('2D');
  } else if (/^ar$/i.test(mode)) {
    //
  } else {
    const api = await setPlayerMode('3D');
    if (
      !initialJson &&
      initialConfiguration &&
      initialConfiguration.productId
    ) {
      await api.configurator.setProduct(
        initialConfiguration.productId,
        initialConfiguration
      );
    }
    return api;
  }
}

window.threekit = { init };

function initPlayerEl(el) {
  const parentEl = document.getElementById(el);

  const el2D = document.createElement('div');
  el2D.setAttribute('style', 'display: block; width: 100%; height: 100%');
  parentEl.appendChild(el2D);

  const el3D = document.createElement('div');
  el3D.setAttribute('style', 'display: none; width: 100%; height: 100%');
  parentEl.appendChild(el3D);

  const setMode = (mode) => {
    el2D.style.display = mode === '2D' ? 'block' : 'none';
    el3D.style.display = mode === '2D' ? 'none' : 'block';
  };

  return { parentEl, el2D, el3D, setMode };
}
