import { getWorldTransform } from '../../helpers';

// // Original custom version of what api.scene.alignNode does. Keeping for posterity and possible future optimization.
// function alignNodePointToPoint(
//   nodeId,
//   srcPointWorldTransform,
//   targetPointWorldTransform,
//   opts = {}
// ) {
//   const { negZ } = opts;
//   const { Matrix4 } = window.api.THREE;
//   const invSrc = new Matrix4().getInverse(srcPointWorldTransform);
//   let target = targetPointWorldTransform;
//   if (negZ)
//     target = target.clone().multiply(new Matrix4().makeRotationY(Math.PI));
//   const srcToTarget = new Matrix4().multiplyMatrices(target, invSrc);

//   const nodeWorldTransform = window.api.scene.get({
//     id: nodeId,
//     evalNode: true,
//   }).worldTransform;

//   // NB: This assumes node local transform is same as its world transform Works
//   // for now, but will break if its ever parented under another node that has a
//   // non-zero transform which we would then need to account for
//   const newTransform = new Matrix4().multiplyMatrices(
//     srcToTarget,
//     nodeWorldTransform
//   );

//   const newTransformDecomposed = decomposeForTransformPlug(newTransform);

//   window.api.scene.set(
//     { id: nodeId, plug: 'Transform', property: 'translation' },
//     newTransformDecomposed.translation
//   );
//   window.api.scene.set(
//     { id: nodeId, plug: 'Transform', property: 'rotation' },
//     newTransformDecomposed.rotation
//   );
//   window.api.scene.set(
//     { id: nodeId, plug: 'Transform', property: 'scale' },
//     newTransformDecomposed.scale
//   );
// }

// export function alignNodePointToNode(nodeA, ptNode, nodeB, opts = {}) {
//   const ptWorldTransform = window.api.scene.get({ id: ptNode, evalNode: true })
//     .worldTransform;
//   const nodeBWorldTransform = window.api.scene.get({
//     id: nodeB,
//     evalNode: true,
//   }).worldTransform;
//   alignNodePointToPoint(nodeA, ptWorldTransform, nodeBWorldTransform, opts);
// }

// Take a THREE.Matrix4 and decompose it into translation, rotation and scale
// values suitable for our scenegraph Transform plug.
export function decomposeForTransformPlug(mat, rotInDegs = true) {
  const { Vector3, Quaternion, Euler, Math } = window.threekit.api.THREE;

  const pos = new Vector3();
  const quat = new Quaternion();
  const scl = new Vector3();

  mat.decompose(pos, quat, scl);

  const eul = new Euler();
  eul.setFromQuaternion(quat, 'ZYX');
  const rotVecDegrees = eul.toVector3();
  if (rotInDegs) rotVecDegrees.multiplyScalar(Math.RAD2DEG);
  return { translation: pos, rotation: rotVecDegrees, scale: scl };
}

// Hmm, so this new attempt using builtin alignNode, followed by my own 180
// rotation, results in the exact same behaviour as my original alignment
// function where I do the entire alignment myself. This includes the bug with
// front/back attachments to left/right connectors...why?
export function getAlignedNodeTransform(nodeId, targetId, anchorNodeId) {
  const { api } = window.threekit;

  const { scale } = decomposeForTransformPlug(getWorldTransform(nodeId));

  const { pos, quat } = getAttachTransforms(
    nodeId,
    targetId,
    anchorNodeId,
    true
  );

  // The above works correctly (as presumably does my own alignment code) to
  // position and orient the item so the connectors have the exact same transform.
  // The problem is with the code below attempting to rotate it 180 degrees. For
  // front/back to left/right connections, it seems to be rotation 90 deg?

  const { Vector3, Matrix4 } = api.THREE;

  const nodeTransform = new Matrix4().compose(pos, quat, scale);

  const targetTransform = getWorldTransform(targetId);

  const parentId = api.scene.findNode({ id: nodeId, parent: true });
  const parentTransform = getWorldTransform(parentId) || new Matrix4();
  const parentInvTransform = new Matrix4().getInverse(parentTransform);

  // make transforms relative to node's parent to simplify logic (need final transform data relative to parent anyway)
  targetTransform.premultiply(parentInvTransform);

  const nodePos = new Vector3().setFromMatrixPosition(nodeTransform);
  const targetPos = new Vector3().setFromMatrixPosition(targetTransform);

  const nodeToTarget = nodePos.clone().sub(targetPos).sub(nodePos);

  const nodeToTargetMat = new Matrix4().makeTranslation(
    nodeToTarget.x,
    nodeToTarget.y,
    nodeToTarget.z
  );
  const rotMat = new Matrix4().makeRotationY(Math.PI);
  const nodeToTargetMatInv = new Matrix4().getInverse(nodeToTargetMat);
  const fullMat = nodeToTargetMat
    .premultiply(rotMat)
    .premultiply(nodeToTargetMatInv);

  nodeTransform.premultiply(fullMat);

  return nodeTransform;
}

function getAttachTransforms(nodeId, alignNodeId, anchorNodeId, withRotation) {
  const { api } = window.threekit;
  const { Matrix4, Quaternion, Vector3 } = api.THREE;
  const nodeTransform = getWorldTransform(nodeId);
  const nodeTransformPlug = getNodeTransformPlug(nodeId);
  const alignTransform = getWorldTransform(alignNodeId);
  const anchorTransform = getWorldTransform(anchorNodeId);

  if (!(nodeTransform && alignTransform && anchorTransform)) {
    // Couldn't find one of the targets
    return { translation: undefined, rotation: undefined };
  }

  const parentId = api.store.get('sceneGraph').nodes[nodeId].parent;
  const parentTransform = getWorldTransform(parentId) || new Matrix4();
  const parentInvTransform = new Matrix4().getInverse(parentTransform);

  // We will be setting the transforms of the model node, relative to its parent
  // calculations wll be done in parent space to simplify the logic
  nodeTransform.premultiply(parentInvTransform);
  alignTransform.premultiply(parentInvTransform);
  anchorTransform.premultiply(parentInvTransform);

  const tmpScale = new Vector3();
  const tmpShear = new Vector3();

  const nodePos = new Vector3();
  const nodeQuat = new Quaternion();
  nodeTransform.decompose(nodePos, nodeQuat, tmpScale, tmpShear);

  const alignPos = new Vector3();
  const alignQuat = new Quaternion();
  alignTransform.decompose(alignPos, alignQuat, tmpScale, tmpShear);

  const anchorPos = new Vector3();
  const anchorQuat = new Quaternion();
  anchorTransform.decompose(anchorPos, anchorQuat, tmpScale, tmpShear);

  let newNodePos;
  {
    // Align relative to anchor point
    const relAlignPos = alignPos.clone().sub(anchorPos);

    // Set the modelPos to match up the anchor to the dock. Since we only set the translation, we have to remove the pivot offets that get added to translation
    newNodePos = relAlignPos
      .add(nodePos)
      .sub(getRotatePivotOffset(nodeTransformPlug))
      .sub(getScalePivotOffset(nodeTransformPlug));
  }
  let newNodeQuat;
  if (withRotation) {
    const zero = new Vector3(0, 0, 0);

    // Some extra math is required to support the following, check out getLocalTransform from operator Transform
    if (!zero.equals(nodeTransformPlug.scalePivotOffset))
      console.warn(
        "alignNode doesn't support scale pivot offset with rotation"
      );
    if (!zero.equals(nodeTransformPlug.localRotatePivot))
      console.warn("alignNode doesn't support local rotation pivot");

    // Rotation from anchorQuat to alignQuat
    const rotationQuat = alignQuat
      .clone()
      .multiply(anchorQuat.clone().inverse());

    const preRotationQuat = new Quaternion().setFromEuler(
      getPreRotation(nodeTransformPlug)
    );

    // We set the nodeRot to match up the anchor to the align.
    // Since we only set the rotation, we have to remove the pre-rotation that gets added to rotation
    newNodeQuat = nodeQuat
      .clone()
      .premultiply(rotationQuat)
      .premultiply(preRotationQuat.inverse());

    // Since the anchor can be off-center, we have to take into account the possible deviations introduced by the rotation
    const anchorRel = anchorPos.clone().sub(nodePos);

    const newAnchorRel = anchorRel.clone().applyQuaternion(rotationQuat);

    const rotationOffset = anchorRel.sub(newAnchorRel);

    newNodePos.add(rotationOffset);
  }

  return {
    pos: newNodePos,
    quat: newNodeQuat,
  };
}

function getNodeTransformPlug(id) {
  const node = window.threekit.api.store.get('sceneGraph').nodes[id];
  return node && node.plugs.Transform[0];
}

export function getRotatePivotOffset(transform, optionalTarget) {
  return (optionalTarget || new window.threekit.api.THREE.Vector3()).copy(
    transform.rotatePivotOffset
  );
}

export function getScalePivotOffset(transform, optionalTarget) {
  return (optionalTarget || new window.threekit.api.THREE.Vector3()).copy(
    transform.scalePivotOffset
  );
}

export function getPreRotation(transform, optionalTarget) {
  const { Vector3, Euler, Math: ThreeMath } = window.threekit.api.THREE;
  const { preRotation, rotateOrder } = transform;
  const preRotationRadians = new Vector3()
    .copy(preRotation)
    .multiplyScalar(ThreeMath.DEG2RAD);

  return (optionalTarget || new Euler()).setFromVector3(
    preRotationRadians,
    rotateOrder
  );
}

export function rotationFromQuat(quat, rotateOrder, optionalTarget) {
  const { Euler, Math: ThreeMath } = window.threekit.api.THREE;
  return (optionalTarget || new Euler())
    .setFromQuaternion(quat, rotateOrder)
    .toVector3()
    .multiplyScalar(ThreeMath.RAD2DEG);
}

// window.alignNodePointToNode = alignNodePointToNode;
// window.alignNodePointToPoint = alignNodePointToPoint;
