import { v4 as uuidv4 } from 'uuid';
import {
  traverseHierarchy,
  getWorldPosition,
  getWorldTransform,
  setConfigOnModel,
} from '../../helpers';
import { getAlignedNodeTransform, decomposeForTransformPlug } from './align';
import {
  getItem,
  getItems,
  setSeatBackPillow,
  getSeatBackPillow,
  isAccessory,
} from '../items';
import { backPillowAdded, backPillowRemoved } from '../capacity';
import { wouldCollide } from '../collision';

const ATTACH_DISTANCE_THRESHOLD = 0.02; // distance within which two connectors will be considered effectively attached
const ATTACH_ANGLE_THRESHOLD = 1;

const CONNECTOR_TARGETS = {
  seatClampSlot: new Set(['seatClampSlot', 'sideClampSlot']),
  sideClampSlot: new Set(['seatClampSlot']),
  sideAccessory: new Set(['sideAccessorySlot']),
  tableAccessory: new Set(['tableAccessorySlot']),
  rollArmSideAccessory: new Set(['rollArmSideAccessorySlot']),
  sideAttachment: new Set(['sideAttachment']),
};

// Sides in array placed clockwisely
export const ALL_SEAT_SIDES = ['front', 'left', 'back', 'right'];

export const PREFERRED_SEAT_SIDES = ['back', 'left', 'right', 'front'];

export const OPPOSITE_SEAT_SIDES = {
  front: 'back',
  back: 'front',
  left: 'right',
  right: 'left',
};

export const NEXT_SEAT_SIDES = {
  front: 'left',
  back: 'right',
  left: 'back',
  right: 'front',
};

export const PREVIOUS_SEAT_SIDES = {
  front: 'right',
  back: 'left',
  left: 'front',
  right: 'back',
};

export function isSameSeatSideType(seatSideA, seatSideB) {
  return (
    seatSideA === seatSideB || OPPOSITE_SEAT_SIDES[seatSideA] === seatSideB
  );
}

export function isDeepSeatSide(seatSide) {
  return seatSide === 'left' || seatSide === 'right';
}

const connectors = { byId: {}, byItem: {}, byType: {} };
const attachments = {
  byId: {}, // attachment id --> attachment data. Note: id only needed for indexing from items/connectors. Not needed for persistence - can be regenerated on load.
  byItem: {},
  byConnector: {},
};
const backPillowSupport = {};

export async function registerConnectorsForItem(itemId) {
  await traverseHierarchy(itemId, (node) => {
    const { id, name } = node;

    if (name.startsWith('connector')) {
      const [, connectorName, type] = name.split('_');
      const connector = {
        id,
        name: connectorName,
        type,
        occupied: false,
        owner: itemId,
      };

      // if this is a seat connector, identify which side of the seat the
      // connector is on via a naming convention. This will be used for back
      // pillow and attachment logic.
      if (getItem(itemId).type === 'seat') {
        const lowerName = connectorName.toLowerCase();
        if (lowerName.includes('left')) connector.seatSide = 'left';
        else if (lowerName.includes('right')) connector.seatSide = 'right';
        else if (lowerName.includes('front')) connector.seatSide = 'front';
        else if (lowerName.includes('back')) connector.seatSide = 'back';
      }

      connectors.byId[id] = connector;
      if (!connectors.byType[type]) connectors.byType[type] = [];
      connectors.byType[type].push(id);
      if (!connectors.byItem[itemId]) connectors.byItem[itemId] = [];
      connectors.byItem[itemId].push(id);
    }
  });
}

export async function itemWillBeRemoved(itemId) {
  await detachAll(itemId);

  // handle clearing all state related to the item and its attachments and
  // connectors

  delete attachments.byItem[itemId];

  const itemConnectors = getConnectorsForItem(itemId);
  itemConnectors.forEach((connectorId) => {
    const { type } = getConnectorData(connectorId);
    delete attachments.byConnector[connectorId];
    delete connectors.byId[connectorId];
    connectors.byType[type] = connectors.byType[type].filter(
      (id) => id !== connectorId
    );
  });

  delete connectors.byItem[itemId];
}

export function getConnectorData(connectorId) {
  return connectors.byId[connectorId];
}

function setConnectorOccupied(connectorId, value) {
  const { owner, seatSide } = connectors.byId[connectorId];

  // If the connector is on a seat frame, all connectors on the same side as the
  // given connector, should be marked as occupied/unoccupied
  if (seatSide) {
    getConnectorsForItem(owner).forEach((connector) => {
      if (getConnectorData(connector).seatSide === seatSide)
        connectors.byId[connector].occupied = value;
    });
  } else {
    connectors.byId[connectorId].occupied = value;
  }
}

export function getConnectorsForItem(itemId) {
  return connectors.byItem[itemId] || [];
}

function isTargetForSource(targetConnectorId, srcConnectorId) {
  const { type: targetType, name: targetName } = getConnectorData(
    targetConnectorId
  );
  const { type: srcType, name: srcName } = getConnectorData(srcConnectorId);

  // CONNECTOR_TARGETS[srcType] could be undefined if it is a connector type our
  // code is unaware of (ex. during dev/testing), or if the connector type only
  // ever acts as a target, not a source (ie it doesn't snap to anything - other
  // things snap to it)
  const targetsForSrc = CONNECTOR_TARGETS[srcType];

  return (
    targetsForSrc &&
    CONNECTOR_TARGETS[srcType].has(targetType) &&
    // Sides should only attach to sides such that they are oriented the same way
    (srcType !== 'sideAttachment' ||
      (targetName === 'left' && srcName === 'right') ||
      (targetName === 'right' && srcName === 'left'))
  );
}

// given a src connector, find all valid target connectors on another item
export function getTargetConnectors(srcConnectorId, targetItemId) {
  const itemConnectors = getConnectorsForItem(targetItemId).map(
    getConnectorData
  );
  return itemConnectors
    .filter(({ id }) => {
      return isTargetForSource(id, srcConnectorId);
    })
    .map(({ id }) => id);
}

// Returns object where keys = srcItem connector ids, and values are arrays of
// valid target connectors found on any target items
//
// TODO: Unify with getConnectorMap. They are very similar, and their usage can
// likely be modified so that only one of them is necessary. Basically, we
// should have one standard data structure (and function that generates it) ro
// representing attachment targets for a given itemId (or array of item ids)
export function getValidConnectorTargetsForItem(
  srcItemId,
  targetItems = [],
  availableOnly = true
) {
  const srcConnectors = getConnectorsForItem(srcItemId);
  const validTargets = {};
  srcConnectors.forEach((srcConnectorId) => {
    if (availableOnly) {
      const { occupied } = getConnectorData(srcConnectorId);
      if (occupied) return;
    }

    targetItems.forEach((targetItemId) => {
      let targetConnectors = getTargetConnectors(srcConnectorId, targetItemId);
      if (targetConnectors && targetConnectors.length) {
        if (availableOnly)
          targetConnectors = targetConnectors.filter((connectorId) => {
            const { occupied } = getConnectorData(connectorId);
            return !occupied;
          });
        // all connectors on the target item might be occupied, so check again
        // if any are left
        if (targetConnectors.length) {
          if (!validTargets[srcConnectorId]) validTargets[srcConnectorId] = {};
          validTargets[srcConnectorId][targetItemId] = targetConnectors;
        }
      }
    });
  });

  return validTargets;
}

export function getConnectorDistance(connectorIdA, connectorIdB) {
  const worldPosA = getWorldPosition(connectorIdA);
  const worldPosB = getWorldPosition(connectorIdB);

  const connectorA = getConnectorData(connectorIdA);
  const connectorB = getConnectorData(connectorIdB);

  const itemIdA = connectorA.owner;
  const itemIdB = connectorB.owner;

  const itemDataA = getItem(itemIdA);
  const itemDataB = getItem(itemIdB);

  // If one of the connectors belongs to a deep side (indoor or outdoor) and the other belongs to a
  // deep side of a seat, we want to find the distance between the side
  // connector and the midpoint between the two seat connectors on the same deep
  // side, since a deep side connector actually connects to both seat connectors
  // on the deep side of a seat
  if (
    itemDataA.key.startsWith('deep') &&
    (connectorB.seatSide === 'left' || connectorB.seatSide === 'right')
  ) {
    worldPosB.add(getDeepConnectorsMidpointOffset(itemIdB, connectorB));
  } else if (
    itemDataB.key.startsWith('deep') &&
    (connectorA.seatSide === 'left' || connectorA.seatSide === 'right')
  ) {
    worldPosA.add(getDeepConnectorsMidpointOffset(itemIdA, connectorA));
  }
  return worldPosA.distanceTo(worldPosB);
}

// The deep side has one large connector that connects to both connectors on
// seat sides with two connectors, thus we need to find the offset from the
// given seat connector to the midpoint of the two seat connectors to align the
// deep side and seat correctly.
function getDeepConnectorsMidpointOffset(itemId, connectorA) {
  const { id: connectorAId, seatSide } = connectorA;
  const connectorBId = getConnectorsForItem(itemId).find(
    (connectorId) =>
      connectorId !== connectorAId &&
      getConnectorData(connectorId).seatSide === seatSide
  );

  const translationA = getWorldPosition(connectorAId);
  const translationB = getWorldPosition(connectorBId);

  return translationB.clone().sub(translationA).multiplyScalar(0.5);
}

// Returns true if the connectors were able to be attached successfully. False
// otherwise.
export async function attach(
  srcConnectorId,
  targetConnectorId,
  align = true,
  detectCollisions = true
) {
  const { api } = window.threekit;
  const srcConnector = getConnectorData(srcConnectorId);
  const targetConnector = getConnectorData(targetConnectorId);
  if (srcConnector.occupied || targetConnector.occupied) return false;
  const srcItemId = srcConnector.owner;
  const targetItemId = targetConnector.owner;

  const srcItemData = getItem(srcItemId);
  const targetItemData = getItem(targetItemId);

  let offset;
  if (
    srcItemData.key.startsWith('deep') &&
    (targetConnector.seatSide === 'left' ||
      targetConnector.seatSide === 'right')
  )
    offset = getDeepConnectorsMidpointOffset(targetItemId, targetConnector);
  else if (
    targetItemData.key.startsWith('deep') &&
    (srcConnector.seatSide === 'left' || srcConnector.seatSide === 'right')
  )
    offset = getDeepConnectorsMidpointOffset(srcItemId, srcConnector).negate();

  // Don't always want to align. Ex. when resuming state that has items already
  // in the right places, we only want to set the state for their attachment.
  if (align) {
    const nodeTransform = getAlignedNodeTransform(
      srcItemId,
      targetConnectorId,
      srcConnectorId
    );

    if (offset) {
      nodeTransform.setPosition(
        decomposeForTransformPlug(nodeTransform).translation.add(offset)
      );
    }

    if (detectCollisions && wouldCollide(srcItemId, nodeTransform))
      return false;

    const { translation, rotation } = decomposeForTransformPlug(nodeTransform);

    api.scene.set(
      { id: srcItemId, plug: 'Transform', property: 'translation' },
      translation
    );
    api.scene.set(
      { id: srcItemId, plug: 'Transform', property: 'rotation' },
      rotation
    );
    await api.player.evaluateSceneGraph();
  }

  setConnectorOccupied(srcConnectorId, targetConnectorId);
  setConnectorOccupied(targetConnectorId, srcConnectorId);

  // Now, update state for the new attachment
  const attachmentId = uuidv4();
  const attachment = {
    itemA: { itemId: srcItemId, connectorName: srcConnector.name },
    itemB: { itemId: targetItemId, connectorName: targetConnector.name },
  };
  attachments.byId[attachmentId] = attachment;

  if (!attachments.byConnector[srcConnectorId])
    attachments.byConnector[srcConnectorId] = [];
  attachments.byConnector[srcConnectorId].push(attachmentId);

  if (!attachments.byConnector[targetConnectorId])
    attachments.byConnector[targetConnectorId] = [];
  attachments.byConnector[targetConnectorId].push(attachmentId);

  if (!attachments.byItem[srcItemId]) attachments.byItem[srcItemId] = [];
  attachments.byItem[srcItemId].push(attachmentId);

  if (!attachments.byItem[targetItemId]) attachments.byItem[targetItemId] = [];
  attachments.byItem[targetItemId].push(attachmentId);

  if (srcConnector.type.includes('Accessory'))
    await reparentAccessory(targetItemId, srcItemId, true);

  // **** Handle back cushion ****
  // If one of the items is a seat, and the other is a side, the seat's back
  // pillow visualization may be affected by the newly attached side.
  //
  // If one of the items is a seat and the other is a side or both items are
  // seats, the back pillow visualization of corner seats attached in parallel
  // to the new attachment may be affected
  {
    let seatItemId;
    let seatConnector;

    if (srcItemData.type === 'seat' && targetItemData.type === 'seat') {
      const cornerSeats = [srcConnector, targetConnector].reduce(
        (acc, connector) => {
          const cornerSeat = getCornerSeat(connector);
          if (cornerSeat) acc.push(cornerSeat);
          return acc;
        },
        []
      );
      await Promise.all(cornerSeats.map(chooseBackPillowSide));
    } else if (srcItemData.type === 'seat' && targetItemData.type === 'side') {
      seatItemId = srcItemId;
      seatConnector = srcConnector;
    } else if (srcItemData.type === 'side' && targetItemData.type === 'seat') {
      seatItemId = targetItemId;
      seatConnector = targetConnector;
    }

    if (seatItemId) {
      const { seatSide } = seatConnector;

      const cornerSeats = [];
      const cornerSeat = getCornerSeat(seatConnector);
      if (cornerSeat && cornerSeat !== seatItemId) cornerSeats.push(cornerSeat);

      await Promise.all([
        addBackPillowSupport(seatItemId, attachmentId, seatSide),
        ...cornerSeats.map(chooseBackPillowSide),
      ]);
    }
  }

  if (srcItemData.type === 'seat' && targetItemData.type === 'table') {
    await setConfigOnModel(srcItemData.id, { TableOn: true });
  } else if (srcItemData.type === 'table' && targetItemData.type === 'seat') {
    await setConfigOnModel(targetItemData.id, { TableOn: true });
  }

  return true;
}

function chooseBackPillowSide(seatItemId) {
  const newPillowSide = findSeatBack(seatItemId);
  return setSeatBackPillow(seatItemId, newPillowSide);
}

// Logic to determine which side of a seat (if any) is the "back"
function findSeatBack(seatItemId) {
  // 1. If seat has no sides, it does not have a back
  // 2. If seat has one side, that side is the back
  // 3. If seat has three sides, the middle side is the back
  // 4. If seat has two sides perpendicular to one another:
  //      - for each side, look at whether there is an attached seat
  //        perpendicular to the given side and traverse attachments until a
  //        closed end (an attached side) or an unattached seat side is reached
  //      - If a closed end is reached for only one of the two sides, that side
  //        is the back
  //      - Otherwise look at the number of seats that were found when
  //        traversing attachments, the side with the higher corresponding
  //        number of seats is the back
  // 5. If no distinction could be made in the previous step, or the seat has
  //    two sides parallel to one another, or the seat has four sides (not a
  //    realistic scenario):
  //      - any of the sides can be the back with preference given to non-deep
  //        sides

  const { seats, sides } = sortSeatsAttachments(seatItemId);

  if (!sides) return 'none';
  if (sides.length === 1) return sides[0].thisConnector.seatSide;
  if (sides.length === 3) {
    const occupiedSides = sides.map(
      ({ thisConnector }) => thisConnector.seatSide
    );
    const sidelessSide = PREFERRED_SEAT_SIDES.find(
      (val) => !occupiedSides.includes(val)
    );
    return getOppositeSeatSide(sidelessSide);
  }
  if (sides.length === 2) {
    const { seatSide: seatSideA } = sides[0].thisConnector;
    const { seatSide: seatSideB } = sides[1].thisConnector;

    // Sides are attached parallel to one another
    if (seatSideA === getOppositeSeatSide(seatSideB)) return seatSideA;

    const perpendicularSeatA =
      seats &&
      seats.find(
        (seat) => seat.thisConnector.seatSide !== getOppositeSeatSide(seatSideA)
      );
    const perpendicularSeatB =
      seats &&
      seats.find(
        (seat) => seat.thisConnector.seatSide !== getOppositeSeatSide(seatSideB)
      );

    if (perpendicularSeatA && perpendicularSeatB) {
      const rowDataA = getRowData(perpendicularSeatA.otherConnector);
      const rowDataB = getRowData(perpendicularSeatB.otherConnector);

      if (rowDataA.closedEnd && !rowDataB.closedEnd) return seatSideA;
      if (!rowDataA.closedEnd && rowDataB.closedEnd) return seatSideB;
      if (rowDataA.numSeats > rowDataB.numSeats) return seatSideA;
      if (rowDataA.numSeats < rowDataB.numSeats) return seatSideB;
    } else if (perpendicularSeatA) {
      return seatSideA;
    } else if (perpendicularSeatB) {
      return seatSideB;
    }
    if (seatSideA === 'front' || seatSideA === 'back') return seatSideA;
    return seatSideB;
  }

  // Seat has four sides
  const nonDeep = sides.filter(
    ({ thisConnector }) =>
      thisConnector.seatSide === 'front' || thisConnector.seatSide === 'back'
  );
  if (nonDeep.length) return nonDeep[0].thisConnector.seatSide;
  return sides[0].thisConnector.seatSide;
}

// Sort attachments for a given item by type and return in the form:
//  {<type>s: [{thisConnector, otherConnector}, ...]...}
//    - thisConnector is the connector for the given item
//    - otherConnector is the other connector involved in the attachment
export function sortSeatsAttachments(itemId) {
  return getAttachmentsForItem(itemId).reduce((acc, attachmentId) => {
    const { thisConnector, otherConnector } = attachmentConnectorsByItem(
      attachmentId,
      itemId
    );
    const { type } = getItem(otherConnector.owner);

    if (!acc[`${type}s`]) acc[`${type}s`] = [];
    acc[`${type}s`].push({ thisConnector, otherConnector });
    return acc;
  }, {});
}

// Return an attachment in the form: {thisConnector, otherConnector}
//  - thisConnector is the connector for the given item
//  - otherConnector is the other connector involved in the attachment
function attachmentConnectorsByItem(attachmentId, itemId) {
  const { itemA, itemB } = getAttachment(attachmentId);
  let thisItem = itemA;
  let otherItem = itemB;
  if (thisItem.itemId !== itemId) {
    thisItem = itemB;
    otherItem = itemA;
  }

  const thisConnector = getNamedConnectorForItem(
    itemId,
    thisItem.connectorName
  );
  const otherConnector = getNamedConnectorForItem(
    otherItem.itemId,
    otherItem.connectorName
  );

  return { thisConnector, otherConnector };
}

export function getOppositeSeatSide(seatSide) {
  return OPPOSITE_SEAT_SIDES[seatSide];
}

// Returns the id of the corner seat opposite a given connector (if one exists)
function getCornerSeat(seatConnector) {
  const allItems = traverseSeatAttachments(seatConnector);
  return getItem(allItems.pop()).type === 'side' && allItems.pop();
}

// Traverses seats attached in parallel opposite the given connector and returns
// the number of attached seats and a boolean indicating whether the end of the
// row is closed (an end is closed if there is an attached side)
function getRowData(seatConnector) {
  const items = traverseSeatAttachments(seatConnector);

  return getItem(items.pop()).type === 'side'
    ? { numSeats: items.length, closedEnd: true }
    : { numSeats: items.length + 1, closedEnd: false };
}

// Traverses seats attached in parallel opposite the given connector until an
// attached side or a seat side without an attachment is reached
function traverseSeatAttachments({ owner, seatSide }, itemIds = []) {
  // The unlikely scenario where seat configuration forms a closed loop
  if (itemIds[0] === owner) return itemIds;

  itemIds.push(owner);
  if (!seatSide) return itemIds;

  const oppositeSeatSide = getOppositeSeatSide(seatSide);

  let attachedConnector;
  getAttachmentsForItem(owner).some((attachmentId) => {
    const { thisConnector, otherConnector } = attachmentConnectorsByItem(
      attachmentId,
      owner
    );
    if (!thisConnector.name.includes(oppositeSeatSide)) return false;
    attachedConnector = otherConnector;
    return true;
  });
  if (!attachedConnector) return itemIds;
  return traverseSeatAttachments(attachedConnector, itemIds);
}

export function hasBackPillow(seatItemId) {
  return (
    backPillowSupport[seatItemId] &&
    Object.values(backPillowSupport[seatItemId]).some((val) => val)
  );
}

async function addBackPillowSupport(seatItemId, attachmentId, seatSide) {
  if (!backPillowSupport[seatItemId]) {
    backPillowSupport[seatItemId] = {};
  }
  const hasPillowBefore = hasBackPillow(seatItemId);
  backPillowSupport[seatItemId][seatSide] = attachmentId;
  const hasPillowAfter = hasBackPillow(seatItemId);

  if (!hasPillowBefore && hasPillowAfter) backPillowAdded();

  await chooseBackPillowSide(seatItemId);
}

async function removeBackPillowSupport(seatItemId, seatSide) {
  const hasPillowBefore = hasBackPillow(seatItemId);
  backPillowSupport[seatItemId][seatSide] = null;
  const hasPillowAfter = hasBackPillow(seatItemId);

  if (hasPillowBefore && !hasPillowAfter) backPillowRemoved();

  await chooseBackPillowSide(seatItemId);
}

/**
 * Remove all attachments between the provided item and other items. The item
 * will maintain its existing transform, but will no longer be semantically
 * attached to other items.
 * @param {*} itemId
 */
export function detachAll(itemId, includeAccessories = true) {
  let itemAttachments = getAttachmentsForItem(itemId);
  if (!includeAccessories) {
    itemAttachments = itemAttachments.filter((attachmentId) => {
      const { itemB } = getAttachment(attachmentId);
      const { type } = getNamedConnectorForItem(
        itemB.itemId,
        itemB.connectorName
      );
      return !(type.includes('AccessorySlot') && itemB.itemId === itemId);
    });
  }
  return Promise.all(itemAttachments.map(detach));
}

async function detach(attachmentId) {
  const { itemA, itemB } = getAttachment(attachmentId);

  const srcItemData = getItem(itemA.itemId);
  const targetItemData = getItem(itemB.itemId);

  const connectorA = getNamedConnectorForItem(
    itemA.itemId,
    itemA.connectorName
  );
  const connectorB = getNamedConnectorForItem(
    itemB.itemId,
    itemB.connectorName
  );

  let seatConnector;

  // Get corner seats opposite the broken attachment
  const cornerSeats = [];
  if (srcItemData.type === 'seat' && targetItemData.type === 'seat') {
    const cornerSeatA = getCornerSeat(connectorA);
    if (cornerSeatA) cornerSeats.push(cornerSeatA);

    const cornerSeatB = getCornerSeat(connectorB);
    if (cornerSeatB) cornerSeats.push(cornerSeatB);
  } else if (srcItemData.type === 'seat' && targetItemData.type === 'side') {
    seatConnector = connectorA;
  } else if (srcItemData.type === 'side' && targetItemData.type === 'seat') {
    seatConnector = connectorB;
  }

  if (seatConnector) {
    const cornerSeat = getCornerSeat(seatConnector);
    if (cornerSeat && cornerSeat !== seatConnector.owner)
      cornerSeats.push(cornerSeat);
  }

  // 1) remove indexing by item
  attachments.byItem[itemA.itemId] = attachments.byItem[itemA.itemId].filter(
    (id) => id !== attachmentId
  );
  if (!attachments.byItem[itemA.itemId].length)
    delete attachments.byItem[itemA.itemId];

  attachments.byItem[itemB.itemId] = attachments.byItem[itemB.itemId].filter(
    (id) => id !== attachmentId
  );
  if (!attachments.byItem[itemB.itemId].length)
    delete attachments.byItem[itemB.itemId];

  // 2) remove indexing by connector
  const { id: connectorAId } = connectorA;
  attachments.byConnector[connectorAId] = attachments.byConnector[
    connectorAId
  ].filter((id) => id !== attachmentId);
  if (!attachments.byConnector[connectorAId].length)
    delete attachments.byConnector[connectorAId];

  const { id: connectorBId } = connectorB;

  setConnectorOccupied(connectorAId, false);
  setConnectorOccupied(connectorBId, false);

  attachments.byConnector[connectorBId] = attachments.byConnector[
    connectorBId
  ].filter((id) => id !== attachmentId);
  if (!attachments.byConnector[connectorBId].length)
    delete attachments.byConnector[connectorBId];

  // finally, delete primary attachment data
  delete attachments.byId[attachmentId];

  if (getConnectorData(connectorAId).type.includes('Accessory'))
    await reparentAccessory(itemB.itemId, itemA.itemId, false);

  // **** Handle back cushion ****
  // If one of the items is a seat, and the other is a side, the seat's back
  // pillow visualization may be affected by the removed side.
  //
  // If one of the items is a seat and the other is a side or both items are
  // seats, the back pillow visualization of corner seats that were attached in
  // parallel to the broken attachment may be affected
  if (seatConnector) {
    const { seatSide } = seatConnector;

    return Promise.all([
      removeBackPillowSupport(seatConnector.owner, seatSide),
      ...cornerSeats.map(chooseBackPillowSide),
    ]);
  }

  if (srcItemData.type === 'seat' && targetItemData.type === 'table') {
    await setConfigOnModel(srcItemData.id, { TableOn: false });
  } else if (srcItemData.type === 'table' && targetItemData.type === 'seat') {
    await setConfigOnModel(targetItemData.id, { TableOn: false });
  }

  return Promise.all(cornerSeats.map(chooseBackPillowSide));
}

export async function reparentAccessory(itemId, accessoryId, attachToItem) {
  const { api } = window.threekit;
  const { Matrix4 } = api.THREE;

  const childTransform = getWorldTransform(accessoryId);
  const newParentId = attachToItem
    ? itemId
    : api.scene.findNode({ id: itemId, parent: true });

  const newParentTransform = getWorldTransform(newParentId);
  if (newParentTransform) {
    const newParentInverseTransform = new Matrix4().getInverse(
      newParentTransform
    );
    childTransform.premultiply(newParentInverseTransform);
  }

  api.scene.reparent(newParentId, [accessoryId]);

  const { translation, rotation, scale } = decomposeForTransformPlug(
    childTransform
  );
  api.scene.set(
    { id: accessoryId, plug: 'Transform', property: 'translation' },
    translation
  );
  api.scene.set(
    { id: accessoryId, plug: 'Transform', property: 'rotation' },
    rotation
  );
  api.scene.set(
    { id: accessoryId, plug: 'Transform', property: 'scale' },
    scale
  );
  await api.player.evaluateSceneGraph();
}

export function getConnectionAngle(connectorAId, connectorBId) {
  const { api } = window.threekit;
  const { Vector3, Math } = api.THREE;

  const getZAxis = (connectorId) => {
    const zAxis = new Vector3();
    getWorldTransform(connectorId).extractBasis(
      new Vector3(),
      new Vector3(),
      zAxis
    );
    return zAxis;
  };

  const connectorAZ = getZAxis(connectorAId);
  const connectorBZ = getZAxis(connectorBId);

  return 180 - connectorAZ.angleTo(connectorBZ) * Math.RAD2DEG;
}

// for an input map of form: { [srcConnectorId]:[...targetConnectorIds] }
// return an array of src/connector pairs sorted by distance
export function getSortedDistancePairs(connectorMap) {
  const connectorPairs = Object.entries(connectorMap).reduce(
    (array, [src, targets]) => {
      const pairs = targets.map((target) => {
        return {
          src,
          target,
          distance: getConnectorDistance(src, target),
        };
      });
      return [...array, ...pairs];
    },
    []
  );

  connectorPairs.sort((pairA, pairB) => pairA.distance - pairB.distance);

  return connectorPairs;
}

export function getNamedConnectorForItem(itemId, connectorName) {
  const itemConnectors = getConnectorsForItem(itemId);
  return itemConnectors
    .map(getConnectorData)
    .find(({ name }) => name === connectorName);
}

// Attach connector pairs if they are within the ATTACHMENT_DISTANCE_THRESHOLD
// and ATTACHMENT_ANGLE_THRESHOLD. We pass false to attach so these are only
// semantically attached - no additional transform changes made.
export async function makeValidAttachments(connectorMap) {
  const connectorPairs = getSortedDistancePairs(connectorMap).filter(
    ({ src, target, distance }) => {
      return (
        distance < ATTACH_DISTANCE_THRESHOLD &&
        getConnectionAngle(src, target) <= ATTACH_ANGLE_THRESHOLD
      );
    }
  );
  await Promise.all(
    connectorPairs.map(({ src, target }) => attach(src, target, false))
  );
}

export function getConnectorMap(itemIds) {
  let srcConnectors = [];
  const connectorMap = {}; // src to targets

  for (const id of itemIds) {
    const itemSrcConnectors = getConnectorsForItem(id);
    srcConnectors = srcConnectors.concat(itemSrcConnectors);
  }

  const unselectedItems = Array.from(getItems().keys()).filter(
    (id) => !itemIds.includes(id)
  );

  if (unselectedItems.length) {
    srcConnectors.forEach((connectorId) => {
      unselectedItems.forEach((itemId) => {
        const targets = getTargetConnectors(connectorId, itemId);
        if (targets.length) {
          if (!connectorMap[connectorId]) connectorMap[connectorId] = [];
          connectorMap[connectorId] = connectorMap[connectorId].concat(targets);
        }
      });
    });
  }

  return connectorMap;
}

export function isUnattachedAccessory(itemId) {
  return isAccessory(itemId) && !itemHasAttachements(itemId);
}

function itemHasAttachements(itemId) {
  return getAttachmentsForItem(itemId).length !== 0;
}

export function getAttachment(attachmentId) {
  return attachments.byId[attachmentId];
}

export function getAttachmentsForItem(itemId) {
  return attachments.byItem[itemId] || [];
}

export function getAttachments() {
  return attachments.byId;
}

export function getAttachmentsForConnector(connectorId) {
  return attachments.byConnector[connectorId] || [];
}

export function getConnectedItemOfSeatForSide(seatId, side) {
  const seatConnectors = getConnectorsForItem(seatId);
  if (seatConnectors && getItem(seatId).type === 'seat') {
    const { owner } = seatConnectors.find(
      (connector) => connector.seatSide === side
    );
    if (owner) return getItem(owner);
  }
}
// attachment pair check optimizations:
// frustum cull target attachments
// frustum cull source attachments?
// bounding sphere distance discard pairs

window.attachments = attachments;
window.connectors = connectors;
