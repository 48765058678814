import Side from './Side';

export default class DoubleSidedSide extends Side {
  constructor(threekitApi, options = {}) {
    super(threekitApi, options);
    this._key = 'doubleSided';
    this._keyAlians = 'standard';

    this._initAlignmentWithConnector(options.connector);
  }

  _initAlignmentWithConnector = (connector) => {
    this.bottom = null;
    this.bottomAlign = null;
    this.top = null;
    this.topAlign = null;
    this.island = null;

    if (connector) {
      this._connectTo(connector);
    }
  };
}
