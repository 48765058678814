// import { fabricMap } from './fabrics';
import { setConfigOnModel, getConfigOnModel } from '../../helpers';

async function applyConfiguration(item) {
  // const { id, type } = item;
  // const { fabric } = item.configuration;
  // if (fabric) {
  //   const { soft, hard, trim } = fabric;
  //   const threekitConfig = {};
  //   if (fabric.hasOwnProperty('soft'))
  //     threekitConfig.Soft = { assetId: fabricMap[soft] };
  //   if (fabric.hasOwnProperty('hard'))
  //     threekitConfig.Hard = { assetId: fabricMap[hard] };
  //   if (fabric.hasOwnProperty('trim')) {
  //     if (trim) threekitConfig.Trim = { assetId: fabricMap[trim] };
  //     else if (type === 'seat') {
  //       if (fabric.hasOwnProperty('soft'))
  //         threekitConfig.Trim = { assetId: fabricMap[soft] };
  //     } else if (type === 'side') {
  //       if (fabric.hasOwnProperty('hard'))
  //         threekitConfig.Trim = { assetId: fabricMap[hard] };
  //     }
  //   }
  //   return setConfigOnModel(id, threekitConfig);
  // }
}

export function setSeatBackPillow(seatItemId, backPillowValue) {
  return setConfigOnModel(seatItemId, { BackPillow: backPillowValue });
}

export async function getSeatBackPillow(seatItemId) {
  const config = await getConfigOnModel(seatItemId);
  return config && config.BackPillow;
}

export const sactionalItems = {
  seat: {
    standard: {
      assetName: 'Standard Seat',
      applyConfiguration,
    },
    storage: {
      assetName: 'Storage Seat',
      applyConfiguration,
    },
    wedge: {
      assetName: 'Wedge Seat',
      applyConfiguration,
    },
    standard_outdoor: {
      assetName: 'Outdoor Standard Seat',
      applyConfiguration,
    },
  },
  side: {
    standard: {
      assetName: 'Standard Side',
      applyConfiguration,
    },
    rollArm: {
      assetName: 'Roll Arm Side',
      applyConfiguration,
    },
    deep: {
      assetName: 'Deep Side',
      applyConfiguration,
    },
    standard_outdoor: {
      assetName: 'Outdoor Standard Side',
      applyConfiguration: async () => {},
    },
    deep_outdoor: {
      assetName: 'Outdoor Deep Side',
      applyConfiguration: async () => {},
    },
  },
  table: {
    hickory: {},
    greyAsh: {},
    darkWalnut: {},
  },
  coaster: {
    hickory: { assetName: 'Hickory Coaster' },
    greyAsh: { assetName: 'Grey Ash Coaster' },
    darkWalnut: { assetName: 'Dark Walnut Coaster' },
  },
  drinkHolder: {
    hickory: { assetName: 'Hickory Drink Holder' },
    greyAsh: { assetName: 'Grey Ash Drink Holder' },
    darkWalnut: { assetName: 'Dark Walnut Drink Holder' },
  },
  rollArmDrinkHolder: {
    hickory: { assetName: 'Hickory Roll Arm Drink Holder' },
    greyAsh: { assetName: 'Grey Ash Roll Arm Drink Holder' },
    darkWalnut: { assetName: 'Dark Walnut Roll Arm Drink Holder' },
  },
};
