import { getMeshesForItems } from '../items';

export const frameNodes = (nodeIds) =>
  window.threekit.api.player.cameraController.frameBoundingSphere(nodeIds);

export const frameScene = async (itemIds) => {
  const filteredNodeIds = await getMeshesForItems(itemIds);
  if (filteredNodeIds.length) frameNodes(filteredNodeIds);
};

export async function rotateCamera(degrees = 45) {
  const { api } = window.threekit;

  // The following calculation is required to obtain the correct input for
  // camera.orbit
  const orbitSensitivity = api.scene.get({
    id: api.player.cameraController.activeCamera,
    plug: 'Camera',
    property: 'orbitSensitivity',
  });
  const rect = api.player.getRect();
  const increment =
    (degrees * Math.min(rect.width, rect.height)) / (orbitSensitivity * 180);

  return api.camera.orbit(increment, 0);
}

export const getCameraId = (cameraName) => {
  const { api } = window.threekit;
  return api.player
    .getAssetInstance([api.instanceId, 'plugs', 'Proxy', 0, 'asset'])
    .then((proxyId) => {
      const camera = Object.values(api.player.sceneGraph.nodes)
        .filter(({ name }) => name === cameraName)
        .find(
          ({ parent }) => api.player.sceneGraph.nodes[parent].parent === proxyId
        );

      return camera && camera.id;
    });
};

// This function uses low-level camera controller functionality to accomplish
// pointing the camera at at new autopositioned item. Using
// cameraController.lookAtBoundingSphere would normally accomplish our needs,
// but it uses a bounding sphere (even though in the end it only needs the
// sphere centre) which requires mesh visibility to calculate. In our case, we
// keep the item hidden until it is properly placed.
// So, the following is a modification of lookAtBoundingSphere's code which uses
// the known position directly (no need for bounding sphere).
export function pointCameraAtPosition(pos) {
  const { api } = window.threekit;
  const relativePos = pos
    .clone()
    .sub(
      api.player.cameraController.controls.getCameraPosition(
        new api.THREE.Vector3()
      )
    );
  const direction = relativePos.normalize();

  api.player.cameraController.controls.setCameraDirection(direction);

  api.player.cameraController.needsUpdate({ cameraDataChanged: true });
}
