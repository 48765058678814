import { updateMeasurementColor, initFloor } from '../measurement';

const FABRIC_CODE_METADATA_KEY = 'Internal ID (Fabric ID from Item table)';

const ATTRIBUTE_METADATA_KEYS = {
  floor: 'floorType',
  Fabric: FABRIC_CODE_METADATA_KEY,
  Soft: FABRIC_CODE_METADATA_KEY,
  Hard: FABRIC_CODE_METADATA_KEY,
  Trim: FABRIC_CODE_METADATA_KEY,
};

export const DEFAULT_SCENE_STATE = {
  floor: '',
  Fabric: '760', // 760 = Charcoal Grey Corded Velvet
  Soft: '760',
  Hard: '760',
  Trim: null,
};

const state = { ...DEFAULT_SCENE_STATE };

export function setSceneState(newState) {
  Object.assign(state, newState);
}

const FLOOR_TYPES = [
  'Espresso',
  'Chestnut',
  'SaddleBrown',
  'CoastalGrey',
  'WhiteOak',
];

export function buildTKSceneConfiguration(initialConfigurationData) {
  const config = {};

  const { floor, items } = initialConfigurationData;

  config.floor = floor;

  initFloor(floor);

  const fabricItem = items.find(
    (item) => item.configuration && item.configuration.hasOwnProperty('fabric')
  );
  if (fabricItem)
    Object.assign(
      config,
      baFabricToSceneConfig(fabricItem.configuration.fabric)
    );

  return config;
}

export async function setFloor(floorName) {
  if (floorName && !FLOOR_TYPES.includes(floorName)) {
    throw new Error(`Unsupported flooring '${floorName}'`);
  }
  updateMeasurementColor(floorName);

  await setSceneConfiguration({ floor: floorName });

  // Some models - such as sacs - come with shadow planes under them, for shadow visuals when no floor/ssao exists.
  // TODO: store prev floor value, only toggle floorshadows if floor switches between existent and non-existent
  // for (const itemId of configuratorState.items.keys()) {
  //   setConfigOnModel(itemId, { useShadowPlane: floorName ? 'no' : 'yes' }); // catalog item attr's do not support boolean yet, so we use string
  // }
}

export function getFloor() {
  return state.floor;
}

export function mapSceneConfigurationToTKConfiguration(config) {
  const assetQueryConfig = Object.keys(config).reduce((acc, attrName) => {
    const metadataKey = ATTRIBUTE_METADATA_KEYS[attrName];
    const value = config[attrName];
    if (metadataKey) {
      // if intentionally empty asset ref, only way to force propagation is to set it as empty string
      if (
        value === null ||
        value === undefined ||
        value === '' ||
        (typeof value === 'object' && !value.assetId && !value.query)
      )
        acc[attrName] = '';
      else {
        acc[attrName] = {
          query: { metadata: { [metadataKey]: value } },
        };
      }
    } else acc[attrName] = value;
    return acc;
  }, {});

  return assetQueryConfig;
}

export function baFabricToSceneConfig(baFabric) {
  if (typeof baFabric === 'string') return { Fabric: baFabric };
  if (typeof baFabric === 'object') {
    const globalFabrics = {};
    if (baFabric.hasOwnProperty('soft')) globalFabrics.Soft = baFabric.soft;
    if (baFabric.hasOwnProperty('hard')) globalFabrics.Hard = baFabric.hard;
    if (baFabric.hasOwnProperty('trim')) globalFabrics.Trim = baFabric.trim;
    return globalFabrics;
  }
  return {};
}

export async function setSceneConfiguration(config) {
  const changedAttrs = Object.keys(config).reduce((acc, attrName) => {
    if (config[attrName] !== state[attrName]) acc[attrName] = config[attrName];
    return acc;
  }, {});

  if (!Object.keys(changedAttrs).length) return;

  Object.assign(state, changedAttrs);

  const tkConfigFormat = mapSceneConfigurationToTKConfiguration(changedAttrs);

  await window.threekit.api.player
    .getConfigurator()
    .setConfiguration(tkConfigFormat);
}
