/* global __THREEKIT_AUTH_TOKEN__,  __THREEKIT_HOST__ */

const b64toBlob = (dataURI, mimeType) => {
  const byteString = atob(dataURI.split(',')[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeType });
};

const postThumbnail = async (dataUrl, mimeType) => {
  const url = `${__THREEKIT_HOST__}/api/files?bearer_token=${__THREEKIT_AUTH_TOKEN__}`;
  const blob = b64toBlob(dataUrl, mimeType);
  // const blob = await fetch(dataUrl).then((res) => res.blob());
  const postfix = mimeType.split('/')[1];
  const formData = new FormData();
  const filename = `lovesac-${new Date().toISOString()}.${postfix}`;
  formData.append('files', new File([blob], filename));
  formData.append('filename', filename);

  const domain = __THREEKIT_HOST__.replace('https://', '');

  return fetch(url, {
    method: 'post',
    body: formData,
    accept: 'application/json',
    'content-type': 'multipart/form-data',
  })
    .then((res) => res.json())
    .then((json) => {
      const { id } = json.files[0];
      return `${__THREEKIT_HOST__}/api/images/webp/filters:format(webp):quality(80)/${domain}/api/files/${id}/content`;
      // return `${__THREEKIT_HOST__}/api/files/${id}/content`;
    });
};

export async function getThumbnail(width, height, mimeType = 'image/png') {
  const size = width && height ? { width, height } : undefined;
  const blob = await window.threekit.api.snapshotAsync({
    size,
    mimeType,
  });

  return postThumbnail(blob, mimeType);
}
