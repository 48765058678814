export default (prevId, prevKey, newId, newKey) => {
  window.dispatchEvent(
    new CustomEvent('convertSide', {
      detail: {
        prevSideId: prevId,
        prevSideType: prevKey,
        newSideId: newId,
        newSideType: newKey,
      },
    })
  );
};
