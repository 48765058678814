export const SEAT_ASSET_ID = '';
export const SIDE_KEYWORD = ['left', 'top', 'right', 'bottom'];
export const VERTEX_KEYWORD = [
  'topLeft',
  'topRight',
  'bottomRight',
  'bottomLeft',
];
export const SIDE_MAP = {
  left: 'right',
  right: 'left',
  top: 'bottom',
  bottom: 'top',
};
export const SIDE_VERTICES_MAP = {
  left: ['bottomLeft', 'topLeft'],
  top: ['topLeft', 'topRight'],
  right: ['topRight', 'bottomRight'],
  bottom: ['bottomRight', 'bottomLeft'],
};
export const SIDE_DIMENSION_MAP = {
  left: 'height',
  right: 'height',
  top: 'width',
  bottom: 'width',
};
export const ALIGNMENT_MAP = {
  top: 'bottom',
  bottom: 'top',
};
export const SEAT_WIDTH = 0.8865;
export const SEAT_HEIGHT = 0.734;
export const SIDE_WIDTH = 0.8865;
export const SIDE_HEIGHT = 0.1525;
export const FLOAT_POINT_ADJUST = 0.01;
export const PLUS_SIGN_DISTANCE = 0.175;
export const ATTACH_THRESHOLD = 0.3;
// export const DRAG_UNIT_DISTANCE = 1;
export const DRAG_UNIT_DISTANCE = 0.01;

export const SELECTION_COLORS = {
  VALID: '#56585A',
  INVALID: '#6C4E4E',
};

// Sides in array placed clockwisely
export const ALL_SEAT_SIDES = ['bottom', 'left', 'top', 'right'];

export const OPPOSITE_SEAT_SIDES = {
  left: 'right',
  right: 'left',
  top: 'bottom',
  bottom: 'top',
};

export const NEXT_SEAT_SIDES = {
  bottom: 'left',
  top: 'right',
  left: 'top',
  right: 'bottom',
};

export const PREVIOUS_SEAT_SIDES = {
  bottom: 'right',
  top: 'left',
  left: 'bottom',
  right: 'top',
};

export const SIDE_TYPE_KEYS = {
  Standard: 'standard',
  Rollarm: 'rollArm',
  Angled: 'angled',
};

export const DEEP_SIDE_KEYS = {
  standard: 'deep',
  doubleSided: 'deep',
  angled: 'deepAngled',
};

export const CONFIGURATOR_VERSION = 3;

export const PILLOW_CONFIG_MAP = {
  top: 'back',
  bottom: 'front',
  left: 'left',
  right: 'right',
};

export const SIDE_CONFIG_MAP = {
  back: 'top',
  front: 'bottom',
  left: 'left',
  right: 'right',
};
