import {
  ALL_SEAT_SIDES,
  OPPOSITE_SEAT_SIDES,
  NEXT_SEAT_SIDES,
  PREVIOUS_SEAT_SIDES,
} from '../../constants';
import { status } from '../../../status';

/**
 *
 * @param {string} itemId uuid of the item for which we're toggling the speaker icon
 * @param {bool} iconEnabled Boolean value indicating whether the speaker icon(s) for this item should be shown or hidden
 * @param {string} showTopSpeakers left/right - where top speakers show reside
 * @param {string} showSideSpeakers left/right/none - where / whether side speakers should show
 */
export async function toggleSpeakerIcon(
  itemId,
  iconEnabled,
  showTopSpeakers = 'left',
  showSideSpeakers = 'none'
) {
  const config = await status.api3D.api.player.getConfiguratorInstance({
    id: itemId,
    plug: 'Null',
    property: 'asset',
  });
  if (!config) {
    console.error(
      `Could not show speaker for itemId '${itemId}'. Configurator instance not found.`
    );
    return;
  }

  await config.setConfiguration({
    ShowStealthTech: !!iconEnabled,
    showTopSpeakers,
    showSideSpeakers,
  });
}

export const ERROR_CODES = {
  NO_END_STANDARD_SIDES: 'err_no_end_std_sides', // No/not enough std sides found where core speakers should reside
  WEDGE_SEAT_INELIGIBLE: 'err_wedge_seat_ineligible', // Wedge seat found for 1 seat layout
  MUST_HAVE_STANDARD_SEAT: 'err_no_std_seat', // No std seat exist
  UNKNOWN_LAYOUT: 'err_unknown_layout',
  ARMS_DONT_MATCH: 'err_arms_dont_match',
};

export const CORE_PACKAGE_BASE_SPEAKERS_NUM = 6;
const STANDARD_BUNDLE_SKU_KEY = 'SpeakerSactionalStealthBundle';
const WITH_ANGLED_BUNDLE_SKU_KEY = '-Speaker-Angled-';

const getAvailableBundles = (set) => {
  const { stealthTech, sides } = set;
  const { bundle } = stealthTech;
  const { satellites, speakers, layout } = bundle;
  const availableBundles = [];
  // 1 check core speakers types - angled / standard
  const coreType = speakers[0].type;
  if (coreType === 'standard' || coreType === 'doubleSided') {
    availableBundles.push({
      type: 'stealthTech',
      key: `${CORE_PACKAGE_BASE_SPEAKERS_NUM}${STANDARD_BUNDLE_SKU_KEY}`,
    });
  } else if (coreType === 'angled') {
    availableBundles.push({
      type: 'stealthTech',
      key: `${CORE_PACKAGE_BASE_SPEAKERS_NUM}${WITH_ANGLED_BUNDLE_SKU_KEY}ALL`,
    });
  }

  // 2 check satellites types
  if (satellites.length) {
    const satellitesTypes = satellites.reduce((acc, item) => {
      const { type } = item;
      if (!acc[type]) {
        acc[type] = 1;
      } else {
        acc[type]++;
      }
      return acc;
    }, {});
    // for each type add bundles
    Object.entries(satellitesTypes).forEach(([type, count]) => {
      if (type === 'standard') {
        if (coreType === 'standard') {
          for (let i = 0; i < count; i++) {
            availableBundles.push({
              type: 'stealthTech',
              key: `${
                CORE_PACKAGE_BASE_SPEAKERS_NUM + i + 1
              }${STANDARD_BUNDLE_SKU_KEY}`,
            });
          }
        } else if (coreType === 'angled') {
          for (let i = 0; i < count; i++) {
            availableBundles.push({
              type: 'stealthTech',
              key: `${
                CORE_PACKAGE_BASE_SPEAKERS_NUM + i + 1
              }${WITH_ANGLED_BUNDLE_SKU_KEY}ARM`,
            });
          }
        }
      } else if (type === 'angled') {
        if (coreType === 'standard') {
          for (let i = 0; i < count; i++) {
            availableBundles.push({
              type: 'stealthTech',
              key: `${
                CORE_PACKAGE_BASE_SPEAKERS_NUM + i + 1
              }${WITH_ANGLED_BUNDLE_SKU_KEY}BACK`,
            });
          }
        } else if (coreType === 'angled') {
          for (let i = 0; i < count; i++) {
            availableBundles.push({
              type: 'stealthTech',
              key: `${
                CORE_PACKAGE_BASE_SPEAKERS_NUM + i + 1
              }${WITH_ANGLED_BUNDLE_SKU_KEY}ALL`,
            });
          }
        }
      }
    });
  }

  if (layout === 'U' || layout === 'I' || layout === 'M') {
    const find7 = availableBundles.findIndex((e) => e.key.charAt(0) === '7');
    if (find7 !== -1) availableBundles.splice(find7, 1);

    const find9 = availableBundles.findIndex((e) => e.key.charAt(0) === '9');
    if (find9 !== -1) availableBundles.splice(find9, 1);
  }
  console.log('availableBundles', availableBundles);
  return availableBundles;
};

const SPEAKER_POSITIONS = ['left', 'right'];
// * There is an implication in code that speakers have an order in
// the array - left arm then right arm of the sofa

// Current state of stealthTech bundle
const stealthTechState = {
  type: null, // core or optimal
  showIndicators: false,
  maxSubwoofers: 0,
  numOfSubwoofers: 1,
  //
  subwoofers: [],
  cores: [],
  satellites: [],
  layout: 'I', // I, L, U and M
  items: [],
  bundle: {},
};

const state = {};

function setStealthTech(options) {
  console.log('Set StealthTech', options);
  if (!options) return;
  const { type, key, showIndicators } = options;
  const newState = {};
  if (key !== undefined) {
    const numberOfSpeakers = Number.parseInt(key?.match(/^\d*/)[0]);
    const bundleType =
      numberOfSpeakers > CORE_PACKAGE_BASE_SPEAKERS_NUM
        ? 'optimal'
        : numberOfSpeakers > 0
        ? 'core'
        : null;
    newState.type = bundleType;
    newState.numberOfSpeakers = numberOfSpeakers;
  } else {
    newState.type = null;
  }
  if (showIndicators !== undefined) newState.showIndicators = showIndicators;

  updateStealthTechState(newState);
  // console.log('Change stealth tech to', key, stealthTechState);
}

const getSortedSubwoofers = (bundle) => {
  const { numOfSubwoofers, sets } = stealthTechState;
  const { subwoofer, layout } = bundle;
  if (!layout) return [];
  if (numOfSubwoofers < 2) {
    return [{ id: subwoofer }];
  } else {
    const getSubwooferItemsFromSet = (set, num) => {
      const { seats: allSeats, paths, stealthTech } = set;
      const seats = Array.from(allSeats.values()).reduce((acc, seat) => {
        if (seat._key !== 'wedge') {
          acc[seat._id] = seat;
        }
        return acc;
      }, {});
      let path = stealthTech?.bundle?.path || paths[0];
      if (!path) {
        // No path. Probably all ottomans. We might want to start with any seat that has
        // most open spaces and go clockwise. For now just sort them by index
        path = Object.values(seats)
          .sort((a, b) => a._index - b._index)
          .map((seat) => {
            return { id: seat._id, item: seat };
          });
      } else {
        path = path.filter((node) => node.item._key !== 'wedge');
      }
      if (num <= path.length) {
        const step = Math.floor(path.length / num);
        let remainder = path.length % num;
        const subwoofers = [];

        const offset = Math.floor(step / 2);
        let index = offset;
        for (let i = 0; i < num; i++) {
          subwoofers.push({ id: path[index].id });
          index += step;
          if (remainder > 0) {
            index++;
            remainder--;
          }
        }

        return subwoofers;
      } else {
        const processed = {};
        const subwoofers = path.map((node) => {
          processed[node.id] = node;
          return { id: node.id };
        });
        // Need to handle ottoman's subwoofers
        const remainderSubwoofers = num - subwoofers.length;

        // For now, start from main items in the path, check their ottomans
        const array = path
          .map((node) => {
            const { item } = node;
            const findAttachedOttomanSide = ALL_SEAT_SIDES.find(
              (side) =>
                // item has a connection & connection is not processed & connection is a seat
                item[side] &&
                !processed[item[side]._id] &&
                seats[item[side]._id]
            );
            return item[findAttachedOttomanSide];
          })
          .filter((e) => !!e);

        // For now no extra logic for other ottomans
        if (remainderSubwoofers > array.length) {
          array.forEach((item) => {
            subwoofers.push({ id: item._id });
            processed[item._id] = item;
          });
          Object.values(seats)
            // filter other seats not processed yet
            .filter((seat) => !processed[seat._id])
            .slice(0, remainderSubwoofers - array.length)
            .forEach((seat) => subwoofers.push({ id: seat._id }));
        } else {
          const step = Math.floor(array.length / remainderSubwoofers);
          let remainder = array.length % remainderSubwoofers;

          const offset = Math.floor(step / 2);
          let index = offset;
          for (let i = 0; i < remainderSubwoofers; i++) {
            const item = array[index];
            subwoofers.push({ id: item._id });
            processed[item._id] = item;
            index += step;
            if (remainder > 0) {
              index++;
              remainder--;
            }
          }
        }

        return subwoofers;
      }
    };

    const mainSetIndex = sets.findIndex((set) => set.seats.has(subwoofer));
    const mainSet = sets[mainSetIndex];

    const subwoofers = getSubwooferItemsFromSet(mainSet, numOfSubwoofers);

    if (numOfSubwoofers > subwoofers.length) {
      const remainingSets = sets
        .slice(0, mainSetIndex)
        .concat(sets.slice(mainSetIndex + 1));
      remainingSets.sort((a, b) => b.seats.size - a.seats.size);
      const remainderSubwoofers = numOfSubwoofers - subwoofers.length;
      if (remainderSubwoofers > remainingSets.length) {
        // each set get at least 1 subwoofer, fill the largest set first
        let remainder = remainderSubwoofers - remainingSets.length;
        for (const set of remainingSets) {
          const items = getSubwooferItemsFromSet(set, remainder + 1);
          remainder -= items.length;
          items.forEach((item) => subwoofers.push(item));
        }
      } else {
        remainingSets.slice(0, remainderSubwoofers).forEach((set) => {
          const items = getSubwooferItemsFromSet(set, 1);
          items.forEach((item) => subwoofers.push(item));
        });
      }
    }

    return subwoofers;
  }
};

const setStateItemsFromBundle = (bundle = {}, allItems) => {
  // console.log('set stealthTech from bundle', bundle);
  const { layout, subwoofer, speakers, satellites, path = [] } = bundle;
  const subwoofers = getSortedSubwoofers(bundle);
  const cores = (speakers || []).filter((e) => e && e.id);

  const items = [...subwoofers, ...cores].concat(subwoofers);
  if (stealthTechState.type === 'optimal') {
    items.push(...(satellites || []));
  }

  if (stealthTechState.showIndicators && stealthTechState.type) {
    const updateItems = {};
    stealthTechState.items.forEach((item) => {
      if (allItems.has(item.id)) {
        updateItems[item.id] = {
          ...item,
          visible: false,
        };
      }
    });
    items.forEach((item) => {
      updateItems[item.id] = {
        ...item,
        visible: true,
      };
    });
    Object.values(updateItems).forEach((item) => {
      toggleSpeakerIcon(
        item.id,
        item.visible,
        item.showTopSpeakers,
        item.showSideSpeakers
      );
    });
  }
  stealthTechState.layout = layout || null;
  stealthTechState.subwoofers = subwoofers;
  stealthTechState.cores = cores;
  stealthTechState.satellites = satellites || [];
  stealthTechState.items = items;
  stealthTechState.bundle = bundle;
};

// This is expected to be called when user wants a different option
const updateStealthTechState = (state) => {
  const { type, showIndicators, numberOfSpeakers } = state;
  const items = [];
  if (type) {
    items.push(...stealthTechState.subwoofers, ...stealthTechState.cores);
  }
  if (type === 'optimal') {
    const numOfSatellites = numberOfSpeakers - CORE_PACKAGE_BASE_SPEAKERS_NUM;
    items.push(...stealthTechState.satellites.slice(-numOfSatellites));
  }
  let changed = false;
  if (
    showIndicators !== undefined &&
    showIndicators !== stealthTechState.showIndicators
  ) {
    changed = true;
    stealthTechState.showIndicators = showIndicators;
  }
  if (
    type !== stealthTechState.type ||
    stealthTechState.numberOfSpeakers !== numberOfSpeakers
  ) {
    changed = true;
  }

  if (changed) {
    const updateItems = {};
    stealthTechState.items.forEach((item) => {
      updateItems[item.id] = {
        ...item,
        visible: false,
      };
    });
    if (showIndicators)
      items.forEach((item) => {
        updateItems[item.id] = {
          ...item,
          visible: true,
        };
      });

    stealthTechState.subwoofers.forEach((item) => {
      updateItems[item.id] = {
        ...item,
        visible: showIndicators && type,
      };
    });

    Object.values(updateItems).forEach((item) => {
      toggleSpeakerIcon(
        item.id,
        item.visible,
        item.showTopSpeakers,
        item.showSideSpeakers
      );
    });

    stealthTechState.type = type;
    stealthTechState.items = items;
  }
};

const getBundleFromSet = (set) => {
  const { sides: allSides, mappedSeats, errors, state, paths, bundle } = set;

  const sides = new Set(
    Array.from(allSides.keys()).filter(
      (id) => allSides.get(id)._key !== 'rollArm'
    )
  );

  const getBundleFromPaths = (pathArray) => {
    const errorsObj = {};
    // loop through all paths, if found stealthTech eligible, return
    for (const path of pathArray) {
      const { layout } = path[path.length - 1];
      const leftItem = path[0];
      const rightItem = path[path.length - 1];
      const leftSatelliteSide = leftItem.item
        .getConnections()
        .find(
          (e) =>
            e.localSide !== leftItem.cap.localSide &&
            /doubleSided|side/.test(e.target._type)
        );
      const rightSatelliteSide = rightItem.item
        .getConnections()
        .find(
          (e) =>
            e.localSide !== rightItem.cap.localSide &&
            /doubleSided|side/.test(e.target._type)
        );
      const leftSide = leftItem.cap.target;
      const rightSide = rightItem.cap.target;
      if (
        (leftSide._keyAlians || leftSide._key) !==
        (rightSide._keyAlians || rightSide._key)
      ) {
        errorsObj[ERROR_CODES.ARMS_DONT_MATCH] = true;
        continue;
      }
      // Left - Right
      const speakers = [
        { id: path[path.length - 1].cap.target._id },
        { id: path[0].cap.target._id },
      ];

      if (layout === 'M') {
        const [rightCorner, center, leftCorner] = path.filter((ele, index) => {
          if (ele.type === 'corner') {
            path[index].index = index;
            return ele;
          }
          return null;
        });
        const cornerExts = path.filter((ele) => {
          return ele.type === 'corner-ext';
        });
        if (leftCorner && rightCorner) {
          const subwooferIndex = center.index;
          let subwoofer = path[subwooferIndex].id;

          if (path[subwooferIndex].item._key === 'wedge') {
            let i = 1;
            while (path[subwooferIndex - i] || path[subwooferIndex + i]) {
              const prevNode = path[subwooferIndex - i];
              const nextNode = path[subwooferIndex + i];
              if (prevNode && prevNode.item._key !== 'wedge') {
                subwoofer = prevNode.id;
                break;
              } else if (nextNode && nextNode.item._key !== 'wedge') {
                subwoofer = nextNode.id;
                break;
              } else i++;
            }
          }

          const leftCornerSides = ALL_SEAT_SIDES.map(
            (side) => mappedSeats[leftCorner.id][side]
          ).filter((connector) => connector && sides.has(connector.target._id));
          const rightCornerSides = ALL_SEAT_SIDES.map(
            (side) => mappedSeats[rightCorner.id][side]
          ).filter((connector) => connector && sides.has(connector.target._id));

          const satellites = [];
          if (
            path.length === 5 &&
            rightCorner.clockwise === leftCorner.clockwise
          ) {
            if (leftSatelliteSide && rightSatelliteSide) {
              satellites.push({
                id: leftSatelliteSide.target._id,
                showTopSpeakers: SPEAKER_POSITIONS[0],
              });
              satellites.push({
                id: rightSatelliteSide.target._id,
                showTopSpeakers: SPEAKER_POSITIONS[1],
              });
            }
          } else {
            leftCornerSides.forEach((c, i) => {
              satellites.push({
                id: c.target._id,
                showTopSpeakers: SPEAKER_POSITIONS[i],
              });
            });
            rightCornerSides.forEach((c, i) => {
              satellites.push({
                id: c.target._id,
                showTopSpeakers: SPEAKER_POSITIONS[i],
              });
            });
            cornerExts.forEach((o) => {
              const connector = mappedSeats[o.id].top;
              if (connector && sides.has(connector.target._id)) {
                satellites.push({
                  id: connector.target._id,
                  showTopSpeakers: SPEAKER_POSITIONS[1],
                });
              }
            });
          }
          return {
            layout,
            subwoofer,
            speakers,
            satellites,
            path,
          };
        }
      } else if (layout === 'U' && path.length > 5) {
        const [rightCorner, leftCorner] = path.filter((ele, index) => {
          if (ele.type === 'corner') {
            path[index].index = index;
            return ele;
          }
          return null;
        });
        const cornerExts = path.filter((ele) => {
          return ele.type === 'corner-ext';
        });
        if (leftCorner && rightCorner) {
          const subwooferIndex = Math.ceil(
            (leftCorner.index + rightCorner.index) / 2
          );
          let subwoofer = path[subwooferIndex].id;

          if (path[subwooferIndex].item._key === 'wedge') {
            let i = 1;
            while (path[subwooferIndex - i] || path[subwooferIndex + i]) {
              const prevNode = path[subwooferIndex - i];
              const nextNode = path[subwooferIndex + i];
              if (prevNode && prevNode.item._key !== 'wedge') {
                subwoofer = prevNode.id;
                break;
              } else if (nextNode && nextNode.item._key !== 'wedge') {
                subwoofer = nextNode.id;
                break;
              } else i++;
            }
          }

          const leftCornerSides = ALL_SEAT_SIDES.map(
            (side) => mappedSeats[leftCorner.id][side]
          ).filter((connector) => connector && sides.has(connector.target._id));
          const rightCornerSides = ALL_SEAT_SIDES.map(
            (side) => mappedSeats[rightCorner.id][side]
          ).filter((connector) => connector && sides.has(connector.target._id));

          const satellites = [];
          leftCornerSides.forEach((c, i) => {
            if (rightCornerSides[i])
              satellites.push({
                id: c.target._id,
                showTopSpeakers: SPEAKER_POSITIONS[i],
              });
          });
          rightCornerSides.forEach((c, i) => {
            if (leftCornerSides[i])
              satellites.push({
                id: c.target._id,
                showTopSpeakers: SPEAKER_POSITIONS[i],
              });
          });
          cornerExts.forEach((o) => {
            const connector = mappedSeats[o.id].top;
            if (connector && sides.has(connector.target._id)) {
              satellites.push({
                id: connector.target._id,
                showTopSpeakers: SPEAKER_POSITIONS[1],
              });
            }
          });
          return {
            layout,
            subwoofer,
            speakers,
            satellites,
            path,
          };
        }
      } else if (layout === 'L' && path.length > 2) {
        // We can't really tell if seats are left or right winged,
        // for now use avg Z of nodes' worldTransform
        const cornerItemIndex = path.findIndex((ele) => ele.type === 'corner');
        let avgZ1 = 0;
        let avgZ2 = 0;
        for (let i = 0; i < path.length; i++) {
          const worldTransform = path[i].item.translation;
          if (i < cornerItemIndex) {
            avgZ1 += worldTransform.z;
          } else if (i > cornerItemIndex) {
            avgZ2 += worldTransform.z;
          }
        }

        const leftWinged =
          avgZ1 / (cornerItemIndex + 1) <
          avgZ2 / (path.length - cornerItemIndex - 1);

        const subwooferIndex = leftWinged
          ? Math.ceil(cornerItemIndex / 2)
          : path.length - Math.ceil((path.length - cornerItemIndex) / 2);
        let subwoofer = path[subwooferIndex].id;

        if (path[subwooferIndex].item._key === 'wedge') {
          let i = 1;
          while (path[subwooferIndex - i] || path[subwooferIndex + i]) {
            const prevNode = path[subwooferIndex - i];
            const nextNode = path[subwooferIndex + i];
            if (prevNode && prevNode.item._key !== 'wedge') {
              subwoofer = prevNode.id;
              break;
            } else if (nextNode && nextNode.item._key !== 'wedge') {
              subwoofer = nextNode.id;
              break;
            } else i++;
          }
        }

        const satellites = [];
        if (leftSatelliteSide) {
          satellites.push({
            id: leftSatelliteSide.target._id,
            showTopSpeakers: SPEAKER_POSITIONS[0],
          });
        }
        if (rightSatelliteSide) {
          satellites.push({
            id: rightSatelliteSide.target._id,
            showTopSpeakers: SPEAKER_POSITIONS[1],
          });
        }
        const cornerItemId = path[cornerItemIndex].id;
        const cornerExts = path.filter((ele) => {
          return ele.type === 'corner-ext';
        });
        // Get the standard sides that attach to the corner seat of L shape
        const cornerSides = ALL_SEAT_SIDES.map(
          (side) => mappedSeats[cornerItemId][side]
        ).filter((connector) => connector && sides.has(connector.target._id));

        if (cornerExts.length) {
          const connector = mappedSeats[cornerExts[0].id].top;
          if (connector && sides.has(connector.target._id)) {
            cornerSides.push(connector);
          }
        }
        // ////////////////////
        if (cornerSides.length === 2) {
          const mainSatelliteSeatInfo = path[leftWinged ? 0 : path.length - 1];
          const mainSatelliteSeat = mappedSeats[mainSatelliteSeatInfo.id];
          const mainSatelliteSeatEndSideIndex = ALL_SEAT_SIDES.findIndex(
            (side) =>
              mainSatelliteSeat[side] &&
              mainSatelliteSeat[side].id ===
                mainSatelliteSeatInfo.cap.target._id
          );
          const nextSide =
            ALL_SEAT_SIDES[
              (mainSatelliteSeatEndSideIndex + (leftWinged ? 1 : 3)) %
                ALL_SEAT_SIDES.length
            ];
          const nextItemConnector =
            mappedSeats[mainSatelliteSeatInfo.id][nextSide];
          if (nextItemConnector && sides.has(nextItemConnector.target._id)) {
            satellites.push({
              id: nextItemConnector.target._id,
              showTopSpeakers: SPEAKER_POSITIONS[leftWinged & 1],
            });
          }
          satellites.push(
            ...cornerSides.map((c, i) => {
              return {
                id: c.target._id,
                showTopSpeakers: SPEAKER_POSITIONS[i],
              };
            })
          );
        }
        return {
          layout,
          subwoofer,
          speakers,
          satellites,
          path,
        };
      } else if (layout === 'I' && path.length > 1) {
        const firstSeat = mappedSeats[path[0].id];
        const lastSeat = mappedSeats[path[path.length - 1].id];
        // The sides at the end of each end seat
        const firstSeatEndSide = ALL_SEAT_SIDES.find(
          (side) =>
            firstSeat[side] &&
            firstSeat[side].target._id === path[0].cap.target._id
        );
        const lastSeatEndSide = ALL_SEAT_SIDES.find(
          (side) =>
            lastSeat[side] &&
            lastSeat[side].target._id === path[path.length - 1].cap.target._id
        );
        const satellites = [];
        // To tell left from right:
        // Assume first seat is on the right side
        // and last seat is on the left side
        let reversed = false;
        const nextSideItem1 = firstSeat[NEXT_SEAT_SIDES[firstSeatEndSide]];
        const prevSideItem1 = firstSeat[PREVIOUS_SEAT_SIDES[firstSeatEndSide]];
        const nextSideItem2 = lastSeat[NEXT_SEAT_SIDES[lastSeatEndSide]];
        const prevSideItem2 = lastSeat[PREVIOUS_SEAT_SIDES[lastSeatEndSide]];
        // optimal bundle available for I layout only more than 3
        // seats connect in a row
        if (
          nextSideItem1 &&
          prevSideItem2 &&
          sides.has(nextSideItem1.target._id) &&
          sides.has(prevSideItem2.target._id) &&
          path.length > 3
        ) {
          satellites.push(
            { id: prevSideItem2.target._id },
            { id: nextSideItem1.target._id }
          );
        } else if (
          nextSideItem2 &&
          prevSideItem1 &&
          sides.has(nextSideItem2.target._id) &&
          sides.has(prevSideItem1.target._id)
        ) {
          if (path.length > 3)
            satellites.push(
              { id: prevSideItem1.target._id },
              { id: nextSideItem2.target._id }
            );
          reversed = true;
        }
        satellites.map((e, i) => {
          e.showTopSpeakers = SPEAKER_POSITIONS[i];
          return e;
        });
        if (reversed) {
          path.reverse();
          speakers.reverse();
        }

        const subwoofer = path[Math.floor(path.length / 2)].id;
        return {
          layout,
          subwoofer,
          speakers,
          satellites,
          path,
        };
      }
    }
    // End of loop: not eligible for stealthTech, pass errors to set
    Object.keys(errorsObj).forEach((errorCode) => errors.push(errorCode));
  };

  return getBundleFromPaths(paths);
};

const getStealthTechBundlesFromSets = (sets, allItems, update = false) => {
  let bundle;
  let eligibleSet;
  for (const set of sets) {
    if (set.stealthTech?.bundle) {
      eligibleSet = set;
      bundle = set.stealthTech.bundle;
      const { speakers } = bundle;
      speakers.forEach((e, i) => {
        e.showTopSpeakers = SPEAKER_POSITIONS[i];
        e.showSideSpeakers = SPEAKER_POSITIONS[1 - i];
        e.type = set.sides.get(e.id)._key;
      });
      break;
    } else {
      const result = getBundleFromSet(set);
      if (result) {
        const { speakers, satellites } = result;
        speakers.forEach((e, i) => {
          e.showTopSpeakers = SPEAKER_POSITIONS[i];
          e.showSideSpeakers = SPEAKER_POSITIONS[1 - i];
          e.type = set.sides.get(e.id)._key;
        });
        satellites.forEach((e, i) => {
          e.type = set.sides.get(e.id)._key;
        });
        set.stealthTech = { bundle: result };
        bundle = result;
        eligibleSet = set;
        break;
      }
    }
  }

  // const allItems = Array.from(getItems().keys());
  Object.keys(state).forEach((key) => {
    state[key] = false;
  });
  let seatCount = 0;
  let numOfStandardSeats = 0;
  allItems.forEach((item) => {
    if (item._type === 'seat') {
      seatCount++;
      if (item._key === 'standard') {
        state.hasStdSeat = true;
        numOfStandardSeats++;
      } else if (item._key === 'storage') {
        state.hasStorageSeat = true;
      }
    } else if (item._key === 'rollArm' && item.type === '_side') {
      state.hasRollArm = true;
    }
  });

  // Keep a record of errors for each func call
  const stepErrors = [];

  const { hasStdSeat, hasStorageSeat } = state;

  const stealthTechEligible =
    eligibleSet && hasStdSeat ? getAvailableBundles(eligibleSet) : [];

  if (update) {
    stealthTechState.maxSubwoofers = numOfStandardSeats;
    stealthTechState.sets = sets;
    setStateItemsFromBundle(
      hasStdSeat && bundle,
      new Set(allItems.map((i) => i._id))
    );
  }

  if (stealthTechEligible.length)
    return { stealthTechEligible, maxSubwoofers: numOfStandardSeats };
  else {
    const stealthTechErrors = stepErrors.pop() || [];
    if (!hasStdSeat && hasStorageSeat && seatCount > 1) {
      stealthTechErrors.push(ERROR_CODES.MUST_HAVE_STANDARD_SEAT);
    }
    return { stealthTechEligible, stealthTechErrors };
  }
};
/**
 *
 * @param {Number} num Number of all subwoofers including the one for core, so minimun should be 1
 */
const setSubwoofers = (num) => {
  console.log('Called setSubwoofers:', num);
  if (stealthTechState.maxSubwoofers < num) {
    stealthTechState.numOfSubwoofers = stealthTechState.maxSubwoofers;
  } else {
    stealthTechState.numOfSubwoofers = Math.max(1, num);
  }
  if (stealthTechState.showIndicators) {
    const subwoofers = getSortedSubwoofers(stealthTechState.bundle);
    const updateItems = {};
    stealthTechState.subwoofers.forEach((item) => {
      updateItems[item.id] = {
        ...item,
        visible: false,
      };
    });
    subwoofers.forEach((item) => {
      updateItems[item.id] = {
        ...item,
        visible: true,
      };
    });
    Object.values(updateItems).forEach((item) => {
      toggleSpeakerIcon(item.id, item.visible);
    });
    stealthTechState.subwoofers = subwoofers;
  }
};

export {
  getBundleFromSet,
  getStealthTechBundlesFromSets,
  setStealthTech,
  setSubwoofers,
};
