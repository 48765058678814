import { SEAT_HEIGHT } from '../constants';

import Side from './Side';

export default class DeepAngledSide extends Side {
  constructor(threekitApi, options = {}) {
    super(threekitApi, options);
    this.width = SEAT_HEIGHT;
    this._key = 'deepAngled';

    this._initAlignmentWithConnector(options.connector);
  }

  validateConnection = (item) => {
    return item._key !== 'wedge';
  };
}
