import { addCapacityListener } from './items';

const ADD_LISTENER_MAP = {
  capacityChange: addCapacityListener,
  //   selectionChange: addSelectionListener, // not used for vray
};

export function addEventListener(listenerTarget, listenerFn) {
  // console.log('Threekit: add listener start', listenerTarget, listenerFn);
  const addListenerFn = ADD_LISTENER_MAP[listenerTarget];
  if (addListenerFn) {
    // console.log('adding listener for', listenerTarget);
    addListenerFn(listenerFn, true);
  }
  // console.log('Threekit: add listener finish', listenerTarget, listenerFn);
}
