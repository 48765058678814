export const getBoundingBox = (nodeIds) => {
  const { api } = window.threekit;
  return nodeIds.reduce((bbox, id) => {
    const nodeBBox = api.scene.get({ id, evalNode: true }).getBoundingBox();
    return (nodeBBox && bbox.union(nodeBBox)) || bbox;
  }, new api.THREE.Box3());
};

export const getBoundingBoxSize = (nodeIds) =>
  getBoundingBox(nodeIds).getSize(new window.threekit.api.THREE.Vector3());

export const getRootId = async () => {
  const { api } = window.threekit;
  let rootSceneId;

  const instanceNode = api.scene.get({ id: api.instanceId });

  if (instanceNode.type === 'Item') {
    rootSceneId = await api.player.getAssetInstance({
      id: api.instanceId,
      plug: 'Proxy',
      property: 'asset',
    });
    const rootSceneNode = api.scene.get({ id: rootSceneId });
    if (rootSceneNode.plugs.Proxy && rootSceneNode.type === 'Stage') {
      const webglProxy = rootSceneNode.plugs.Proxy[0].webgl?.assetId;
      if (!webglProxy) throw new Error('Cannot find webgl proxy');
      rootSceneId = await api.player.getAssetInstance({
        id: rootSceneId,
        plug: 'Proxy',
        property: 'webgl',
      });
    }
  } else rootSceneId = api.instanceId; // it is a direct scene asset

  return api.scene.findNode({ from: rootSceneId, type: 'Objects' });
};

export async function setConfigOnModel(modelNullId, config) {
  const instanceId = await window.threekit.api.player.getAssetInstance({
    id: modelNullId,
    plug: 'Null',
    property: 'asset',
  });

  return window.threekit.api.scene
    .get({ id: instanceId, evalNode: true })
    .configurator.setConfiguration(config);
}

export async function getConfigOnModel(modelNullId) {
  const instanceId = await window.threekit.api.player.getAssetInstance({
    id: modelNullId,
    plug: 'Null',
    property: 'asset',
  });

  return window.threekit.api.scene
    .get({ id: instanceId, evalNode: true })
    .configurator.getConfiguration();
}

// The platform sometimes sets assets as string ids, rather than {assetId}
// objects. api.player.getAssetInstance does not work on those. This function
// works around that, so that the caller does not need to worry whether the
// asset is a direct node id or a template object reference
export function getAssetInstance(query) {
  const { api } = window.threekit;
  const { id, plug, property } = query;
  try {
    const asset = api.store.get('sceneGraph').nodes[id].plugs[plug][0][
      property
    ];
    if (!asset || (!asset.assetId && !asset.query) || typeof asset === 'string')
      return asset;
  } catch (e) {}
  return api.player.getAssetInstance(query);
}

// There is an api.player.getConfiguratorInstance function which does a
// combination of api.player.getAssetInstance and the code in this function
// below. However, getAssetInstance doesn't work on assets using direct string
// id's, and so we cannot rely on it. Instead, we use a combo of our own
// getAssetInstance functon above, and this function to get the configurator for
// the isntance.
export async function getConfigurator(instanceId) {
  const sceneGraph = window.threekit.api.store.get('sceneGraph');
  const evalNode = sceneGraph.evaluatedNodes[instanceId];
  return evalNode && evalNode.configurator;
}

// Depth-first traversal of scenegraph including following Item/Model asset references
// If a node is a Model or Item, its contents are traversed first, followed by its children
export async function traverseHierarchy(nodeId, callback, conditional = false) {
  const { api } = window.threekit;
  const node = api.store.get('sceneGraph').nodes[nodeId];

  // In some cases, an instance id is returned from recursive code below which has no actual scenegraph node.
  // Presumably this is some issue with models in the middle of being swapped.
  if (!node) return;

  // Inform caller of the next node we found
  // Stop traversing hierarchy if traversal is condional on callback and
  // callback returns false
  if ((callback && callback(node)) || !conditional) {
    const { type, children } = node;

    if (type === 'Item') {
      const query = {
        id: nodeId,
        plug: 'Proxy',
        property: 'asset',
      };
      const instanceId = await getAssetInstance(query);
      if (instanceId) {
        const configInstance = await getConfigurator(instanceId);
        // need try/catch because there is a spurious orgId error
        // when the default attributes are applied
        try {
          if (configInstance) await configInstance.waitForRules();
        } catch (err) {}
        await traverseHierarchy(instanceId, callback, conditional);
      }
    } else if (type === 'Model') {
      const query = {
        id: nodeId,
        plug: 'Null',
        property: 'asset',
      };
      const instanceId = await getAssetInstance(query);
      if (instanceId) {
        const configInstance = await getConfigurator(instanceId);
        // need try/catch because there is a spurious orgId error
        // when the default attributes are applied
        try {
          if (configInstance) await configInstance.waitForRules();
        } catch (err) {}
        await traverseHierarchy(instanceId, callback, conditional);
      }
    }

    await children.reduce((prevPromise, childId) => {
      return prevPromise.then(() =>
        traverseHierarchy(childId, callback, conditional)
      );
    }, Promise.resolve());
  }
}

export function getWorldPosition(nodeId, optionalVector) {
  const worldTransformA = window.threekit.api.scene.get({
    id: nodeId,
    evalNode: true,
  }).worldTransform;

  const pos = optionalVector || new window.threekit.api.THREE.Vector3();

  pos.setFromMatrixPosition(worldTransformA);

  return pos;
}

// See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/random
// This version is inclusive of the min and max values
export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function getRandomNumber(min, max) {
  return Math.random() * (max - min) + min;
}

export function getWorldTransform(nodeId) {
  return window.threekit.api.scene.get({
    id: nodeId,
    evalNode: true,
  }).worldTransform;
}

export function isShadowPlane(nodeName) {
  return nodeName && nodeName.toLowerCase().endsWith('shadowplane');
}
