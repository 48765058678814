import Item from './Item';
import { getAlignKeyword } from '../utils';
import { SEAT_WIDTH, SEAT_HEIGHT, SIDE_MAP } from '../constants';
/**
 *
 * @param {*} threekitApi threekit api object after initial the 3D player
 * @param {*} param2
 */

export default class Anytable extends Item {
  constructor(threekitApi, options = {}) {
    super(threekitApi, options);

    this.width = SEAT_WIDTH;
    this.height = SEAT_WIDTH / 2;
    this._type = 'anytable';
    this._key = options.key || 'standard';
    this._initAlignmentWithConnector(options.connector);
  }

  _initAlignmentWithConnector = (connector) => {
    this.left = null;
    this.right = null;
    this.top = null;
    this.bottom = null;
    this.leftAlign = null;
    this.rightAlign = null;
    this.topAlign = null;
    this.bottomAlign = null;
    this.island = null;

    if (connector) {
      this._connectTo(connector);
    }
  };

  _connectTo = (connector) => {
    const { target, targetLocalSide, localSide, insert } = connector;

    let curConnectItem;
    let curItemNewConnector;

    let thisLocalSide = localSide;

    if (!thisLocalSide) {
      const targetWorldSide = target.getWorldSide(targetLocalSide);
      const thisWorldSide = 'top';
      thisLocalSide = this.getLocalSide(thisWorldSide);
    }

    this._connectHelper({ ...connector, localSide: thisLocalSide });

    if (curConnectItem) {
      curConnectItem._connectTo(curItemNewConnector);
    }

    this._updatePillow();
    target._updatePillow();
  };

  validateConnection = (item) => {
    return item._key !== 'wedge';
  };
}
