import { getSavedConfiguration, postConfiguration } from '../platform';
import {
  addItem,
  baItemToSceneConfig,
  baFabricToSceneConfig,
  getItems,
  DEFAULT_FABRIC_CONFIG,
  DEFAULT_PRODUCT_CONFIG,
} from './items';
import {
  baFloorToSceneConfig,
  getFloor,
  setFloor,
  DEFAULT_FLOOR_CONFIG,
} from './floor';

// we ended up implementing the save format the same as v1, so let's retain the
// same version string.
const CURRENT_PRODUCT_VERSION = 'test_version';

let initialConfigurationData; // reused on reset

export async function getInitialConfiguration(initParams, saveConfig = true) {
  let { configurationId, initialConfiguration } = initParams;

  if (saveConfig && !configurationId && !initialConfiguration) {
    const urlParams = new URLSearchParams(window.location.search);
    configurationId = urlParams.get('threekitConfigurationId');
  }

  let restoreVariant;
  if (configurationId) {
    const { variant } = await getSavedConfiguration(configurationId);
    restoreVariant = variant;
  } else if (initialConfiguration) {
    restoreVariant = initialConfiguration;
  }

  let tkSceneConfig = {
    ...DEFAULT_FLOOR_CONFIG,
    ...DEFAULT_FABRIC_CONFIG,
    ...DEFAULT_PRODUCT_CONFIG,
  };
  if (restoreVariant) {
    if (saveConfig && !initialConfigurationData)
      initialConfigurationData = restoreVariant;

    Object.entries(restoreVariant).forEach(async ([key, value]) => {
      switch (key) {
        case 'floor':
          tkSceneConfig = { ...tkSceneConfig, ...baFloorToSceneConfig(value) };
          setFloor(value, false);
          break;
        case 'items' && value && value[0]:
          tkSceneConfig = {
            ...tkSceneConfig,
            ...baItemToSceneConfig(value[0]),
          };
          await addItem(value[0], false);
          break;
        case 'productId':
          tkSceneConfig.Product = { assetId: value };
          break;
        case 'fabric':
          tkSceneConfig.Fabric = baFabricToSceneConfig(value).Fabric;
          break;
        default:
          tkSceneConfig[key] = value;
          break;
      }
    });
  }

  return tkSceneConfig;
}

export async function saveConfiguration() {
  const floor = getFloor();
  const items = Array.from(getItems().values());

  const configurator = await window.threekit.api.getConfigurator();
  const {
    Floor,
    Fabric,
    Product,
    ...configuration
  } = configurator.getConfiguration();

  if (Product && Product.assetId) configuration.productId = Product.assetId;

  const { id } = await postConfiguration(
    { floor, items, ...configuration },
    CURRENT_PRODUCT_VERSION
  );
  return id;
}

export async function reset(configurationId) {
  console.log('Threekit: reset start');
  const resetConfig = await getInitialConfiguration(
    { configurationId, initialConfiguration: initialConfigurationData },
    false
  );

  const configurator = await window.threekit.api.getConfigurator();
  configurator.setConfiguration(resetConfig);
  console.log('Threekit: reset finish');
}
