import { setFloor } from './floor';
import { reset, saveConfiguration } from './persistence';
import {
  addItem,
  configureItems,
  getItems,
  removeItems,
  toggleMeasurement,
  setArmType,
  setBackType,
  setProduct,
  setFabric,
  setStealthTech,
  setSubwoofers,
} from './items';
import { addEventListener } from './eventListeners';
import { rotateCamera, setCameraTool, setCameraZoomFactor } from './camera';
import { getThumbnail } from './thumbnail';
import { getAR } from './ar';

// must expose same functionality as v1 configurator
export default {
  setFloor,
  addItem,
  configureItems,
  removeItems,
  getItems,
  rotateItems: async () => {}, // note: will no longer be accessible/used in vray sacs configurator
  rotateCamera,
  setCameraTool,
  getThumbnail,
  saveConfiguration,
  reset,
  addEventListener,
  toggleMeasurement,
  setArmType,
  setBackType,
  setProduct,
  setFabric,
  setStealthTech,
  getAR,
  setCameraZoomFactor,
  setSubwoofers,
};
