import { getBoundingBoxSize } from '../../helpers';
import {
  getItem,
  getItems,
  getMeshesForItems,
  getNonAccessories,
  getItemIds,
  getSeatingCapacity,
  getConnectedSets,
} from '../items';
import { getStealthTechBundlesFromSets } from '../stealthTech';
import { getSelection } from '../selection';
import { updateSets } from '../measurement';

const state = {
  width: 0,
  depth: 0,
  height: 0,
  seating: 0,
  backPillowsUsed: 0,
  stealthTechEligible: [],
  stealthTechErrors: undefined,
  listener: null,
};

const FEET_PER_METRE = 3.2808399;

export function addCapacityListener(listenerFn, fireOnInit = false) {
  state.listener = listenerFn;
  if (fireOnInit) notifyCapacityChanged();
}

async function getHeight(itemIds) {
  // The height calculation should not include back pillows
  const nonBackPillowNodeIds = await getMeshesForItems(itemIds, {
    excludeBackPillows: true,
  });
  const { y } = getBoundingBoxSize(nonBackPillowNodeIds);

  return { height: y * FEET_PER_METRE };
}

async function getFloorSize(itemIds) {
  // The width and depth calculations should not include accessories
  const nonAccessoryNodeIds = await getMeshesForItems(itemIds, {
    excludeAccessories: true,
  });
  const { x, z } = getBoundingBoxSize(nonAccessoryNodeIds);

  return {
    width: x * FEET_PER_METRE,
    depth: z * FEET_PER_METRE,
  };
}

function notifyCapacityChanged() {
  if (state.listener) {
    const { listener, ...capacity } = state;
    listener.call(null, capacity);
  }
}

export async function updateCapacity(itemIds, added = true) {
  let capacityChanged = false;

  const items = getItemIds();

  const selection = getSelection();
  const selectionArray = Array.from(selection);

  // Get islands (all type of seats)
  const sets = getConnectedSets(items);

  // Pass islands to measurement tool
  await updateSets(
    sets.length > 1 ? sets.filter((set) => set.seats.size > 1) : sets
  );

  // Skip checking bundles when moving irrelevant items
  if (
    selectionArray.some((id) => {
      const item = getItem(id);
      return item && /seat|side/.test(item.type);
    }) ||
    selectionArray.length === 0 // For delection
  ) {
    // Detect what stealthTech bundle is eligble
    const {
      stealthTechEligible,
      stealthTechErrors,
    } = getStealthTechBundlesFromSets(sets, true);
    if (stealthTechErrors !== state.stealthTechErrors) {
      state.stealthTechErrors = stealthTechErrors;
      capacityChanged = true;
    }
    if (
      state.stealthTechEligible.length !== stealthTechEligible.length ||
      (stealthTechEligible.length === 2 &&
        stealthTechEligible[1].key !== state.stealthTechEligible[1].key)
    ) {
      state.stealthTechEligible = stealthTechEligible;
      capacityChanged = true;
    }
  }

  Object.entries(await getFloorSize(items)).forEach(([key, value]) => {
    if (value !== state[key]) {
      state[key] = value;
      capacityChanged = true;
    }
  });

  if (itemIds) {
    const seating = getSeatingCapacity(itemIds);
    if (seating) {
      state.seating += seating * (added ? 1 : -1);
      capacityChanged = true;
    }

    const { height } = await getHeight(items);
    if (height !== state.height) {
      state.height = height;
      capacityChanged = true;
    }
  }

  return capacityChanged && notifyCapacityChanged();
}

function backPillowsChanged(backPillows) {
  state.backPillowsUsed += backPillows;
  state.seating += backPillows;
  notifyCapacityChanged();
}

export function backPillowAdded() {
  return backPillowsChanged(1);
}

export function backPillowRemoved() {
  return backPillowsChanged(-1);
}
