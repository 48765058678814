import { setNodeHighlighting, toggleHighlightError } from './highlighting';
import { isColliding } from '../collision';
import { isUnattachedAccessory } from '../placement';

const state = {
  selection: new Set(),
  listener: null,
};

export function getSelection() {
  return state.selection;
}

export function addSelectionListener(listenerFn, fireOnInit = false) {
  state.listener = listenerFn;
  if (fireOnInit) notifySelectionChanged();
}

function notifySelectionChanged() {
  if (state.listener) {
    const { listener, selection } = state;
    const selectedItems = Array.from(selection);
    listener.call(null, selectedItems);
  }
}

export function selectItem(itemId) {
  setNodeHighlighting(itemId, true);
  toggleHighlightError(isColliding(itemId) || isUnattachedAccessory(itemId));
  state.selection.add(itemId);
  notifySelectionChanged();
}

// itemIds can be Array or Set
export async function deselectItems(itemIds) {
  let deselected = false;
  await Promise.all(
    itemIds.map(async (itemId) => {
      await setNodeHighlighting(itemId, false);
      state.selection.delete(itemId);
      deselected = true;
    })
  );
  if (deselected) notifySelectionChanged();
}

export async function clearSelection() {
  await deselectItems(Array.from(state.selection));
}

window.getSelectedItem = () => state.selection.keys().next().value;
