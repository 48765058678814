const state = {
  //   width: 0,
  //   depth: 0,
  //   height: 0,
  //   seating: 0,
  //   backPillowsUsed: 0,
  maxSubwoofers: 0,
  stealthTechEligible: [],
  stealthTechErrors: undefined,
};

export function updateCapacity(data) {
  const { items, bundles, added } = data;
  let capacityChanged = false;

  // Detect what stealthTech bundle is eligble
  const { stealthTechEligible, stealthTechErrors, maxSubwoofers } = bundles;
  if (stealthTechErrors !== state.stealthTechErrors) {
    state.stealthTechErrors = stealthTechErrors;
    capacityChanged = true;
  }
  if (state.maxSubwoofers !== maxSubwoofers) {
    state.maxSubwoofers = maxSubwoofers;
    capacityChanged = true;
  }
  if (
    state.stealthTechEligible.length !== stealthTechEligible.length ||
    (stealthTechEligible.length === 2 &&
      stealthTechEligible[1].key !== state.stealthTechEligible[1].key)
  ) {
    state.stealthTechEligible = stealthTechEligible;
    capacityChanged = true;
  } else if (
    JSON.stringify(state.stealthTechEligible) !==
    JSON.stringify(stealthTechEligible)
  ) {
    state.stealthTechEligible = stealthTechEligible;
    capacityChanged = true;
  }
  if (capacityChanged) return { ...state };
}
